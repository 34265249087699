import {
    REQUEST_SUBSCRIPTION, LOAD_SUBSCRIPTION, FAILED_SUBSCRIPTION,
    REQUEST_PRODUCTS, LOAD_PRODUCTS, FAILED_PRODUCTS,
    REQUEST_BOOKING, LOAD_BOOKING, FAILED_BOOKING,
    PAYMENT_SUCCESSFUL, REQUEST_PAYMENT, FAILED_PAYMENT, INITIATE_PAYMENT,
    SELECT_PRODUCT,  loadingState
} from '../actions'



const initialState = {
    has_active_membership: false,
    allow_purchase: false,
    is_trial_account: false,
    products_list: null,
    center_name: null,
    subscriptions: null,
    booking_list: new Map(),

    subscriptions_state: loadingState.none,
    products_state: loadingState.none,
    bookings_state: loadingState.none,
    payment_state: loadingState.none,
    last_error:null,
    ref_id: null,
    purchased_item: null, ///for thank you page (after buy)
    selected_product: null, ///for checkout page (before buy)
};

const subscriptionReducer = (state=initialState, action) => {
    switch (action.type) {
        case SELECT_PRODUCT:
            return  Object.assign({}, state, {
                selected_product: {
                    type: action.payload.type,
                    product_id: action.payload.product_id
                },
            });
        case REQUEST_SUBSCRIPTION:
            return  Object.assign({}, state, {
                subscriptions_state : loadingState.pending,
                subscriptions:[],
                last_error:null,
            });
        case LOAD_SUBSCRIPTION:
            return  Object.assign({}, state, {
                subscriptions_state : loadingState.ready,
                subscriptions :  action.payload.subscriptions,
                has_active_membership :  action.payload.has_active_membership,
                allow_purchase :  action.payload.allow_purchase,
                is_trial_account :  action.payload.is_trial_account
            });
        case FAILED_SUBSCRIPTION:
            return  Object.assign({}, state, {
                subscriptions_state: loadingState.failed,
                subscriptions:[],
                last_error : action.payload.error,
            });


        case REQUEST_PRODUCTS:
            return  Object.assign({}, state, {
                products_state : loadingState.pending,
                products_list:[],
                last_error: null,
            });
        case LOAD_PRODUCTS:
            return  Object.assign({}, state, {
                products_state : loadingState.ready,
                products_list :action.payload.products,
                center_name :action.payload.centerName,
            });
        case FAILED_PRODUCTS:
            return  Object.assign({}, state, {
                products_state: loadingState.failed,
                products_list:[],
                last_error : action.payload.error,
            });


        case REQUEST_BOOKING:
            return  Object.assign({}, state, {
                bookings_state : loadingState.pending,
                booking_list: new Map(),
                last_error:null,
            });
        case LOAD_BOOKING:
            let bookingList = state.booking_list;
            action.payload.booking_list.map((item)=>{
                bookingList.set(item.id, item);
            })
            return  Object.assign({}, state, {
                bookings_state : loadingState.ready,
                booking_list: bookingList,
            });

        case FAILED_BOOKING:
            return  Object.assign({}, state, {
                bookings_state: loadingState.failed,
                booking_list: new Map(),
                last_error : action.payload.error,
            });

        case INITIATE_PAYMENT:
            return  Object.assign({}, state, {
                payment_state: loadingState.none,
                ref_id:null,
                last_error : null,
                selected_product:null
            });
        case FAILED_PAYMENT:
            return  Object.assign({}, state, {
                payment_state: loadingState.failed,
                ref_id:null,
                purchased_item:null,
                last_error : action.payload.error,
            });

        case REQUEST_PAYMENT:
            return  Object.assign({}, state, {
                payment_state : loadingState.pending,
                ref_id:null,
                last_error:null,
                purchased_item:null,
            });

        case  PAYMENT_SUCCESSFUL:
            return  Object.assign({}, state, {
                payment_state: loadingState.ready,
                ref_id : action.payload.id,
                purchased_item : action.payload.product,

                subscriptions_state : loadingState.none,
                subscriptions:[],
                last_error:null,
            });
        default:
            return state;
    }
}
export default subscriptionReducer;