import {
    broadcastSyncMessage,
    broadcastMetrics,
    METRICS_CHANGED,
    SYNC_MESSAGE
} from '../actions';


export function signalRMiddleware({ getState }) {
    return next => action => {
        switch (action.type) {
            case SYNC_MESSAGE:
                broadcastSyncMessage(getState);
                break;
            case METRICS_CHANGED:
                broadcastMetrics(getState, action.payload.metrics);
                break;
            default:
                break;
        }

        return next(action);
    }
}

