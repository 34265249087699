import React, {Component} from "react";
import {connect} from "react-redux";

import {Card, Col, Collapse, ListGroup, ListGroupItem, Row} from "react-bootstrap";
import {listTrainingPeaksSessions} from "../actions/";
import Loading from "./Loading";
import {Link} from "react-router-dom";
import {Event} from "./Tracking";
import {isEmpty} from "../utils/statistics";

class TrainingPeaksWorkouts extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: true
        }
    }


    toggle() {
        if(!this.state.open) {
            Event("view_trainingpeaks", {})
        }
        this.setState({
            open : !this.state.open
        })
    }


    render() {

        if (this.props.connectionsList.length === 0) {
           return (<Loading/>)
        }

        let connected_to_training_peaks = this.props.connectionsList.training_peaks.connected;
        if (connected_to_training_peaks && (typeof this.props.tpwodsList === 'undefined' || this.props.tpwodsList === null)) {
            return ''; //still pending for result
        }

        if (!connected_to_training_peaks ) {
            return (<Row>
                <Col className="text-center">
                    <h4>please connect to training peaks to get your workout of the day.</h4>
                </Col>
            </Row>)
        }
        return (
            <div className="introductionContainer shadowContainer">
                <Row>
                    <Col className="text-center">
                        <h4>TrainingPeaks workouts</h4>
                    </Col>
                </Row>
                <Collapse in={this.state.open}>
                <Row>

                    <Col  className="text-left">
                        <Card style={{width: '25rem'}}>

                            <Card.Body>
                                <Card.Title></Card.Title>
                            </Card.Body>
                            <ListGroup className="tpwodList list-group-flush">
                                {
                                    this.props.tpwodsList.map((item, key) => {

                                            return (
                                                <ListGroupItem key={key}>
                                                    <Link
                                                        onClick={() =>
                                                            Event("click", {page:"home", component:"IntroductionMessage", target:"introduction workout", workout_name:"item.name"})
                                                        }
                                                        className="text-nowrap" to={{
                                                        pathname: "private-workout",
                                                        state: {wid: item.id},
                                                    }}>{item.name}
                                                    </Link>


                                                </ListGroupItem>
                                            )
                                        }
                                    )
                                }
                            </ListGroup>
                        </Card>
                    </Col>

                </Row>
                </Collapse>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    tpwodsList: state.session.training_peaks_wods,
    connectionsList: isEmpty(state.session.connections) ? [] : state.session.connections,
});
export default connect(mapStateToProps,{listTrainingPeaksSessions})(TrainingPeaksWorkouts)
