import React   from "react";
import {Col, Container, Row} from "react-bootstrap";
import powerwatts_logo from "../assets/powerwatts_logo.png";
import {Event} from "../Components/Tracking";
export const Logout = (props) => {
    Event("page_view", {page_title:"Logout"})
    return (
        <Container className="flex-grow-1 mt-5  h-100">

            <Row >
                <Col className="text-center">

                    <div  className="homeMessageContainer">
                        <h2><a href="/"><img src={powerwatts_logo} alt="powerwatts" />Virtual Studio</a></h2>
                        <p>Please <a href="/">Login</a> to get access to  PowerWatts Online</p>

                    </div>
                </Col>
            </Row>
        </Container>
    )
}