import React, {Component} from "react";
import {connect} from "react-redux";
import { loadWorkout } from "../actions";
import {roundStr, toRelative} from "../utils/statistics";
import {toArcPath} from "../utils/SvgHelpers";

class    AccuracyGauge extends Component {
    static defaultProps = {
        colors: {
            primaryColor : '#ffffff',
            blue: '#3595F0',
            green: '#2F8F00',
            red: '#C01818',
        },

        gradient: [],
        width: 400,
        height: 50,
        margin: 150,
        accuracyMin: 0,
        accuracyMax: 100,
    };


    getStyles (accuracyData){
        let centerX = this.props.width/2;
        let centerY =  this.props.height ;
        let radius = centerX-this.props.margin;
        let radiusY = 0.75*radius;

        let tbz = toRelative(accuracyData.tbz, this.props.accuracyMin,this.props.accuracyMax, 180 );
        let tiz = toRelative(accuracyData.tiz, this.props.accuracyMin,this.props.accuracyMax, 180 );
        let toz = toRelative(accuracyData.toz, this.props.accuracyMin,this.props.accuracyMax, 180 );
        let grade =  accuracyData.grade;
        let needleWidth = 15;
        return {
            colors: this.props.colors,
            width: this.props.width,
            height: this.props.height,
            centerX: centerX,
            centerY: centerY,
            radiosX: radius,
            radiosY: radiusY,
            needleWidth: needleWidth,
            tbz: {
                arcAngle : tbz,
                startAngle:  180,
                color : this.props.colors.blue
            },
            tiz: {
                arcAngle : tiz,
                startAngle:  180 + tbz,
                color : this.props.colors.green
            },
            toz: {
                arcAngle : toz,
                startAngle:  180 + tbz + tiz,
                color : this.props.colors.red
            },
            grade: grade
        }
    }

    getMetrics(intervalId){
        let metrics = this.props.intervalsMetrics.find(e => (e.intervalId===intervalId) );
        if (typeof metrics==="undefined") {
            return null;
        }
        return metrics;
    }


    render() {

        if(!this.props.showGauge || this.props.currentIntervalId=== null || this.props.currentIntervalId===0){
            return '';
        }
        let metrics = this.getMetrics(this.props.currentIntervalId-1)
        if(metrics===null){
            return '';
        }
        let accuracyData = metrics.power_accuracy;
        let avgSpeed = metrics.avg_speed;

        let styles = this.getStyles(accuracyData);
        const viewBox = "0 0 " + styles.width + ' ' + styles.height;
        return (
            <svg  viewBox={viewBox} >
                <g fill="none" fillOpacity="1" strokeWidth={styles.needleWidth}>
                    <path d={toArcPath(
                        styles.centerX,
                        styles.centerY,
                        styles.radiosX,
                        styles.tbz.startAngle ,
                        styles.tbz.arcAngle ,
                        0,
                        styles.radiosY,
                    )} id="tbz-gauge" stroke={styles.tbz.color}
                    />
                    <path d={toArcPath(
                        styles.centerX,
                        styles.centerY,
                        styles.radiosX,
                        styles.tiz.startAngle ,
                        styles.tiz.arcAngle ,
                        0,
                        styles.radiosY,
                    )} id="tbz-gauge" stroke={styles.tiz.color}
                    />
                    <path d={toArcPath(
                        styles.centerX,
                        styles.centerY,
                        styles.radiosX,
                        styles.toz.startAngle ,
                        styles.toz.arcAngle ,
                        0,
                        styles.radiosY,
                    )} id="tbz-gauge" stroke={styles.toz.color}
                    />
                </g>
                <g id="texts-accuracy"  textAnchor="middle">
                    <text x={styles.centerX} y={styles.centerY } fill={styles.colors.primaryColor} fontSize="0.7rem">{accuracyData.tiz}%  Δ{accuracyData.diff}</text>
                    <text x={styles.centerX} y={styles.centerY-15 } fill={styles.colors.green} fontSize="0.7rem">{roundStr(avgSpeed,1)}Kph</text>
                </g>

            </svg>
            );
    }
}


const mapStateToProps = state => ({
    currentIntervalId: (state.workout.progress)? state.workout.progress.intervalId : null,
    intervalsMetrics: state.metrics.intervals,
    showGauge: state.visualExperience.display_accuracy_gauge
});
export default connect(mapStateToProps, {loadWorkout})(AccuracyGauge)