

import {SyncTimer} from "./session";
export const START_TIMER = 'START_TIMER';
export const STOP_TIMER = 'STOP_TIMER';
export const PAUSE_TIMER = 'PAUSE_TIMER';
export const SKIP_TIMER = 'SKIP_TIMER';
export const TIMER_TICK = 'TIMER_TICK';

export const INTERVAL_TIMER ='INTERVAL_TIMER';

export const timerStatus = {
    started : 'started',
    stopped: 'stopped',
    paused: 'paused',
    autoPaused: 'auto-paused',
};


export let MainTimer = null;
export function startTimer(){
    return function (dispatch, getState) {
        if(MainTimer) {
            clearInterval(MainTimer);
        }
        MainTimer = setInterval(()=> {
            tick(dispatch);
        },1000);
        let workout = getState().workout;
        let session = getState().session;
        let startSec = getState().timer.workout;
        let miliSecDiff =0;
        if(startSec===0 && session.auto_start_in_seconds) {
            let diff = session.auto_start_at - Date.now();
            miliSecDiff = diff % 1000;
            startSec = -1* Math.round((diff)/1000);
        } else if(startSec===0 && workout.initialized && workout.plan.videoId && workout.plan.videoStartSec) {
            startSec = -1* workout.plan.videoStartSec;
        }

        dispatch(
            {
                type: START_TIMER,
                payload:{
                    startTs: Date.now(),
                    startSec: startSec
                }
            }
        )
    };
}

export function stopTimer(){
    clearInterval(MainTimer);
    if(SyncTimer)
        clearInterval(SyncTimer);
    return function (dispatch) {
        dispatch(
            {
                type: STOP_TIMER,
                payload:{
                    startTs: Date.now(),
                }
            }
        )
    };
}
export function skipTimer(){
    return function (dispatch, getState) {
        if(  typeof getState().workout.interval.durationInSeconds === "undefined"){
            return false;
        }
        let duration = getState().workout.interval.durationInSeconds
        dispatch(
            {
                type: SKIP_TIMER,
                payload:{
                    startTs: Date.now(),
                    duration: typeof duration==='undefined' ? 0 : duration
                }
            }
        )
    };
}
export function pauseTimer(){
    clearInterval(MainTimer);
    return function (dispatch) {
        dispatch(
            {
                type: PAUSE_TIMER,
                payload:{
                    startTs: Date.now(),
                }
            }
        )
    };
}


export function tick(dispatch){

    dispatch(
        {
            type: TIMER_TICK,
            payload:{
                timestamp: Date.now(),
            }
        }
    )

}
