import {Event} from '../Components/Tracking'

export const REQUEST_SUBSCRIPTION = 'REQUEST_SUBSCRIPTION';
export const LOAD_SUBSCRIPTION = 'LOAD_SUBSCRIPTION';
export const FAILED_SUBSCRIPTION = 'FAILED_SUBSCRIPTION';

export const REQUEST_PRODUCTS = 'REQUEST_PRODUCTS';
export const LOAD_PRODUCTS = 'LOAD_PRODUCTS';
export const FAILED_PRODUCTS = 'FAILED_PRODUCTS';

export const REQUEST_BOOKING = 'REQUEST_BOOKING';
export const LOAD_BOOKING = 'LOAD_BOOKING';
export const FAILED_BOOKING = 'FAILED_BOOKING';

export const SUBSCRIPTION_NOT_ACTIVE = 'SUBSCRIPTION_NOT_ACTIVE';

export const REQUEST_PAYMENT = 'REQUEST_PAYMENT';
export const PAYMENT_SUCCESSFUL = 'PAYMENT_SUCCESSFUL';
export const FAILED_PAYMENT = 'FAILED_PAYMENT';
export const INITIATE_PAYMENT = 'INITIATE_PAYMENT';

export const SELECT_PRODUCT = 'SELECT_PRODUCT';

export const loadingState = {
    none: 'none',
    pending: 'pending',
    ready: 'ready',
    failed: 'failed',
};

export const ProductType = {
    service: 'service',
    contract: 'contract',
};


function prepareRequestOptions(getState, method)
{
    let token = getState().user.info.token;
    return {
        method: method,
        headers: {
            'X-CPX-Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
    };
}
export function subscriptionNotActive(){
    return function (dispatch) {
        dispatch(
            {
                type : SUBSCRIPTION_NOT_ACTIVE,
                payload : {}
            }
        )
    }
}
export function selectProduct(type,id){
    return function (dispatch) {
        dispatch(
            {
                type : SELECT_PRODUCT,
                payload : {
                    type: type,
                    product_id: id
                }
            }
        )
    }
}

export function initiatePayent(){
    return function (dispatch) {
        dispatch(
            {
                type : INITIATE_PAYMENT,
                payload : {}
            }
        )
    }
}

export function buyService(serviceId, paymentInfo){
    return function (dispatch, getState) {
        if (getState().subscription.payment_state === loadingState.pending) {
            return
        }

        if (getState().subscription.selected_product===null){
            return dispatch({type: FAILED_PAYMENT, payload: {error: 'missing requested package'}});
        }
        dispatch({type: REQUEST_PAYMENT, payload: {timestamp: Date.now()}});
        let payload = {
            service_id: serviceId,
            payment_info: {
                credit_card_number: paymentInfo.number,
                exp_month: paymentInfo.expiry_month,
                exp_year: paymentInfo.expiry_year,
                cvv: paymentInfo.cvc,
                billing_name: paymentInfo.name,
            }
        }

        let requestOptions = prepareRequestOptions(getState, 'POST');
        requestOptions.body  = JSON.stringify(payload)
        let url = 'https://booking.powerwatts.co.il/subscriptions/purchaseClassPack';
        fetch(url, requestOptions)
        .then(res => res.json())
        .then(body => {
            if (body.success === false) {
                return dispatch({type: FAILED_PAYMENT, payload: {error: body.error.detailed_error}});
            }
            let service_id = body.service_id
            let service = body.service

            Event('purchase' ,{
                affiliation: 'Web App',
                currency: (typeof service.currency!=="undefined" ? service.currency : 'CAD'),
                items: [{
                    item_id: serviceId,
                    item_name: service.name,
                    affiliation: 'Web App',
                    price:  (typeof service.price!=="undefined" ? service.price :0),
                    currency:  (typeof service.currency!=="undefined" ? service.currency : 'CAD'),
                    quantity: 1
                }],
                value: (typeof service.total!=="undefined" ? body.total :0),
                tax:  (typeof service.tax!=="undefined" ? body.tax :0)
            })

            return dispatch(
                {
                    type: PAYMENT_SUCCESSFUL,
                    payload: {
                        id: service_id,
                        product: service,
                    }
                }
            )
        })
        .catch(function (error) {
            return dispatch({type: FAILED_PAYMENT, payload: {error: error}});
        });
    }

}

export function buyContract(contractId, paymentInfo){
    return function (dispatch, getState) {
        if (getState().subscription.payment_state === loadingState.pending) {
            return;
        }
        dispatch({type: REQUEST_PAYMENT, payload: {timestamp: Date.now()}});
        let payload = {
            contract_id: contractId,
            payment_info: {
                credit_card_number: paymentInfo.number,
                exp_month: paymentInfo.expiry_month,
                exp_year: paymentInfo.expiry_year,
                cvv: paymentInfo.cvc,
                billing_name: paymentInfo.name,
            }
        }

        let requestOptions = prepareRequestOptions(getState, 'POST');
        requestOptions.body  = JSON.stringify(payload)
        let url = 'https://booking.powerwatts.co.il/subscriptions/purchaseContract';
        fetch(url, requestOptions)
        .then(res => res.json())
        .then(body => {
            if (body.success === false) {
                return dispatch({type: FAILED_PAYMENT, payload: {error: body.error.detailed_error}});
            }
            let contract_id = body.contract_id
            let contract = body.contract

            Event('purchase' ,{
                affiliation: 'Web App',
                currency: (typeof body.currency!=="undefined" ? body.currency : 'CAD'),
                items: [{
                    item_id: contract_id,
                    item_name: contract.name,
                    affiliation: 'Web App',
                    price:  (typeof contract.price!=="undefined" ? contract.price :0),
                    currency:  (typeof contract.currency!=="undefined" ? contract.currency : 'CAD'),
                    quantity: 1
                }],
                value: (typeof contract.total!=="undefined" ? body.total :0),
                tax:  (typeof contract.tax!=="undefined" ? contract.tax :0)
            })


            return dispatch(
                {
                    type: PAYMENT_SUCCESSFUL,
                    payload: {
                        id: contract_id,
                        product: contract,
                    }
                }
            )
        })
        .catch(function (error) {
            return dispatch({type: FAILED_PAYMENT, payload: {error: error}});
        });
    }

}
export function bookPrivateClass(id){
    return function (dispatch, getState) {
        if (getState().subscription.bookings_state !== loadingState.ready) {
             dispatch({type: FAILED_BOOKING, payload: {error: 'your booking list is not ready yet'}});
             return;
        }
        dispatch({type: REQUEST_BOOKING, payload: {timestamp: Date.now()}});

        let requestOptions = prepareRequestOptions(getState, 'POST');
        let url = 'https://booking.powerwatts.co.il/subscriptions/bookPrivateClass?external_id='+id;
        fetch(url, requestOptions)
        .then(res => res.json())
        .then(body => {
            if (body.success === false) {
                return dispatch({type: FAILED_BOOKING, payload: {error: body.error.detailed_error}});
            }
            let booking_list = body.booking.map((booking) => {
                return {
                    id: booking.id,
                    class_name: booking.name,
                    date: booking.date,
                    start_time: booking.start_time,
                    end_time: booking.end_time,
                }
            });

            return dispatch(
                {
                    type: LOAD_BOOKING,
                    payload: {
                        booking_list: booking_list,
                    }
                }
            )
        })
        .catch(function (error) {
            return dispatch({type: FAILED_BOOKING, payload: {error: error}});
        });
    }
}

export function loadBookedClasses() {
    return function (dispatch, getState) {
        if (getState().subscription.bookings_state !== loadingState.none) {
            return;
        }

        dispatch({type: REQUEST_BOOKING, payload: {timestamp: Date.now()}});

        let requestOptions = prepareRequestOptions(getState, 'GET');
        let url = 'https://booking.powerwatts.co.il/subscriptions/bookingList?offset=' + new Date().getTimezoneOffset();
        fetch(url, requestOptions)
            .then(res => res.json())
            .then(body => {
                if (body.success === false) {
                    return dispatch({type: FAILED_BOOKING, payload: {error: body.error.detailed_error}});
                }
                let booking_list = body.booking.map((booking) => {
                    return {
                        id: booking.id,
                        class_name: booking.name,
                        date: booking.date,
                        start_time: booking.start_time,
                        end_time: booking.end_time,
                    }
                });

                return dispatch(
                    {
                        type: LOAD_BOOKING,
                        payload: {
                            booking_list: booking_list,
                        }
                    }
                )
            })
            .catch(function (error) {
                return dispatch({type: FAILED_SUBSCRIPTION, payload: {error: error}});
            });
    }
}


export function loadSubscriptions() {
    return function (dispatch, getState) {
        if (getState().subscription.subscriptions_state !== loadingState.none) {
            return;
        }

        dispatch({type: REQUEST_SUBSCRIPTION, payload: {timestamp: Date.now()}});

        let requestOptions = prepareRequestOptions(getState, 'GET');
        let url = 'https://booking.powerwatts.co.il/subscriptions/list';
        fetch(url, requestOptions)
            .then(res => res.json())
            .then(body => {
                if (body.success === false) {
                    return dispatch({type: FAILED_SUBSCRIPTION, payload: {error: body.error.detailed_error}});
                }
                let subscriptions = body.subscriptions.map((subscription) => {
                    return {
                        id: subscription.id,
                        type: subscription.type,
                        name: subscription.name,
                        count: subscription.count,
                        is_active: subscription.active,
                        start_date: subscription.start,
                        end_date: subscription.end,
                        period_text: subscription.period,
                    }
                });

                return dispatch(
                    {
                        type: LOAD_SUBSCRIPTION,
                        payload: {
                            subscriptions: subscriptions,
                            has_active_membership: body.has_active_membership,
                            allow_purchase: body.allow_purchase,
                            is_trial_account:  body.is_trial_account
                        }
                    }
                )
            })
            .catch(function (error) {
                return dispatch({type: FAILED_SUBSCRIPTION, payload: {error: error}});
            });
    }
}

export function loadProducts() {
    return function (dispatch, getState) {
        if (getState().subscription.products_state !== loadingState.none) {
            return;
        }

        dispatch({type: REQUEST_PRODUCTS, payload: {timestamp: Date.now()}});

        let requestOptions = prepareRequestOptions(getState, 'GET');
        let url = 'https://booking.powerwatts.co.il/subscriptions/products';
        fetch(url, requestOptions)
            .then(res => res.json())
            .then(body => {
                if (body.success === false) {
                    return dispatch({type: FAILED_PRODUCTS, payload: {error: body.error.detailed_error}});
                }
                let centerName = body.center_name;
                let products = body.products.map((product) => {
                    return {
                        id: product.id,
                        type: product.type,
                        name: product.name,
                        price: product.price,
                        tax: product.tax,
                        total: product.total,
                        currency: product.currency,
                        expiration: product.expiration,
                        count: product.count,
                        description: typeof product.description!=='undefined' ? product.description : '',
                        is_default: typeof product.default!=='undefined' ? product.default : false,
                    }
                });

                return dispatch(
                    {
                        type: LOAD_PRODUCTS,
                        payload: {
                            centerName: centerName,
                            products: products,
                        }
                    }
                )
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}


