import React, {Component} from "react";
import {connect, useDispatch} from "react-redux";
import {updateUserProfile, loadUserMetrics} from  '../actions'
import {Col, Collapse, Form, Button, Row} from "react-bootstrap";
import {isEmpty} from "../utils/statistics";
import { Event } from "./Tracking";
import UserSubscription from "./UserSubscription";
import {LogoutControl} from "./StudioControls";

class UserProfile extends Component {
    constructor(props) {
        super(props)
        let user = this.props.user;
        this.state = {
            open: true,
            first_name: null,
            last_name: null,
            nickname: null,
            height: null,
            weight: null,
            critical_power:null,
        }


    }
    componentDidMount(){
        this.props.loadUserMetrics();

    }


    toggle() {
        if(!this.state.open) {
            Event("view_profile", {email: this.props.user.info.email})
        }

        this.setState({
            open : !this.state.open
        })
    }
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    onSubmit = (e) => {
        e.preventDefault()
        let weight = isEmpty(this.state.weight) ? this.props.user.weight : this.state.weight;
        let height = isEmpty(this.state.height) ? this.props.user.height : this.state.height;
        let cp20 = isEmpty(this.state.critical_power) ? this.props.user.critical_power : this.state.critical_power;
        let nickname = isEmpty(this.state.nickname) ? this.props.user.info.nickname : this.state.nickname;
        let first_name = isEmpty(this.state.first_name) ? this.props.user.info.first_name : this.state.first_name;
        let last_name = isEmpty(this.state.last_name) ? this.props.user.info.last_name : this.state.last_name;
        let userData = {
            'Name': first_name + ' ' + last_name,
            'Nickname': nickname,
            'Height': parseFloat(height),
            'Weight': parseFloat(weight),
            'Cp20M': parseInt(cp20),
            'ProfileCompleted':  parseFloat(weight)>0  && parseFloat(height)>0 && parseFloat(cp20)>0
        }
        this.props.updateUserProfile(userData)
        Event("click", {page:"home", component:"UserProfile", target:"introduction workout", workout_name:"item.name"})
        this.toggle()
    }


    render() {
        let user = this.props.user;
        let weight = isEmpty(this.state.weight) ? this.props.user.weight : this.state.weight;
        let height = isEmpty(this.state.height) ? this.props.user.height : this.state.height;
        let cp20 = isEmpty(this.state.critical_power) ? this.props.user.critical_power : this.state.critical_power;
        let nickname = isEmpty(this.state.nickname) ? this.props.user.info.nickname : this.state.nickname;
        let first_name = isEmpty(this.state.first_name) ? this.props.user.info.first_name : this.state.first_name;
        let last_name = isEmpty(this.state.last_name) ? this.props.user.info.last_name : this.state.last_name;
        return (
            <>

            <div className="introductionContainer shadowContainer">
                <Row>
                    <Col className="text-center">
                        <h4><a href="#"  onClick={()=>this.toggle()}>Profile, Membership & LIVE Class Packages</a></h4>
                    </Col>
                </Row>
                <Collapse in={this.state.open}>
                <Row>
                    <Col>
                        <img alt={user.info.full_name} src={user.info.picture} style={{height:"150px",borderRadius: "50%"}} />
                    </Col>
                    <Col>
                        <Form  onSubmit={this.onSubmit} >
                            <Form.Group  as={Row} controlId="formBasicEmail">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control name="first_name" type="text" size="sm" onChange={this.onChange}  placeholder="Your Name"  value={first_name} />

                            </Form.Group>
                            <Form.Group  as={Row} controlId="formBasicEmail">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control  name="last_name"  type="text"  size="sm" onChange={this.onChange}  placeholder="Your Name"  value={last_name} />
                            </Form.Group>
                            <Form.Group  as={Row} controlId="formBasicEmail">
                                <Form.Label>Nickname</Form.Label>
                                <Form.Control name="nickname" type="text"  size="sm" onChange={this.onChange}  placeholder="Nickname" value={nickname} />
                            </Form.Group>
                            <Form.Group  as={Row} controlId="formBasicEmail">
                                <Form.Label>Height</Form.Label>
                                <Form.Control name="height" type="number"  size="sm" onChange={this.onChange}  placeholder="Height in meters"  value={height} step={0.01}/>
                            </Form.Group>

                            <Form.Group  as={Row} controlId="formBasicEmail">
                                <Form.Label>Weight</Form.Label>
                                <Form.Control type="number"  name="weight" size="sm" onChange={this.onChange}  placeholder="Weight in Kg"  value={weight}  step={0.1} />
                            </Form.Group>

                            <Form.Group  as={Row} controlId="formBasicEmail">
                                <Form.Label>CP20m</Form.Label>
                                <Form.Control type="number"  name="critical_power" size="sm" onChange={this.onChange}  placeholder="20 min critical power in watts"  value={cp20} />
                            </Form.Group>

                            <Button variant="secondary" type="submit">
                                Save
                            </Button>
                        </Form>
                    </Col>
                    <Col>
                    <UserSubscription/>
                    </Col>

                </Row>
                </Collapse>
            </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    user : state.user,

});
export default connect(mapStateToProps,{updateUserProfile, loadUserMetrics})(UserProfile)
