
export function toRank(rank){
    let result = {
        'icon' : '',
        'color' : '#000'
    }
    switch (rank) {
        case 1:
            result.icon = '①';
            result.color = '#FFD700';
            break;
        case 2:
            result.icon = '②';
            result.color = '#C0C0C0';
            break;
        case 3:
            result.icon = '③';
            result.color = '#CD7F32';
            break;
        default:
            break;//skip
    }
    return result;

}
export function toPath(lx,ly,mx,my){
    if(isNaN(lx)) return  null;
    return `M${mx} ${my} L${lx} ${ly}`;
}
export function  toRotate(r,x,y){
    return `rotate(${r},${x},${y})`
}

export function  toLinePath(relX, relY, start , end, angle ){
    return {
        mx: Math.round(relX-start*Math.cos(angle/180*Math.PI)),
        my: Math.round(relY-start*Math.sin(angle/180*Math.PI)),
        lx: Math.round(relX-end*Math.cos(angle/180*Math.PI)),
        ly: Math.round(relY-end*Math.sin(angle/180*Math.PI)),

    }
}

export function toArcPath (cx,cy,r,startAngle,arcAngle, relativeStartAngle, ry=null) {
    cx = parseFloat(cx);
    cy = parseFloat(cy);
    let rx = parseFloat(r);
    ry = ry===null ? rx : parseFloat(ry);
    let tl = startAngle ? parseFloat(startAngle) * Math.PI / 180 : 0
    let delta = parseFloat(arcAngle) * Math.PI / 180
    let phi = parseFloat(relativeStartAngle) * Math.PI / 180;

    return svg_ellipse_arc(
        [cx, cy],
        [rx, ry],
        [tl, delta],
        phi);
}

//////////////////////////////////// http://xahlee.info/js/svg_circle_arc.html
const cos = Math.cos;
const sin = Math.sin;
const PI = Math.PI;

const f_matrix_times = (( [[a,b], [c,d]], [x,y]) => [ a * x + b * y, c * x + d * y]);
const f_rotate_matrix = ((x) => [[cos(x),-sin(x)], [sin(x), cos(x)]]);
const f_vec_add = (([a1, a2], [b1, b2]) => [a1 + b1, a2 + b2]);

const svg_ellipse_arc = (([cx,cy],[rx,ry], [t1, delta], phi ) => {

    /* [
    returns a SVG path element that represent a ellipse.
    cx,cy → center of ellipse
    rx,ry → major minor radius
    t1 → start angle, in radian.
    delta → angle to sweep, in radian. positive.
    phi → rotation on the whole, in radian
    URL: SVG Circle Arc http://xahlee.info/js/svg_circle_arc.html
    Version 2019-06-19
     ] */
    delta = delta % (2*PI);
    let rotMatrix = f_rotate_matrix (phi);
    let [sX, sY] = ( f_vec_add ( f_matrix_times ( rotMatrix, [rx * cos(t1), ry * sin(t1)] ), [cx,cy] ) );
    let [eX, eY] = ( f_vec_add ( f_matrix_times ( rotMatrix, [rx * cos(t1+delta), ry * sin(t1+delta)] ), [cx,cy] ) );
    let fA = ( (  delta > PI ) ? 1 : 0 );
    let fS = ( (  delta > 0 ) ? 1 : 0 );
    if (isNaN(eX) || isNaN(eY)) {
        return null;
    }
    //return  "M " + sX + " " + sY + " A " + [ rx , ry , phi / (2*PI) *360, fA, fS, eX, eY ];
    return  `M ${sX.toFixed(0)} ${sY.toFixed(0)} A ${rx.toFixed(0)} ${ry.toFixed(0)} ${(phi / (2*PI) *360).toFixed(0)} ${fA.toFixed(0)} ${fS.toFixed(0)} ${eX.toFixed(0)} ${eY.toFixed(0)}`;
});