import React, {Component} from "react";
import {connect} from "react-redux";

import {Card, Col, Collapse, ListGroup, ListGroupItem, Row} from "react-bootstrap";
import YouTube from "react-youtube";
import WorkoutNotesControl from "./WorkoutNotes";
import {Link} from "react-router-dom";
import {Event} from "./Tracking";

class IntroductionMessage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: true
        }
    }

    toggle() {
        if(!this.state.open) {
            Event("view_introduction", {})
        }
        this.setState({
            open : !this.state.open
        })
    }
    renderShort() {


        return (
            <div className="introductionContainer shadowContainer">
                <Row>
                    <Col className="text-center">
                        <h4> <a href="https://booking.powerwatts.co.il/index/welcome" target="_blank">New to PowerWatts? Learn More</a></h4>
                    </Col>
                </Row>
            </div>
        )
    }


    render() {
        return (
            <div className="introductionContainer shadowContainer">
                <Row>
                    <Col className="text-center">
                        <h4><a href="#"  onClick={()=>this.toggle()}>Introduction workouts</a> <a href="https://booking.powerwatts.co.il/index/welcome" target="_blank">Learn More</a></h4>
                    </Col>
                </Row>
                <Collapse in={this.state.open}>
                <Row>

                    <Col  className="text-left">
                        <Card style={{width: '25rem'}}>

                            <Card.Body>
                                <Card.Title></Card.Title>
                            </Card.Body>
                            <ListGroup className="introductionList list-group-flush">
                                {
                                    this.props.introductionsList.map((item, key) => {
                                            let category = item.category !== null ? item.category : ''
                                            return (
                                                <ListGroupItem key={key}>
                                                    <WorkoutNotesControl key={'intro' + key} wid={item.wid} name={item.name} />

                                                    <Link
                                                        onClick={() =>
                                                            Event("click", {page:"home", component:"IntroductionMessage", target:"introduction workout", workout_name:"item.name"})
                                                        }
                                                        className="text-nowrap" to={{
                                                        pathname: "virtual-workout",
                                                        state: {wid: item.id},
                                                    }}>{item.name}
                                                    </Link>


                                                </ListGroupItem>
                                            )
                                        }
                                    )
                                }
                            </ListGroup>
                        </Card>
                    </Col>

                </Row>
                </Collapse>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    introductionsList: state.session.introductions,

});
export default connect(mapStateToProps)(IntroductionMessage)
