import React, {Component} from "react";
import YouTube from "react-youtube";
import { Col} from "react-bootstrap";
import {connect} from "react-redux";

import {workoutStatus} from "../actions";

class CoachVideo extends Component {
    constructor(props){
        super(props);
        this.player = null;
        this.state  = {
            playerState:null
        }
    }


    render() {
        let workout = this.props.workout;
        let session = this.props.session;
        let timer = this.props.timer.workout;

        if (!workout.initialized) {
            return '';
        }

        if (typeof session.video_id ==='undefined' || session.video_id===null) {
        return  <Col >
                    <h3 className="text-warning">No video available</h3>
                </Col>
        }

        if(this.player) {
            let seekTo = timer + session.video_start_sec;
            let diff = Math.abs(this.player.getCurrentTime() - seekTo);

            if (timer>0 && diff > 3) {
                this.player.seekTo(seekTo);
                this.player.pauseVideo();
                this.player.playVideo();
            }
        }


        if (workout.status===workoutStatus.started && this.state.playerState!==workoutStatus.started) {
            this.player.playVideo();
        }

        if (workout.status!==workoutStatus.started && this.state.playerState===workoutStatus.started) {
            this.player.pauseVideo();
        }

        let videoId = session.video_id;
        const opts = {
            height:"100%",
            playerVars: {
                // https://developers.google.com/youtube/player_parameters
                autoplay: 0,
                class:'video',
                controls:0,
                autohide:1,
                modestbranding:1,
                fs:0,
                showinfo:0,
                rel:0
            },
        };


        return (
            <div className="video">
                <YouTube videoId={videoId} opts={opts} onReady={this._onReady.bind(this)} onStateChange={this._onStateChange.bind(this)}/>
            </div>
        );
    }
    _onStateChange(event) {
        let playerState= null;
        if(event.target.getPlayerState){
            switch(event.target.getPlayerState()) {
                case 1 :
                    playerState=  workoutStatus.started;
                    break;
                case 2:
                    playerState=  workoutStatus.paused;
                    break;
                default:
                    playerState= workoutStatus.stopped;
                    break;
            }
            this.setState({
                playerState : playerState
            });
        }
    }


    _onReady(event) {

        this.player  = event.target;
        // access to player in all event handlers via event.target
        this.player.pauseVideo();
    }


}

const mapStateToProps = state => ({
    timer: state.timer,
    session: state.session,
    workout: state.workout,
});
export default connect(mapStateToProps, {})(CoachVideo)
