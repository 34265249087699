import {workoutStatus} from "../actions";
import {round} from "../utils/statistics";
import {connect} from "react-redux";
import MetricsGauge from "./MetricsGauge";
import {isEmpty} from "../utils/statistics";

class RemoteMetricsGauge extends  MetricsGauge{
    constructor(props){
        super(props);
        this.userEmail = props.email;
    }

    isVisible(){
        return this.props.visualExperience.coach_screen_display_rider_metrics;
    }
    metricsSubset() {
        let started = (this.props.status === workoutStatus.started);
        let interval  = this.props.interval;
        let currentIntervalId =this.props.interval!==null ?  this.props.interval.intervalId:null;
        let nextInterval = this.props.nextInterval;
        let stats, cp;
        let hasStats = true;
        if (currentIntervalId === null || typeof this.props.metrics[currentIntervalId] === "undefined") {
            hasStats = false;
        } else {
            let metricsMap = this.props.metrics[currentIntervalId];
            stats = metricsMap.get(this.userEmail);
            if (typeof stats === "undefined" || stats === null) {
                hasStats = false;
            } else {
                 cp = stats.cp;
            }
        }


        let load = {
            power : (!started)? 0 : this.getPower(interval),
            nextPower : (!started)? 0 : this.getPower(nextInterval),
            power_window_low : (!started)? 0 : !isEmpty(interval.powerWindowLow) ? interval.powerWindowLow : 30,
            power_window_high : (!started)? 0 : !isEmpty(interval.powerWindowHigh) ? interval.powerWindowHigh : 30,
            cadence : (!started)? 0 : !isEmpty(interval.cadence) ? interval.cadence :  85,
            cadence_window_low :   (!started)? 0 : !isEmpty(interval.cadenceWindowLow) ? interval.cadenceWindowLow : 30,
            cadence_window_high :  (!started)? 0 :  !isEmpty(interval.cadenceWindowHigh) ? interval.cadenceWindowHigh : 30,
            next_power_window_low : (!started  || !nextInterval)? 0 : !isEmpty(nextInterval.powerWindowLow) ? nextInterval.powerWindowLow : 30,
            next_power_window_high : (!started || !nextInterval)? 0 : !isEmpty(nextInterval.powerWindowHigh) ? nextInterval.powerWindowHigh : 30,
        };

        if (started) {
            load = this.fixSelfPacedWindow(load, cp);
        }


        return  {
            workout_ts :(!started)? 0  :  this.props.timer.workout,
            cp : (!started)? 0  : cp,
            watts:  (!hasStats)? 0  :stats.currentWatts,
            power:(!hasStats)? 0  : stats.currentPower,
            avgPower:  (!hasStats)? 0 :Math.round(stats.avgPower),
            avgWatts: (!hasStats)? 0 : Math.round(stats.avgWatts),
            powerLoad: (!started)? 0 :Math.round(load.power),
            nextPowerLoad: (!started)? 0 :Math.round(load.nextPower),
            wattsLoad: (!hasStats)? 0 :Math.round(load.power/100 *cp),
            powerWindowLow: (!started)? 0 :Math.round(load.power_window_low/cp*100),
            powerWindowHigh: (!started)? 0 :Math.round(load.power_window_high/cp*100),
            nextPowerWindowLow: (!started)? 0 :Math.round(load.next_power_window_low/cp*100),
            nextPowerWindowHigh: (!started)? 0 :Math.round(load.next_power_window_high/cp*100),
            cadence: (!hasStats)? 0  : Math.round(stats.currentCadence),
            avgCadence: (!hasStats)? 0 :round(stats.avgCadence,1),
            cadenceLoad: (!started)? 0 :Math.round(load.cadence),
            cadenceWindowLow: (!started)? 0 : Math.round(load.cadence_window_low),
            cadenceWindowHigh: (!started)? 0 : Math.round(load.cadence_window_high),
            speed:0
        };
    }
}

const mapStateToProps = state =>({
    status: state.workout.status,
    users: state.generalMetrics.users,
    metrics: state.generalMetrics.usersMetrics,
    timer: state.timer,

    interval : (state.workout.interval) ? state.workout.interval : null,
    nextInterval : (state.workout.nextInterval) ? state.workout.nextInterval : null,
    visualExperience : state.visualExperience
});

export default connect(mapStateToProps,{})(RemoteMetricsGauge)

