import {isGameInterval, normalizedPower, round, isEmpty} from "./statistics";


export function generateCpxPData(user, session, workoutId, intervals, generalMetrics, ble, samples)
{
    let steps = [];
    let totalWork = 0;
    let workoutStartTime = null;
    let np = normalizedPower(samples);
    let avgp =  samples.length>0 ? samples.reduce((p, c) => p + c.p, 0) / samples.length : 0;
    let totTiz, totTaz , totTbz ;
    totTiz= totTaz=totTbz = 0;
    intervals.map(interval => {
        totalWork += interval.totalWork;
        if (workoutStartTime === null  && typeof interval.start_ts!=='undefined' ) {
            workoutStartTime = interval.start_ts;
        }
        let userMetrics = getMetrics(generalMetrics, interval.intervalId, user.email);
        let intervalDuration = (interval.end_ts-interval.start_ts)/1000;
        let tizS =  interval.power_accuracy.tiz / 100 * intervalDuration;
        let tazS =  interval.power_accuracy.toz / 100 * intervalDuration;
        let tbzS =  interval.power_accuracy.tbz / 100 * intervalDuration;
        totTiz+=tizS;
        totTaz+=tazS;
        totTbz+=tbzS;
        steps.push({
            Step: interval.intervalId,
            ResultString: interval.shortText,
            AveragePower: interval.relative_power,
            WattsAvg: interval.average_power,
            WattsMax: interval.maxPower,
            WattsNorm: isEmpty(interval.normalizedPower)? 0 :interval.normalizedPower,
            TargetPower: interval.targetPower,
            TargetPowerWindow: interval.targetPowerWindow,
            CadenceAvg: interval.average_cadence,
            CadenceMax: interval.minCadence,
            CadenceMin: interval.maxCadence,
            CPX: interval.criticalPower,
            IntervalTypeString: interval.intervalType,
            DistanceKMs: round(interval.distance,3),
            Rank: !isGameInterval(interval.intervalType) || userMetrics===null || typeof userMetrics==='undefined' ? null : userMetrics.rank,
            Points: interval.power_accuracy.grade,
            SpeedAvg: round(interval.avg_speed,2),
            SpeedMax: round(interval.max_speed,2),
            TargetCAD: interval.targetCadence,
            TargetCADWindow: interval.targetCadenceWindow,
            MilesTraveled: round(interval.distance*0.621371,3),
            NumberOfSecondsInTarget: round(tizS,2),
            NumberOfSecondsInOrAboveTarget: round(tazS,2),
            NumberOfSecondsInOrBelowTarget: round(tbzS,2),
            WpKgInterval: null,                 ///TODO verify that it is not null !
            WPrimePercentBalance_Start: null,
            WPrimePercentBalance_End: null,
            TimeInMinutesAtInterval_START: (interval.start_ts-workoutStartTime) / 60000,
            TimeInMinutesAtInterval_END: (interval.end_ts-workoutStartTime) / 60000,
        });
        return null;
    });
    let startTime = new Date(workoutStartTime);
    let total = totTbz+ totTiz + totTaz;
    return {
        ActualStartTimeOfWorkout: startTime.toISOString(),
        User_ID: user.cpxUserId,
        WorkoutId: workoutId,
        cpXUserIdentifier: session.coach_id,
        TotalWork: Math.round(totalWork),
        NormalizedPower: Math.round(np) ,
        AveragePower: Math.round(avgp) ,
        PowerAccuracy : {
            tbz : round((total>0 ? totTbz / total* 100 :0),2),
            tiz : round( (total>0 ? totTiz / total* 100 :0),2),
            toz : round( (total>0 ? totTaz / total* 100 :0) ,2),
        },
        SessionGUID: session.sessionId,
        LocationID: session.locationId,
        VersionID: 1.0,
        BleDevice: ble.device_name,
        MasterDataList: samples,
        ListOfWorkoutStepData: steps,
        ListOfPersonalRecords: [],
    }



}

function getMetrics(metricsMap,intervalId, email)
{
    if (typeof metricsMap[intervalId]==="undefined" || metricsMap[intervalId] === null) {
        return null;
    }
    return metricsMap[intervalId].get(email);
}