import   {Component} from "react";
import {connect} from "react-redux";

import {
    startWorkout, switchInterval, stopTimer, endWorkout, timerStatus,
    startInterval, endInterval, startSet, endSet, preSwitchIntervalNotification,
    toggleAccuracyGauge, intervalType, toggleWorkoutSummary,SyncTimer, MainTimer
} from "../actions";






class Scheduler extends Component {
    constructor(props){
        super(props);
        this.scheduledActions = [];
        this.state={
            lastChange:null
        }
    }

    prepareWorkoutActions(){
        this.scheduledActions = [];

        let accTime = 0;
        let lastDuration = 0;
        if(this.props.workout.initialized) {
            let workout = this.props.workout.plan;
            this.scheduledActions.push({
                time: accTime,
                callback: this.props.startWorkout
            });



            for (let set of workout.sets) {
                this.scheduledActions.push({
                    time: accTime,
                    callback: this.props.startSet
                });

                for (let interval of set.intervals) {

                    this.scheduledActions.push({
                        time: accTime,
                        callback: this.props.startInterval
                    });

                    lastDuration  =  interval.durationInSeconds;
                    accTime += interval.durationInSeconds;
                    if( interval.durationInSeconds>5) {
                        this.scheduledActions.push({
                            time: accTime - 3,
                            callback: this.props.preSwitchIntervalNotification
                        });
                    }

                    this.scheduledActions.push({
                        time: accTime,
                        callback: this.props.endInterval
                    });

                    this.scheduledActions.push({
                        time: accTime,
                        callback: this.props.switchInterval
                    });

                    if( interval.intervalType!==intervalType.recover ) {
                        this.scheduledActions.push({
                            time: accTime +1,
                            callback: ()=>this.props.toggleAccuracyGauge(true)
                        });
                        this.scheduledActions.push({
                            time: accTime +15,
                            callback: ()=>this.props.toggleAccuracyGauge(false)
                        });
                    }



                }

                this.scheduledActions.push({
                    time: accTime,
                    callback: this.props.endSet
                });


            }

            this.scheduledActions.push({
                time: accTime-60,
                callback: this.props.toggleWorkoutSummary
            });

            this.scheduledActions.push({
                time: accTime,
                callback: this.props.endWorkout
            });
            this.scheduledActions.push({
                time: accTime,
                callback: this.props.stopTimer
            });
        }
    }

    runPendingActions(props) {
        let time = props.timer.workout;
        let progress = props.workout.progress;
        let status = props.timer.status;
        let user =  props.user;
        let interval = props.workout.interval;

        if( status === timerStatus.started ) {
            for(let action of this.scheduledActions) {
                if( interval!==null && action.time===time && typeof action.done === "undefined"){
                    interval.setId= progress.set;
                    interval.intervalId = progress.intervalId;
                    action.callback(user,interval, progress.set, progress.interval );
                    action.done = true;
                }
            }

        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext){
        let initialized = nextProps.workout.initialized;
        if(initialized && this.scheduledActions.length === 0 ) {
            this.prepareWorkoutActions();
        }

        if(this.props.timer.workout!==nextProps.timer.workout) {
            this.runPendingActions(nextProps);
            return false;
        }

        return false;
    }

    componentWillUnmount() {
        if(MainTimer)
            clearInterval(MainTimer);
        if(SyncTimer)
            clearInterval(SyncTimer);
    }



    render(){
        return '';

    }
}






const mapStateToProps = state =>({
    session: state.session,
    workout: state.workout,
    timer: state.timer,
    user: state.user
});
export default connect(mapStateToProps,{startWorkout, switchInterval,startInterval,preSwitchIntervalNotification,endInterval,startSet,endSet,stopTimer,endWorkout, toggleAccuracyGauge, toggleWorkoutSummary})(Scheduler)
//export default WorkoutSteps;
