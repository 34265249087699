import React, {Component} from "react";
import {connect} from "react-redux";
import {intervalType} from "../actions";
import {round, roundStr, toRelative} from "../utils/statistics";
import {toRank} from "../utils/SvgHelpers";

export  class GameGauge extends Component {
    static defaultProps = {
        colors: {
            primaryColor : '#ffffff',
            blue: '#3595F0',
            green: '#2F8F00',
            red: '#ff0000',
            tar: '#ffffff',
            tiz: '#7f007f',
            spr: '#026902',
            agr: '#ff0000',
        },

        gradient: [],
        width: 400,
        height: 30,
        
        accuracyMin: 0,
        accuracyMax: 100,
    };

    constructor(props)
    {
        super(props);
        this.userEmail = this.props.user.info.email;
    }

    isVisible(){
        return this.props.visualExperience.display_rider_metrics;
    }


    getMetrics(email) {
        let currentIntervalId = this.props.interval !== null ? this.props.interval.intervalId : null;

        let metrics;
        if (currentIntervalId === null || typeof this.props.metrics[currentIntervalId] === "undefined") {
            return null;
        } else {
            let metricsMap = this.props.metrics[currentIntervalId];
            metrics = metricsMap.get(email);
            if (typeof metrics === "undefined" || metrics === null) {
                return null;
            }
            return metrics;
        }
    }

    myRank(myMetrics) {
        if (myMetrics === null || myMetrics.grade === null) {
            return null;
        }
        let myGrade = myMetrics.grade;

        let users = this.props.users;
        let otherMetrics;
        let myRank = 1;
        for (let elem of users) {
            otherMetrics = this.getMetrics(elem[0]);
            if (otherMetrics === null || otherMetrics.grade === null) {
                continue;
            }
            if (myGrade < otherMetrics.grade) {
                myRank++;
            }
        }
        return myRank;
    }

    getStyles(metrics){
        let height = this.props.height-4;
        let margin = this.props.width/5;
        let width = this.props.width-2*margin;

        let centerX = this.props.width/2;
        let centerY =  this.props.height ;
        let zones =  metrics.zones;
        let tbz = toRelative(zones.tbz/zones.tot*100, this.props.accuracyMin,this.props.accuracyMax, width-2 );
        let tiz = toRelative(zones.tiz/zones.tot*100, this.props.accuracyMin,this.props.accuracyMax, width-2 );
        let toz = toRelative(zones.toz/zones.tot*100, this.props.accuracyMin,this.props.accuracyMax, width-2 );

        return {
            colors: this.props.colors,
            width: width,
            height: height,
            innerHeight: height-2,
            centerX: centerX,
            centerY: centerY,
            rank:{
                x: margin/2,
                y: height/2,
                size:'1.4rem',
            },
            frame: {
                x:margin-1,
                width: width,
            },
            tbz: {
                value:tbz,
                x:margin,
                width: tbz,
                color: this.props.colors.blue
            },
            tiz: {
                value:tiz,
                x:margin+tbz,
                width: tiz,
                color: this.props.colors.green
            },
            toz : {
                value:toz,
                x:margin+tbz+tiz,
                width: toz,
                color: this.props.colors.red

            }

        }
    }

    tizGauge(rank, metrics) {

        let styles = this.getStyles(metrics);
        const viewBox = "0 0 " + this.props.width + ' ' + this.props.height;

        let time = new Date(null);
        time.setSeconds(metrics.zones.tiz/4); // bug due to updating 4 times ????

        let m = (time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes());
        let s = (time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds());
        let timeInZone = m + ':'  +s;
        let tizPercentage = round(metrics.zones.tiz/metrics.zones.tot*100,1);
        let rankObj = toRank(rank)
        return (
            <svg  viewBox={viewBox} >


                <rect

                    fill={styles.tbz.color}
                    strokeWidth="0"
                    width={styles.tbz.width}
                    height={styles.innerHeight}
                    x={styles.tbz.x}
                    y='2' />

                <rect

                    fill={styles.tiz.color}
                    strokeWidth="0"
                    width={styles.tiz.width}
                    height={styles.innerHeight}
                    x={styles.tiz.x}
                    y='2' />
                <rect

                    fill={styles.toz.color}
                    strokeWidth="0"
                    width={styles.toz.width}
                    height={styles.innerHeight}
                    x={styles.toz.x}
                    y='2' />
                <rect
                    stroke={styles.colors.primaryColor}
                    fill="transparent"
                    rx="5"
                    strokeWidth="2"
                    width={styles.frame.width}
                    height={styles.height}
                    x={styles.frame.x}
                    y='1' />
                <g id="texts-accuracy-rank"  textAnchor="middle">
                    <text dominantBaseline="middle" textAnchor="middle" x={styles.rank.x} y={styles.rank.y } fill={rankObj.color} fontSize={styles.rank.size}>{rankObj.icon}</text>
                </g>
                <g id="texts-accuracy"  textAnchor="middle">
                    <text dominantBaseline="middle" textAnchor="middle" x={styles.centerX} y={styles.height/2 } fill={styles.colors.primaryColor} fontSize="1rem">{tizPercentage}% {timeInZone}  ± {roundStr(metrics.deltaPower,2)}</text>
                </g>
            </svg>
        )

    }

    tarGauge(rank, metrics) {
        let styles = this.getStyles(metrics);
        const viewBox = "0 0 " + this.props.width + ' ' + this.props.height;
        let rankObj = toRank(rank)
        return (
            <svg  viewBox={viewBox} >
                <g id="texts-accuracy-rank"  textAnchor="middle">
                    <text dominantBaseline="middle" textAnchor="middle" x={styles.rank.x} y={styles.rank.y } fill={rankObj.color} fontSize="1.5rem">{rankObj.icon}</text>
                </g>
                <g id="texts-accuracy"  textAnchor="middle">
                    <text dominantBaseline="middle" textAnchor="middle" x={styles.centerX} y={styles.height/2 } fill={styles.colors.tar} fontSize="1.5rem">{roundStr(metrics.grade,3)}</text>
                </g>
            </svg>
        );

    }

    agrGauge(rank, metrics) {
        let styles = this.getStyles(metrics);
        const viewBox = "0 0 " + this.props.width + ' ' + this.props.height;
        let rankObj = toRank(rank)
        return (
            <svg  viewBox={viewBox} >
                <g id="texts-accuracy-rank"  textAnchor="middle">
                    <text dominantBaseline="middle" textAnchor="middle" x={styles.rank.x} y={styles.rank.y } fill={rankObj.color} fontSize={styles.rank.size}>{rankObj.icon}</text>
                </g>
                <g id="texts-accuracy"  textAnchor="middle">
                    <text dominantBaseline="middle" textAnchor="middle" x={styles.centerX} y={styles.height/2 } fill={styles.colors.agr} fontSize="1rem">{round(metrics.avgWatts,0)}w {round(metrics.grade)}%</text>
                </g>
            </svg>
        );
    }

    sprGauge(rank, metrics, user)
    {
        let styles = this.getStyles(metrics);
        const viewBox = "0 0 " + this.props.width + ' ' + this.props.height;
        let rankObj = toRank(rank);
        let weight =  user.isWeightMetric ?  user.weight : user.weight * 0.453592
        let grade = roundStr(metrics.avgWatts / weight, 2);  /// TODO check if weight is not null
        let unit = 'W/Kg' ;
        return (
            <svg  viewBox={viewBox} >
                <g id="texts-accuracy-rank"  textAnchor="middle">
                    <text dominantBaseline="middle" textAnchor="middle" x={styles.rank.x} y={styles.rank.y } fill={rankObj.color} fontSize={styles.rank.size}>{rankObj.icon}</text>
                </g>
                <g id="texts-accuracy"  textAnchor="middle">
                    <text dominantBaseline="middle" textAnchor="middle" x={styles.centerX} y={styles.height/2 } fill={styles.colors.spr} fontSize="1rem">{grade} {unit}</text>
                </g>
            </svg>
        );
    }


    render() {
        let metrics = this.getMetrics(this.userEmail);
        if (metrics === null) {
            return '';
        }
        if(!this.isVisible()){
            return '';
        }
        let rank = metrics.rank;
        let user = this.props.user;
        switch (this.props.interval.intervalType) {
            case intervalType.tar:
                return this.tarGauge(rank, metrics);
            case intervalType.tiz:
                return this.tizGauge(rank, metrics);
            case intervalType.toz:
                return this.tizGauge(rank, metrics);
            case intervalType.agr:
                return this.agrGauge(rank, metrics);
            case intervalType.spr:
                return this.sprGauge(rank, metrics, user);
            default:
                return '';
        }
    }
}


const mapStateToProps = state => ({
    status: state.workout.status,
    interval: (state.workout.interval) ? state.workout.interval : null,
    user: state.user,
    users: state.generalMetrics.users,
    metrics: state.generalMetrics.usersMetrics,
    timer: state.timer.workout,
    visualExperience : state.visualExperience
});
export default connect(mapStateToProps, {})(GameGauge)