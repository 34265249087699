export const METRICS_CHANGED = 'METRICS_CHANGED';
export const SYNC_MESSAGE = 'SYNC_MESSAGE';
export const accuracyStatus = {
    low : 'low',
    in_zone : 'in_zone',
    high : 'high',
}

export function syncMessage(metrics){
    return function (dispatch) {
        dispatch(
            {
                type : SYNC_MESSAGE,
                payload:{
                    metrics : {}
                }

            }
        )
    };

}
export function metricsChanged(metrics){
    return function (dispatch) {
        dispatch(
            {
                type : METRICS_CHANGED,
                payload:{
                    metrics : metrics
                }

            }
        )
    };

}





