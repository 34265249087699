import React, {Component} from "react";
import { Col} from "react-bootstrap";
import {connect} from "react-redux";



class ZoomVideo extends Component {

    render() {
        let workout = this.props.workout;
        if (!workout.initialized) {
            return '';
        }
        let zoomMeeting = this.props.session.zoom_id;
        let zoomPassword = this.props.session.zoom_password;
        let email = this.props.user.info.email;
        let name = this.props.user.info.full_name;


        if (zoomMeeting === null) {
            return <Col>
                <h3 className="text-warning">Zoom Meeting Was not linked to this workout</h3>
            </Col>
        }
        let frameSrc = 'https://booking.powerwatts.co.il/workout/zoom?meeting_number='+encodeURI(zoomMeeting)+'&meeting_password='+encodeURI(zoomPassword)+'&email='+encodeURI(email) +'&nickname=' + encodeURI(name);
        return  (
            <div className="zoom-video">
                <div className="">
                    <iframe frameBorder="0" allowFullScreen="1"
                            title="Zoom meeting" height="100%"
                            src={frameSrc}
                            allow="camera *;microphone *"
                            id="zoomiframe"></iframe>
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => ({
    session: state.session,
    user: state.user,
    workout: state.workout,
});
export default connect(mapStateToProps, {})(ZoomVideo)
