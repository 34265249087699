import React, {Component} from "react";
import {PropTypes} from "prop-types";
import {connect} from "react-redux";
import {isEmpty, toRelative} from "../utils/statistics";
import {intervalType} from "../actions";


class WorkoutImage extends Component {

    static defaultProps = {
        colors: {
            primaryColor : '#ffffff',
            yellow:'#FFFF15',
            blue: '#00007f',
            red: '#C01818',
            purple: '#eb0eeb',
            tar: '#696969',
            tiz: '#7f007f',
            spr: '#026902',
            agr: '#ff0000',
            toz: '#f05512',
            //TODO: add 9900ff with opacity for hidden segment
        },
        gradient: [],
        width: 1024,
        height: 130,
        margin:0

    };
    getStyles() {
        return {
            width: this.props.width,
            height: this.props.height,
            colors: this.props.colors
        }
    }
    render() {
        let workout = this.props.workout;
        if (!workout.initialized) {
            return '';
        }
        if(!this.props.display_workout_profile){
            return '';
        }
        const styles = this.getStyles();
        const progres =  this.props.timer;
        const viewBox = "0 0 " + styles.width + ' ' + styles.height;
        const secStepSize = styles.width / workout.plan.duration;

        let sets =  workout.plan.sets;



        let maxLoad = 0
        sets.map( set=> {
            set.intervals.map( interval => {
                let power = 0;
                if(!isEmpty(interval.rampUp)){
                    power = Math.max(parseFloat(interval.rampUp[0]),parseFloat(interval.rampUp[1]));
                }else {
                    power = parseFloat(interval.relativePower);

                }

                if (power>maxLoad) {
                    maxLoad = power;
                }
                return null;
            })
        });

        let cp20Height = styles.height - toRelative(100,0,maxLoad,styles.height-10);
        const pathData = "M0 "+ cp20Height + " L" + styles.width +  " " + cp20Height;
        const currentPathData = "M" +(progres*secStepSize)+ " 0 L" +(progres*secStepSize) +  " " + styles.height;

        return (
            <svg  viewBox={viewBox}>
                <path d={pathData} stroke={styles.colors.red}  strokeWidth="1"  strokeDasharray="3,1"/>
                <SetsDrawing sets={sets} step_size={secStepSize} styles={styles} max_load={maxLoad} scale={styles.height-10}/>
                <SpecialIntervalsDrawing sets={sets} step_size={secStepSize} styles={styles} max_load={maxLoad} scale={styles.height-10}/>
                <CadenceDrawing sets={sets} step_size={secStepSize} styles={styles} max_load={maxLoad} scale={styles.height-10}/>
                <path d={currentPathData} stroke={styles.colors.yellow}  strokeWidth="3" />
            </svg>
        )
    }

}

const SpecialIntervalsDrawing = (props) => {
    let sets = props.sets;
    let stepSize = props.step_size;
    let styles = props.styles;
    let currentTime = 0;
    return (
        sets.map((set, sKey) => {
            return set.intervals.map((interval, iKey) => {
                let x = Math.round(currentTime * stepSize);
                let width = Math.round(interval.durationInSeconds * stepSize);
                let height = styles.height;
                currentTime = currentTime+  interval.durationInSeconds;
                let color = '';
                switch(interval.intervalType) {
                    case intervalType.tar:
                        color = styles.colors.tar;
                        break;
                    case intervalType.agr:
                        color = styles.colors.agr;
                        break;
                    case intervalType.tiz:
                        color = styles.colors.tiz;
                        break;
                    case intervalType.spr:
                        color = styles.colors.spr;
                        break;
                    case intervalType.toz:
                        color = styles.colors.toz;
                        break;
                    default:
                        return '';
                }
                return (<rect
                    stroke={color}
                    strokeWidth="1"
                    stroke-opacity="0.6"
                    fill={color}
                    fill-opacity="0.25"
                    width={width}
                    height={height}
                    rx="1"
                    ry="1"
                    x={x}
                    y="1" />);
            })
        })

    )


}
const CadenceDrawing = (props) => {
    let sets = props.sets;
    let stepSize = props.step_size;
    let styles = props.styles;
    let maxLoad = props.max_load;
    let scale = props.scale;


    let currentTime = 0;
    return (<polygon fill="none" stroke={styles.colors.purple} strokeWidth="2"  strokeDasharray="2,1" points={
            '0,' + styles.height + ' ' +
            sets.map((set, sKey) => {
                return set.intervals.map((interval, iKey) => {
                    let x1 = Math.round(currentTime * stepSize);
                    let y1 = styles.height - toRelative((!isEmpty(interval.cadence) ? parseFloat(interval.cadence) : -5) ,0,maxLoad,scale);

                    currentTime = currentTime+  interval.durationInSeconds;
                    let x2 = Math.round(currentTime * stepSize);
                    let y2 = styles.height - toRelative((!isEmpty(interval.cadence) ? parseFloat(interval.cadence) : -5) ,0,maxLoad,scale);


                    return x1 + ',' + y1 + " " +x2 + ',' + y2 + " "
                })
            }) + ' ' + styles.width + ',' + styles.height
        }/>
    )
}

const SetsDrawing =  (props) => {
    let sets = props.sets;
    let stepSize = props.step_size;
    let styles = props.styles;
    let maxLoad = props.max_load;
    let scale = props.scale;


    let currentTime = 0;
    return (<polygon fill={styles.colors.blue} stroke={styles.colors.primaryColor} stroke-opacity="0.8" strokeWidth="1" stroke-linejoin="round" points={
            '0,' + styles.height + ' ' +
            sets.map((set, sKey) => {
                return set.intervals.map((interval, iKey) => {
                    let x1 = Math.round(currentTime * stepSize);
                    let y1 = styles.height - toRelative((!isEmpty(interval.rampUp) ? parseFloat(interval.rampUp[0]) : parseFloat(interval.relativePower)) ,0,maxLoad,scale);

                    currentTime = currentTime+  interval.durationInSeconds;
                    let x2 = Math.round(currentTime * stepSize);
                    let y2 = styles.height - toRelative((!isEmpty(interval.rampUp) ? parseFloat(interval.rampUp[1]) : parseFloat(interval.relativePower)) ,0,maxLoad,scale);


                    return x1 + ',' + y1 + " " +x2 + ',' + y2 + " "
                })
            }) + ' ' + styles.width + ',' + styles.height
        }/>
    )
}




WorkoutImage.propTypes = {
    workout: PropTypes.object,

};
const mapStateToProps = state =>({
    workout: state.workout,
    timer: state.timer.workout,
    display_workout_profile: state.visualExperience.display_workout_profile
});
export default connect(mapStateToProps,{})(WorkoutImage)