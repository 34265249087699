import {broadcastSyncMessage} from './signalrMessages';
import {loadWorkout} from './workout';
import {Event} from "../Components/Tracking";
import {MainTimer, STOP_TIMER} from "./timer";

export const RESET_SESSION = 'RESET_SESSION';
export const START_SESSION = 'START_SESSION';

export const SESSION_READY = 'SESSION_READY';
export const COACH_SESSION_READY = 'COACH_SESSION_READY';
export const COACH_SYNC_TIMERS = 'COACH_SYNC_TIMERS';
export const SESSIONS_LIST_READY = 'SESSIONS_LIST_READY';
export const SESSIONS_LIST_PENDING = 'SESSIONS_LIST_PENDING';
export const SESSIONS_LIST_FAILED = 'SESSIONS_LIST_FAILED';
export const TP_LIST_READY = 'TP_LIST_READY';
export const TP_LIST_PENDING = 'TP_LIST_PENDING';
export const TP_LIST_FAILED = 'TP_LIST_FAILED';

export const PLANS_LIST_READY = 'PLANS_LIST_READY';
export const PLANS_LIST_PENDING = 'PLANS_LIST_PENDING';
export const PLANS_LIST_FAILED = 'PLANS_LIST_FAILED';


export const CONNECTIONS_LIST_PENDING = 'CONNECTIONS_LIST_PENDING';
export const CONNECTIONS_LIST_READY = 'CONNECTIONS_LIST_READY';
export const CONNECTIONS_LIST_FAILED = 'CONNECTIONS_LIST_FAILED';
export let SyncTimer = null;

export function resetSession(){
    return function (dispatch) {
        dispatch(
            {
                type: RESET_SESSION,
                payload:{
                    startTs: Date.now(),
                }
            }
        )
    };
}

export function changeWorkout(newWorkoutId){
    return function (dispatch, getState) {
        let externalId =  getState().session.external_id;
        let cpxUserId = encodeURI(getState().user.info.cpxUserId);
        let token = getState().user.info.token;

        let url = 'https://booking.powerwatts.co.il/workout/changeWorkout?external_id='+externalId + '&new_workout='+newWorkoutId;
        let payload = {};
        fetch(url, {
            headers: new Headers({
                'X-CPX-Authorization': 'Bearer ' + token,
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        }).catch(function () {
            console.log('failed to join workout ');
        }).then(res => res.json())
        .then(responseObj => {
                dispatch(resetSession(externalId));
            })
        .then(responseObj => {
                dispatch(startCoachSession(externalId));
            })
        .then(responseObj => {
            dispatch(startSession(externalId));
        });
    }
}
export function listConnections() {
    return function (dispatch, getState) {
        let token = encodeURI(getState().user.info.token);
        let connectionsStatus = getState().session.connections_status;
        if(connectionsStatus!==null){
            return ;
        }
        let url = 'https://booking.powerwatts.co.il/workout/connections';
        let payload = {};
        dispatch(
            {
                type: CONNECTIONS_LIST_PENDING,
                payload: payload
            }
        );
        fetch(url, {
            headers: new Headers({
                'X-CPX-Authorization': 'Bearer ' + token,
                'Content-Type': 'application/x-www-form-urlencoded',
            })
        }).then(res => res.json())
            .then(responseObj => {
                if (responseObj.success) {
                    if (responseObj.success) {
                        payload = {
                            connections: responseObj.connections,
                        }
                        dispatch(
                            {
                                type: CONNECTIONS_LIST_READY,
                                payload: payload
                            }
                        );
                        if (responseObj.connections.training_peaks.connected) {
                            dispatch(listTrainingPeaksSessions());
                        }
                    }
                }
            }).catch(function () {
            dispatch(
                {
                    type: CONNECTIONS_LIST_FAILED,
                }
            );
        });
    }
}

export function listTrainingPeaksSessions() {
    return function (dispatch, getState) {
        let token = encodeURI(getState().user.info.token);
        let sessionsStatus = getState().session.tp_workouts_status;
        if(sessionsStatus!==null){
            return ;
        }
        let url = 'https://booking.powerwatts.co.il/workout/trainingpeaks?offset=' + new Date().getTimezoneOffset();
        let payload = {};
        dispatch(
            {
                type: TP_LIST_PENDING,
                payload: payload
            }
        );
        fetch(url, {
            headers: new Headers({
                'X-CPX-Authorization': 'Bearer ' + token,
                'Content-Type': 'application/x-www-form-urlencoded',
            })
        }).then(res => res.json())
            .then(responseObj => {
                if (typeof responseObj.redirect !== 'undefined') {
                    window.location.href = responseObj.redirect;
                } else if (responseObj.success) {
                    payload = {
                        training_peaks_wods: typeof responseObj.training_peaks_wods !== 'undefined' ?   responseObj.training_peaks_wods : [],
                    }
                    dispatch(
                        {
                            type: TP_LIST_READY,
                            payload: payload
                        }
                    );
                }

            }).catch(function () {
            dispatch(
                {
                    type: TP_LIST_FAILED,
                }
            );
        });
    }
}


export function listTrainingPlans() {
    return function (dispatch, getState) {
        let token = encodeURI(getState().user.info.token);
        let sessionsStatus = getState().session.tp_workouts_status;
        if(sessionsStatus!==null){
            return ;
        }
        let url = 'https://booking.powerwatts.co.il/workout/trainingplans';
        let payload = {};
        dispatch(
            {
                type: PLANS_LIST_PENDING,
                payload: payload
            }
        );
        fetch(url, {
            headers: new Headers({
                'X-CPX-Authorization': 'Bearer ' + token,
                'Content-Type': 'application/x-www-form-urlencoded',
            })
        }).then(res => res.json())
            .then(responseObj => {
                if (typeof responseObj.redirect !== 'undefined') {
                    window.location.href = responseObj.redirect;
                } else if (responseObj.success) {
                    payload = {
                        training_plans: typeof responseObj.training_plans !== 'undefined' ?   responseObj.training_plans : [],
                    }
                    dispatch(
                        {
                            type: PLANS_LIST_READY,
                            payload: payload
                        }
                    );
                }

            }).catch(function () {
            dispatch(
                {
                    type: PLANS_LIST_FAILED,
                }
            );
        });
    }
}



export function listSessions() {
    return function (dispatch, getState) {
        let token = encodeURI(getState().user.info.token);
        let sessionsStatus = getState().session.sessions_status;
        if(sessionsStatus!==null){
            return ;
        }

        let url = 'https://booking.powerwatts.co.il/workout/sessions?offset=' + new Date().getTimezoneOffset();
        let payload = {};
        dispatch(
            {
                type: SESSIONS_LIST_PENDING,
                payload: payload
            }
        );
        fetch(url, {
            headers: new Headers({
                'X-CPX-Authorization': 'Bearer ' + token,
                'Content-Type': 'application/x-www-form-urlencoded',
            })
        }).then(res => res.json())
            .then(responseObj => {
                if (typeof responseObj.redirect !== 'undefined') {
                    window.location.href = responseObj.redirect;
                } else if (responseObj.success) {
                    payload = {
                        sessions: responseObj.sessions,
                        workouts: responseObj.workouts,
                        introductions: responseObj.introductions,
                        training_peaks_wods: typeof responseObj.training_peaks_wods !== 'undefined' ?   responseObj.training_peaks_wods : [],
                    }
                }
                dispatch(
                    {
                        type: SESSIONS_LIST_READY,
                        payload: payload
                    }
                );
        }).catch(function () {
            dispatch(
                {
                    type: SESSIONS_LIST_FAILED,
                }
            );
        });
    }
}


export function startCoachSession(id){
    return function (dispatch, getState) {

        dispatch(
            {
                type: COACH_SESSION_READY,
                payload: {
                    connect_time: Math.floor(Date.now() / 1000)
                }
            }
        );
    }
}

export function stopSyncTimers(){
    if(SyncTimer) {
        clearInterval(SyncTimer);
        SyncTimer = null;
    }
}
export function syncTimers(){
    return function (dispatch, getState) {
        let coachSession = getState().session.coachSession;
        let userId = getState().user.info.cpxUserId;
        let coachId = getState().session.coach_id;
        if (coachSession && coachId === userId) {
            if(SyncTimer) {
                clearInterval(SyncTimer);
            }
            SyncTimer = setInterval(()=> {
                broadcastSyncMessage(getState);
            },1000);
            console.log('sync timers is enabled' + coachId+  ' ' + userId);
            dispatch(
                {
                    type: COACH_SYNC_TIMERS,
                    payload: {}
                }
            );
        } else {
            console.log('only main coach can sync the timers' + coachId+  ' ' + userId);
        }
    }
}


export function startSession(id){
    return function (dispatch, getState) {
        if(typeof id ==='undefined'){
            id = null;
        }
        let payload = {};
        let token = getState().user.info.token;
        let coachMode = (getState().session.coachSession=== true ? 1: 0);




        let sessionUrl = 'https://booking.powerwatts.co.il/workout/join?external_id='+id + '&localtime='+Date.now()  + '&coach_mode=' + coachMode;
        fetch(sessionUrl ,{
            headers: new Headers({
                'X-CPX-Authorization': 'Bearer ' + token,
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        }).catch(function () {
            console.log('failed to join workout ');
        }).then(res => res.json())
        .then(responseObj => {
            if(responseObj.success) {
                let workoutObj = responseObj.workout;
                let video_id = typeof workoutObj.video_url==='undefined'  || workoutObj.video_url===null ?  null :  workoutObj.video_url.replace("https://youtu.be/","")
                Event("joined_workout",{booking_id: workoutObj.external_id, coach_name:workoutObj.coach_name, coach_id:workoutObj.coach_id, video_id:video_id});
                payload = {
                    external_id: workoutObj.external_id,
                    sessionId: workoutObj.rtid,
                    zoom_id: workoutObj.zoom_id,
                    zoom_password: workoutObj.zoom_password,
                    coach_name: workoutObj.coach_name,
                    coach_id: workoutObj.coach_id,
                    auto_start_at:  workoutObj.start_in_seconds ? Date.now() + workoutObj.start_in_seconds*1000 : null,
                    auto_start_in_seconds: workoutObj.start_in_seconds,
                    workout_url: workoutObj.url,
                    video_id: video_id,
                    video_start_sec: workoutObj.video_start_sec ? workoutObj.video_start_sec : null,
                };
                dispatch(
                    {
                        type: SESSION_READY,
                        payload: payload
                    }
                );
                return workoutObj;
            }
        }).then((workoutObj)=> {
            dispatch(syncTimers());
            dispatch(loadWorkout());
        }).catch(function (error) {
            console.log('failed to load workout ');
            console.log(error);
        })
    }
}