import React, {Component, useState} from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {Event} from "./Tracking";
import {connect} from "react-redux";
import {buyContract,buyService, loadSubscriptions, loadingState, ProductType} from "../actions";
import {Link} from "react-router-dom";


class CreditCardForm extends Component {
    constructor(props) {
        super(props)
        let user = this.props.user;

        let expiry = new Date();
        let month = "01";
        const year = expiry.getFullYear();

        this.state = {
            cvc: "",
            expiry_month:  month,
            expiry_year:   year,
            expiry: month+ '/' +year,
            name: user.info.first_name + ' ' + user.info.last_name,
            number: ""
        }
    }

    onSubmit(e){
        e.preventDefault()
        Event("click", {target: "pay"});
        let selected = this.props.selected_product;
        if (selected === null) {
            return;
        }
        switch (selected.type) {
            case ProductType.contract:
                return this.props.buyContract(selected.product_id, this.state);
            case ProductType.service:
                return this.props.buyService(selected.product_id, this.state);
            default:
                break;
        }
    }


    handleExpMonth(e){
        this.setState({
            [e.target.name]: e.target.value,
            ['expiry']: e.target.value+ '/' +this.state.expiry_year
        })
    }
    handleExpYear(e){
        this.setState({
            [e.target.name]: e.target.value,
            ['expiry']: this.state.expiry_month+ '/' +e.target.value
        })
    }
    handleInputChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    };

    render() {
        let payment_state = this.props.payment_state;
        let data = this.state
        let error = this.props.last_error
        let now = new Date();
        let yearRange = [];
        for(let year=now.getFullYear() ; year< now.getFullYear()+10 ; year++){
            yearRange.push(year) ;
        }

        if (payment_state === loadingState.ready) {
            //this.props.loadSubscriptions();
            return (
                <div className="paymentSuccessContainer">
                    <h4 className="text-success text-center">Thank You!</h4>
                    <Row>

                        <Col className="text-left">
                            <p>A new contract was added to to your account</p>
                            <p className="subscription_name">{this.props.purchased_item.name}</p>
                            <p  className="reference">Contract REF: {this.props.ref_id}</p>

                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            <Link
                                onClick={() =>
                                    Event("click", {page:"checkout", component:"CreditCart", target:"payment_success"})
                                }
                                to="/home">
                                <Button variant="success">Enjoy The App!</Button>
                            </Link>
                        </Col>
                    </Row>
                </div>
            )
        }


        return (
            <div className="PaymentForm">
                <Cards
                    cvc={data.cvc}
                    expiry={data.expiry}
                    focus={data.focus}
                    name={data.name}
                    number={data.number}
                />
                <div className="text-danger">{error}</div>

                <form onSubmit={this.onSubmit.bind(this)} >
                    <input
                        type="number"
                        name="number"
                        placeholder="Card Number"
                        onChange={this.handleInputChange.bind(this)}
                    />
                    <input
                        type="text"
                        name="name"
                        placeholder="Your Name"
                        onChange={this.handleInputChange.bind(this)}
                        value={data.name}
                    />
                    <select name="expiry_month" onChange={this.handleExpMonth.bind(this)} style={{width:'100px',float:'left'}}>
                        <option value="01">Jan</option>
                        <option value="02">Feb</option>
                        <option value="03">Mar</option>
                        <option value="04">Apr</option>
                        <option value="05">May</option>
                        <option value="06">Jun</option>
                        <option value="07">Jul</option>
                        <option value="08">Aug</option>
                        <option value="09">Sep</option>
                        <option value="10">Oct</option>
                        <option value="11">Nov</option>
                        <option value="12">Dec</option>
                    </select>
                    /
                    <select name="expiry_year" onChange={this.handleExpYear.bind(this)} style={{width:'100px',lear:'left'}}>
                        {yearRange.map((y)=>  <option value={y}>{y}</option>)}
                    </select>



                    <input
                        type="number"
                        name="cvc"
                        placeholder="CVC"
                        onChange={this.handleInputChange.bind(this)}
                    />

                    {
                        (payment_state===loadingState.pending) ?
                        <Button variant="light">
                            processing...
                        </Button>
                        :
                        <Button variant="success"  type="submit">
                            Pay
                        </Button>
                    }

                </form>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    user : state.user,
    last_error : state.subscription.last_error,
    payment_state : state.subscription.payment_state,
    ref_id : state.subscription.ref_id,
    purchased_item : state.subscription.purchased_item,
    selected_product : state.subscription.selected_product

});
export default connect(mapStateToProps,{buyContract,buyService,loadSubscriptions})(CreditCardForm)