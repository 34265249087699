import {Event, TrackingProps} from "../Components/Tracking";


export const CONNECT_USER = 'CONNECT_USER';
export const SET_CRITICAL_POWER = 'SET_CRITICAL_POWER';
export const SET_PROFILE_METRICS = 'SET_PROFILE_METRICS';
export const CONNECT_COACH = 'CONNECT_COACH';





export  function addUser(userObj){
    Event("user_login",  {email:userObj.email ,roles:userObj.roles, cpxUserId:userObj["https://myapp.example.com/CPXUserId"]})
    return {
        type: CONNECT_USER,
        payload : {
            first_name :typeof userObj.given_name !== "undefined"  ? userObj.given_name : '',
            last_name : typeof userObj.family_name !== "undefined"  ? userObj.family_name : '',
            nickname : userObj.nickname,
            full_name :userObj.name,
            picture :userObj.picture,
            email :userObj.email,
            token :userObj.token,
            roles :userObj.roles,
            cpxUserId : userObj["https://myapp.example.com/CPXUserId"]
        }
    }
}

export function addCoach(userObj, center = null){
    return function (dispatch) {
        Event("coach_login",{email:userObj.email, name:userObj.first_name + ' ' +  userObj.last_name,roles:userObj.roles, center:center});

        dispatch(
            {
                type: CONNECT_COACH,
                payload : {
                    first_name : userObj.first_name,
                    last_name : userObj.last_name,
                    center :center
                }
            }
        )
    };

}


export function setCriticalPower(cp,time=20){
    return function (dispatch) {
        dispatch(
            {
                type: SET_CRITICAL_POWER,
                payload: {
                    critical_power : cp,
                    time: time
                }
            }
        )
    };


}
export function loadUserMetrics(){
    return function (dispatch, getState) {
        let token = getState().user.info.token;
        let email = getState().user.info.email;
        let requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer ' + token,
                'email': email.toLowerCase()
            },
        };
        fetch('https://api.powerwatts.com/users' , requestOptions)
            .then(response => response.json())
            .then(userProfile => {

                let bDate = new Date(userProfile.BirthDate );
                let now = new Date();
                let age = now.getFullYear() -bDate.getFullYear();
                TrackingProps({email: userProfile.Email, cpx_id: userProfile.Id, age:age ,gender: userProfile.Sex, center: userProfile.PrimaryStudioIndex});

                dispatch(
                    {
                        type: SET_PROFILE_METRICS,
                        payload: {
                            birthDate: userProfile.BirthDate,
                            isMale: userProfile.Sex === 'male',
                            nickname: userProfile.Nickname,
                            full_name: userProfile.Name,
                            isWeightMetric: true,
                            primaryPowerWattsCenter: userProfile.PrimaryStudioIndex,
                            wPrime: userProfile.WPrime,
                            wPrimeCP: userProfile.WPrimeCP,
                            weight: userProfile.Weight,
                            height: userProfile.Height,
                            critical_power: userProfile.Cp20M,
                            connected_to_strava: userProfile.ConnectedToStrava,
                            connected_to_training_peaks: userProfile.ConnectedToTrainingPeaks,
                            cp: {
                                cp5s: userProfile.Cp5S,
                                cp10s: userProfile.Cp10S,
                                cp20s: userProfile.Cp20S,
                                cp30s: userProfile.Cp30S,
                                cp45s: userProfile.Cp45S,
                                cp1m: userProfile.Cp1M,
                                cp2m: userProfile.Cp2M,
                                cp3m: userProfile.Cp3M,
                                cp5m: userProfile.Cp5M,
                                cp10m: userProfile.Cp10M,
                                cp20m: userProfile.Cp20M,
                                cp30m: userProfile.Cp30M,
                                cp45m: userProfile.Cp45M,
                                cp60m: userProfile.Cp60M,
                            }
                        }
                    }
                )})
            .catch(error => {
                console.log(error);
            });

    }
}

export function updateUserProfile(data){
    return function (dispatch, getState) {
        Event("update_profile",{email: getState().user.info.email});
        let token = getState().user.info.token;
        let email = getState().user.info.email;
        let requestOptions = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer ' + token,
                'email': email.toLowerCase()
            },
            body: JSON.stringify(data)
        };

        fetch('https://api.powerwatts.com/users' , requestOptions)
        .then(response => response.json())
        .catch(error => {
            console.log(error);
        });
    };
}


export function presetCriticalPower(cp,time=20){
    return function (dispatch, getState) {
        Event("set_critical_power",{email: getState().user.info.email});
        let data = {
            'Cp20M':cp
        }
        let token = getState().user.info.token;
        let email = getState().user.info.email;
        let requestOptions = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer ' + token,
                'email': email.toLowerCase()
            },
            body: JSON.stringify(data)
        };

        fetch('https://api.powerwatts.com/users' , requestOptions)
            .then(response => response.json())
            .catch(error => {
                console.log(error);
            });


        dispatch(
            {
                type: SET_CRITICAL_POWER,
                payload: {
                    critical_power : cp,
                    time: time
                }
            }
        )
    };


}





export function setUserProfileMetrics(userProfile){
    return function (dispatch) {
        dispatch(
            {
                type: SET_PROFILE_METRICS,
                payload: {
                    birthDate: userProfile.BirthDate,
                    isMale: userProfile.IsMale,
                    isWeightMetric: userProfile.IsWeightMetric,
                    primaryPowerWattsCenter: userProfile.PrimaryPowerWattsCenter,
                    wPrime: userProfile.WPrime,
                    wPrimeCP: userProfile.WPrimeCP,
                    weight: userProfile.Weight,
                    height: userProfile.HeightInMeters,
                    critical_power:userProfile.CpTwentyM,
                    cp: {
                        cp5s: userProfile.CpFiveS,
                        cp10s: userProfile.CpTenS,
                        cp20s: userProfile.CpTwentyS,
                        cp45s: userProfile.CpFourtyFiveS,
                        cp1m: userProfile.CpOneM,
                        cp2m: userProfile.CpTwoM,
                        cp3m: userProfile.CpThreeM,
                        cp5m: userProfile.CpFiveM,
                        cp10m: userProfile.CpTenM,
                        cp20m: userProfile.CpTwentyM,
                        cp30m: userProfile.CpThirtyM,
                        cp45m: userProfile.CpFourtyFiveM,
                        cp60m: userProfile.CpSixtyM,
                    }
                }
            }
        )
    };
}




