import {SIGN_IN, SIGN_OUT,RESET_SESSION} from '../actions'


const initialState = {
    loggedIn: false,
};

const authReducer = (state=initialState, action) => {
    switch (action.type) {
        case RESET_SESSION:
            return initialState;
        case SIGN_IN:
            return {
                loggedIn: true
            };
        case SIGN_OUT:
            return {
                loggedIn: false
            };
        default:
            return state
    }
};

export default authReducer;