import {convertLoadToUserMetrics} from '../utils/statistics';
import {startTimer} from "./timer";
import {startHubConnection} from "./signalrMessages";
import {toSetInterval} from "../utils/statistics";
import {generateCpxPData} from "../utils/Cpxp";
import {Event} from "../Components/Tracking";


export const LOAD_WORKOUT = 'LOAD_WORKOUT';
export const SEND_WORKOUT_NOTES = 'SEND_WORKOUT_NOTES';
export const LOAD_WORKOUT_NOTES = 'LOAD_WORKOUT_NOTES';
export const HIDE_WORKOUT_NOTES = 'HIDE_WORKOUT_NOTES';
export const LOAD_SET = 'LOAD_SET';
export const LOAD_INTERVAL = 'LOAD_INTERVAL';

export const START_WORKOUT = 'START_WORKOUT';
export const END_WORKOUT = 'END_WORKOUT';
export const PAUSE_WORKOUT = 'PAUSE_WORKOUT';
export const START_INTERVAL = 'START_INTERVAL';
export const END_INTERVAL = 'END_INTERVAL';
export const SWITCH_INTERVAL = 'SWITCH_INTERVAL';
export const PRE_SWITCH_INTERVAL = 'PRE_SWITCH_INTERVAL';
export const START_SET = 'START_SET';
export const END_SET = 'END_SET';
export const FAST_FORWARD = 'FAST_FORWARD';
export const SEND_WORKOUT_SUMMARY = 'SEND_WORKOUT_SUMMARY';



export const intervalType = {
    warm_up : 'warm-up',
    recover: 'recover',
    active_recovery: 'active_recovery',
    power: 'power_only',
    power_slope: 'power_and_slope',
    power_cadence: 'power_and_cadence',
    tiz: 'tiz',
    taz: 'taz',
    toz: 'toz',
    tar : 'tar',
    agr : 'agr',
    kom : 'kom',
    kod : 'kom',
    spr : 'spr',
};


export const workoutStatus = {
    pending_start : 'pending_start',
    started : 'started',
    stopped: 'stopped',
    paused: 'paused',
    autoPaused: 'auto-paused',
};

export function loadWorkoutNotes(wid){
    return function (dispatch, getState) {
        if(getState().workout.workoutNotesSent){
            return;
        }

        dispatch(
            {
                type: SEND_WORKOUT_NOTES,
                payload: {
                    timestamp: Date.now()
                }
            }
        );


        let token = getState().user.info.token;
        let requestOptions = {
            method: 'GET',
            headers: {
                'X-CPX-Authorization':'Bearer ' + token
            },
        };
        let workoutNotesUrl = 'https://booking.powerwatts.co.il/workout/notes?wid=' + wid;
        fetch(workoutNotesUrl, requestOptions)
            .then(res => res.text())
            .then(body => {
                dispatch(
                    {
                        type: LOAD_WORKOUT_NOTES,
                        payload: {
                            wid: wid,
                            html: body
                        }
                    }
                )
            })
            .catch(function (error) {
            console.log(error);
        });
    }
}
export function hideWorkoutNotes(){
    return function (dispatch, getState) {
        dispatch(
            {
                type: HIDE_WORKOUT_NOTES,
            }
        )
    }
}

export  function loadWorkout(workoutUrl){

    return function (dispatch, getState) {
        //let defaultWorkoutUrl = 'https://pw-virtual-studio.s3.eu-central-1.amazonaws.com/sharpener.json';
        //let defaultWorkoutUrl = 'https://pw-virtual-studio.s3.amazonaws.com/lucky7.json';
        //let defaultWorkoutUrl = 'https://pw-virtual-studio.s3.amazonaws.com/tabata.json';
        //let defaultWorkoutUrl = 'http://pw-virtual-studio.s3.amazonaws.com/testWorkout.json';
        //let defaultWorkoutUrl = 'https://pw-virtual-studio.s3.amazonaws.com/chaos.json';
        //let defaultWorkoutUrl = 'https://pw-virtual-studio.s3.amazonaws.com/the_staminator.json';
        //let defaultWorkoutUrl = 'https://pw-virtual-studio.s3.amazonaws.com/all_mixed_up.json';
        //let defaultWorkoutUrl = 'https://pw-virtual-studio.s3.amazonaws.com/the_hour_of_power.json';
        //let defaultWorkoutUrl = 'https://pw-virtual-studio.s3.amazonaws.com/terrible_twos.json';
        let defaultWorkoutUrl = 'https://pw-virtual-studio.s3.amazonaws.com/test_workout.json';

        if (typeof workoutUrl === 'undefined' || workoutUrl === null) {
            workoutUrl = getState().session.workout_url;
        }
        if (workoutUrl===null){
            workoutUrl = defaultWorkoutUrl;
        }
        let generalIndex = 0;
        fetch(workoutUrl)
            .then(res => res.json())
            .then(workoutObj => {
                let duration = 0;
                workoutObj.sets.map((set,sIndex) => {
                    let sDuration = 0;
                    set.index = sIndex;
                    set.intervals.map((interval,index) => {
                        sDuration += interval.durationInSeconds
                        interval.index = index;
                        interval.intervalId = generalIndex;
                        generalIndex++;
                        if(interval.intervalType === intervalType.recover){
                            interval.powerWindowLow  = -1
                            interval.powerWindowHigh  = null
                        }
                        return interval;
                    });
                    duration += sDuration;
                    return set;
                });
                Event("load_workout",{wid: workoutObj.workoutId,workout_name:workoutObj.workoutName, duration:duration });
                dispatch(
                    {
                        type: LOAD_WORKOUT,
                        payload: {
                            workoutId: workoutObj.workoutId,
                            videoId: typeof workoutObj.video_url==='undefined' ?  null :  workoutObj.video_url.replace("https://youtu.be/",""),
                            videoStartSec: typeof workoutObj.video_start_sec==='undefined' ?  0 : parseInt(workoutObj.video_start_sec),
                            workoutName: workoutObj.workoutName,
                            duration: duration,
                            intensityFactor: workoutObj.intensityFactor,
                            description: workoutObj.description,
                            workoutPlanImage: workoutObj.workoutPlanImage,
                            sets: workoutObj.sets
                        }
                    }
                )
                return workoutObj;
            }
        ).then ( (workoutObj) => {
            if(!getState().session.hubConnectionReady) {
                dispatch(startHubConnection());
            }
        }).catch(function (error) {
            console.log(error);
        }).then ( (workoutObj) => {
            if ( getState().session.auto_start_in_seconds) {
                dispatch(startTimer());
                if(getState().session.auto_start_in_seconds<0){
                    dispatch(fastForward(Math.abs(getState().session.auto_start_in_seconds)));
                }

            }

        }).catch(function (error) {
            console.log(error);
        });

    }

}






export  function startWorkout(set=null, interval=null) {
    return function (dispatch) {
        dispatch(
            {
                type: START_WORKOUT,
                payload: {
                    setId : 0,
                    intervalId: 0,
                    timestamp: Date.now(),
                }
            }
        )
    };

}

export  function switchInterval() {
    return function (dispatch,getState) {
        let interval = getState().workout.interval;

        dispatch(
            {
                type: SWITCH_INTERVAL,
                payload: {
                    duration: (interval && interval.durationInSeconds) ? interval.durationInSeconds: 0,
                }
            }
        )
    };

}


export  function preSwitchIntervalNotification(set=null, interval=null) {
    return function (dispatch) {
        dispatch(
            {
                type: PRE_SWITCH_INTERVAL,
                payload: {
                    set: set,
                    interval: interval,
                }
            }
        )
    };

}

export  function fastForward(seconds) {

    return function (dispatch, getState) {

        let data = toSetInterval(getState().workout.plan, seconds);
        if (data === null) {
            return;
        }
        dispatch(
            {
                type: FAST_FORWARD,
                payload: {
                    timestamp: Date.now(),
                    second: seconds,
                    setId: data.setId,
                    intervalIndex: data.intervalIndex,
                    intervalId: data.intervalId,
                    secondInInterval :  data.secondInInterval
                }
            }
        )
    };
}


export  function pauseWorkout(set=null, interval=null) {
    return function (dispatch) {
        dispatch(
            {
                type: PAUSE_WORKOUT,
                payload: {
                    set: set,
                    interval: interval,
                }
            }
        )
    };

}




export  function endWorkout() {


    return (dispatch, getState) => {
        let state = getState();
        let samples = state.metrics.samples;

        let workout = state.workout.plan;
        dispatch(
            {
                type: END_WORKOUT,
                payload: {
                    timestamp: Date.now()
                }
            }
        );

        if (getState().workout.workoutSummarySent) {
            return;
        }

        let token = getState().user.info.token;
        dispatch(
            {
                type: SEND_WORKOUT_SUMMARY,
                payload: {
                    timestamp: Date.now()
                }
            }
        );

        let summary = generateCpxPData(
            state.user.info,
            state.session,
            workout.workoutId,
            state.metrics.intervals,
            state.generalMetrics.usersMetrics,
            state.ble,
            samples
        );
        let requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer ' + token
            },
            body: JSON.stringify(summary)
        };
        Event("workout",{cpx_id: summary.User_ID,wid: summary.WorkoutId,coach_id:summary.cpXUserIdentifier , total_work:summary.TotalWork, np:summary.NormalizedPower  });
        fetch('https://api.powerwatts.com/activities' , requestOptions)
            .then(response => response.json())
            .catch(error => {
                console.log(error);
            });
        delete requestOptions['headers']['Authorization'];

        requestOptions['headers']['X-CPX-Authorization'] =  'Bearer ' + token
        fetch('https://booking.powerwatts.co.il/workout/summary?wid=' +encodeURI(workout.workoutId) , requestOptions)
            .then(response => response.json())
            .catch(error => {
                console.log(error);
            });
    };

}


export  function startInterval(user, interval) {

    return function (dispatch , getState) {
        let progress = getState().workout.progress;
        let interval = getState().workout.interval;
        let load = convertLoadToUserMetrics(user, interval);
        dispatch(
            {
                type: START_INTERVAL,
                payload: {
                    timestamp: Date.now(),
                    setId : progress.set,
                    intervalId : progress.intervalId,
                    critical_power: load.critical_power,
                    power : load.power,
                    cadence : load.cadence,
                    power_window: [load.power_window_low, load.power_window_high],
                    cadence_window: [load.cadence_window_low, load.cadence_window_high],
                    visualExperience: (typeof interval.visualExperience!== 'undefined') ? interval.visualExperience : {}
                }
            }
        )
    };

}

export  function endInterval(user, interval) {
    let load = convertLoadToUserMetrics(user, interval);
    return function (dispatch) {
        dispatch(
            {
                type: END_INTERVAL,
                payload: {
                    timestamp: Date.now(),
                    setId : interval.setId,
                    intervalId : interval.intervalId,
                    critical_power: load.critical_power,
                    power : load.power,
                    cadence : load.cadence,
                    power_window: [load.power_window_low, load.power_window_high],
                    cadence_window: [load.cadence_window_low, load.cadence_window_high],
                    interval_type: interval.intervalType,
                    duration :interval.durationInSeconds,
                    shortText: interval.shortText
                }
            }
        )
    };

}


export  function startSet(user, interval) {
    return function (dispatch) {
        dispatch(
            {
                type: START_SET,
                payload: {
                    timestamp: Date.now(),
                    setId : interval.setId,
                }
            }
        )
    };

}

export  function endSet(user, interval) {
    return function (dispatch) {
        dispatch(
            {
                type: END_SET,
                payload: {
                    timestamp: Date.now(),
                    setId : interval.setId,
                }
            }
        )
    };

}