import React  from "react";
import { useDispatch} from "react-redux";
import {useAuth0} from "../react-auth0-spa";
import Loading from "../Components/Loading";
import CreditCardForm from "../Components/CreditCardForm";
import {loadProducts,ProductType} from "../actions";
import {Col, Container, Row} from "react-bootstrap";
import ProductsSelector from "../Components/ProductsSelector";
import powerwatts_logo from "../assets/powerwatts_logo.png";
import {useParams } from "react-router-dom";

export const CheckoutView  =  (props)=>{
    let dispatch = useDispatch();
    let { productType } = useParams();
    const { loading, user } = useAuth0();
    dispatch(loadProducts());
    if (loading || !user) {
        return <Loading />;
    }
    console.log(productType);
    const date = new Date();
    const day = date.getDate();


    switch (productType) {
        case ProductType.contract:
        case ProductType.service:
            break
        default:
            if(typeof  props.location.state!=="undefined") {
                productType = props.location.state.productType;
            } else {
                productType = ProductType.contract
            }
            break;
    }


    return (
        <Container className="flex-grow-1 mt-3  h-100">

            <div className="shadowContainer">

                <div className="checkoutContainer">
                    <h2 className="text-center"><img src={powerwatts_logo} alt="powerwatts" />  Virtual Studio</h2>

                    <Row>
                        <ProductsSelector productType={productType}/>
                    </Row>

                    <hr/>

                    <Row>
                        <Col className="text-center">
                                <CreditCardForm />
                        </Col>
                    </Row>

                    <a href="https://company.mindbodyonline.com/" target="_blank">
                        <img style={{margin:"auto",height:'70px'}} src="https://developers.mindbodyonline.com/Content/Images/Branding/06-mindbody-branding-guidelines-powered-by.png"/>
                    </a>
                </div>
            </div>
        </Container>
    )



}