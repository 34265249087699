import React, { Component} from "react";


export class AutoFocus extends Component {



    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.state = {
            location: null
        };
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (this.state.location !== nextProps.location);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.focus();
        this.setState({
            location: this.props.location
        })
    }


    render() {
        return <div style={{height:0, width:0}} ref={this.myRef}/>
    }

    focus() {
        this.myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    // run this method to execute scrolling.
}