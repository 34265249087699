import React from 'react';
import { Router,Route,   Switch } from "react-router-dom";
import history from "./utils/history";
import PrivateRoute from "./Components/PrivateRoute";
import {TrainAloneStudio, TrainWithVirtualCoachStudio, TrainWithGroupStudio} from "./pages/VirtualStudio";
import Home from './pages/Home';
import Loading from './Components/Loading';
import { useAuth0 } from "./react-auth0-spa";
import {connect} from "react-redux";
import {resetSession,listSessions, toggleWorkoutSummary} from "./actions";
import {CoachView} from "./pages/CoachView";
import {CheckoutView} from "./pages/CheckoutView";
import {Logout} from "./pages/Logout";
import {Landing} from "./pages/Landing";

const App = (props) => {

        const {loading  } = useAuth0();

        if (loading) {
            return <Loading />;
        }




        return (
            <div>
                <div id="app" className="d-flex flex-column">
                    <Router history={history} basename="/">
                        <Switch>
                            <Route path="/" exact component={Landing} />
                            <PrivateRoute path="/home" exact component={Home} />
                            <PrivateRoute path="/private-workout" component={TrainAloneStudio} />
                            <PrivateRoute path="/private-workout" component={TrainAloneStudio} />
                            <PrivateRoute path="/virtual-workout" component={TrainWithVirtualCoachStudio}  />
                            <PrivateRoute path="/virtual-workout" component={TrainWithVirtualCoachStudio}  />
                            <PrivateRoute path="/class-workout" component={TrainWithGroupStudio}   />
                            <PrivateRoute path="/class-workout" component={TrainWithGroupStudio}   />
                            <PrivateRoute path="/coach" component={CoachView}   />
                            <PrivateRoute path="/coach" component={CoachView}   />


                            <PrivateRoute path="/checkout/:productType" component={CheckoutView}   />

                            <Route path="/logout" component={Logout}   />
                        </Switch>
                    </Router>
                </div>
            </div>
        );

};

const mapStateToProps = state =>({
    requested_wid :  state.session.requested_wid,
});
export default connect(mapStateToProps,{resetSession,listSessions,toggleWorkoutSummary})(App)