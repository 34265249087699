import React, {Component, useState} from "react";
import {connect} from "react-redux";
import {bookPrivateClass, loadBookedClasses, loadingState} from "../actions";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChalkboardTeacher, faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {Event} from "./Tracking";
import {Button, ListGroupItem, Modal} from "react-bootstrap";
import Loading from "./Loading";
import {isEmpty} from "../utils/statistics";

class PaidSessionLink extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.props.loadBookedClasses()
    }

    render() {

        let isCoach = typeof this.props.user.info.roles !== 'undefined' && this.props.user.info.roles.includes('Coach');
        let session = this.props.session;
        let booked_classes = this.props.booked_classes;
        let key =this.props.key;
        let hostingCenter = session.hosting_center !== null ? session.hosting_center : '';
        let sessionNote = isEmpty(session.session_note) ?  ''  : '(' + session.session_note +')';
        let coachComment = '';
        let isBooked = booked_classes.has(session.external_id);
        if (session.workout_name === null) {
            if (isCoach) {
                coachComment += 'Define the workout in the coach screen'
            } else {
                return '';
            }
        }

        return (
            <ListGroupItem key={key} className={!isBooked?"unregisteredClass" : ''}>
                {isCoach ?  <CoachLink  session={session}/> : ''}
                &nbsp;
                {isBooked ? <BookedLink session={session}/> : <UnregisteredLink  session={session}/>}

                <div className="text-right">{hostingCenter} {sessionNote}</div>
                <div className='text-danger'>{coachComment}</div>
            </ListGroupItem>
        )
    }
}

const CoachLink = (props) => {
    let session = props.session;
    return (
        <Link
            to={{
                pathname: "coach",
                state: {externalId: session.external_id},
            }}> <FontAwesomeIcon icon={faChalkboardTeacher}/>

        </Link>
    )
}


const BookedLink = (props) =>{
    let session = props.session
    return (
        <Link
            onClick={() =>
                Event("click", {
                    target: "live class",
                    start_time: session.start_time,
                    end_time: session.end_time,
                    coach_name: session.coach_name
                })
            }
            className="text-nowrap" to={{
            pathname: "class-workout",
            state: {externalId: session.external_id},
        }}>
            {session.start_time}-{session.end_time} with {session.coach_name}
        </Link>
    )
}

const UnregisteredLink = (props) => {
    const [show, setShow] = useState(false);
    const close = () => setShow(false);
    const confirm = () => setShow(true);

    let session = props.session
    return (
        <>
            <Link
                onClick={confirm}>
                {session.start_time}-{session.end_time} with {session.coach_name}
            </Link>
            <Modal show={show} onHide={close}>
                <Modal.Header closeButton>
                    <Modal.Title>You are not registered for this class!</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    If you click <b>Enter Class</b>, you will be able to join the class and<br/>
                     a <b>LIVE class credit</b> will be deducted from your account.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={close}>
                        Go Back to Main Screen
                    </Button>

                    <Link
                        onClick={() => {
                            Event("click", {
                                target: "live class not booked",
                                start_time: session.start_time,
                                end_time: session.end_time,
                                coach_name: session.coach_name
                            });
                            close();
                        }}
                        className="text-nowrap" to={{
                        pathname: "class-workout",
                        state: {externalId: session.external_id},
                    }}> <Button variant="danger">Enter Class</Button>
                    </Link>

                </Modal.Footer>
            </Modal>
        </>
    )
}


const mapStateToProps = state => ({
    user: state.user,
    booked_classes: state.subscription.booking_list,
    booking_state: state.subscription.bookings_state

});
export default connect(mapStateToProps, {bookPrivateClass, loadBookedClasses})(PaidSessionLink)