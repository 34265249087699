import {
    LOAD_WORKOUT,
    START_WORKOUT,
    PAUSE_WORKOUT,
    END_WORKOUT,
    SWITCH_INTERVAL,
    workoutStatus, CONTROL_MESSAGE, START_TIMER, RESET_SESSION, FAST_FORWARD, SEND_WORKOUT_SUMMARY,
        SEND_WORKOUT_NOTES, LOAD_WORKOUT_NOTES,HIDE_WORKOUT_NOTES
} from '../actions'


const workoutInitialState = {
        initialized: false,
        status: null,
        plan: {},
        interval: {},
        load : {
            power : 0,
            power_window : 0,
            cadence : 0,
            cadence_window :  0
        },
        progress: {
            intervalId : null,
            set: null,
            interval: null
        },
        nextInterval: null,
        workoutSummarySent: false,
        workoutNotesSent: false,
        notes:null
}


const workoutReducer = (state=workoutInitialState, action) => {
    let nextSet = null;
    let nextInterval = null;
    let nextIntervalId = null;
    let status = null;
    let actionType = action.type;

    if(actionType === CONTROL_MESSAGE &&  state.status !== action.payload.workout_status) {
        switch(action.payload.workout_status) {
            case workoutStatus.started: actionType = START_WORKOUT; break;
            case workoutStatus.stopped: actionType = END_WORKOUT; break;
            case workoutStatus.paused: actionType = PAUSE_WORKOUT; break;
            default :
                break;//nothing to do.
        }
    }

    switch (actionType) {
        case RESET_SESSION:
            return workoutInitialState;
        case LOAD_WORKOUT:
            return  Object.assign({}, state, {
                initialized: true,
                plan : action.payload,
            });
        case LOAD_WORKOUT_NOTES:
            return  Object.assign({}, state, {
                workoutNotesSent : false,
                notes : action.payload,
            });
        case HIDE_WORKOUT_NOTES:
            return  Object.assign({}, state, {
                notes : null,
            });
        case SEND_WORKOUT_NOTES:
            return  Object.assign({}, state, {
                workoutNotesSent : true,
            });
        case START_TIMER:
        case START_WORKOUT:
            if(!state.status) {
                nextSet = 0;
                nextInterval = 0;
                nextIntervalId = 0;
            } else {
                nextSet = state.progress.set;
                nextInterval = state.progress.interval;
                nextIntervalId = state.progress.intervalId;
            }
            status = actionType===START_WORKOUT  ? workoutStatus.started : workoutStatus.pending_start;
            break;
        case  FAST_FORWARD:
            nextSet = action.payload.setId;
            nextInterval = action.payload.intervalIndex;
            nextIntervalId = action.payload.intervalId;
            status = workoutStatus.started;
            break;
        case PAUSE_WORKOUT:
            status = workoutStatus.paused;
            nextSet = state.progress.set;
            nextInterval = state.progress.interval;
            nextIntervalId = state.progress.intervalId;
            break;
        case END_WORKOUT:
            nextSet = null;
            nextInterval = null;
            nextIntervalId = null;
            status = null;
            break;
        case SEND_WORKOUT_SUMMARY:
            return Object.assign({}, state, {
                workoutSummarySent: true,
            });
     case SWITCH_INTERVAL:
            let currentSet = state.progress.set;
            let currentInterval = state.progress.interval;
            let currentIntervalId = state.progress.intervalId;
            let setsLimit = state.plan.sets.length;
            let intervalsLimit = currentSet!==null ? state.plan.sets[currentSet].intervals.length : 0 ;
            status =  state.status;

            nextIntervalId = currentIntervalId+1;
            if(currentInterval < intervalsLimit-1){
                nextSet = currentSet;
                nextInterval = currentInterval+1;
            } else if(currentSet < setsLimit -1){
                nextSet = currentSet+1;
                nextInterval = 0;
            } else {
                return state;
            }
            break;
        default :
            return state;
    }

    let interval = ((typeof state.plan.sets[nextSet] === "undefined" ||  typeof state.plan.sets[nextSet].intervals[nextInterval] === "undefined") ? null  : state.plan.sets[nextSet].intervals[nextInterval]);
    let nextIntervalObj = ((typeof state.plan.sets[nextSet] === "undefined" ||  typeof state.plan.sets[nextSet].intervals[nextInterval+1] === "undefined") ? ''  : state.plan.sets[nextSet].intervals[nextInterval+1])
    if(interval){
        interval.setId= nextSet;
        interval.intervalId= nextIntervalId;
        interval.startTs = Date.now();
    }

    return  Object.assign({}, state, {
        status : status,
        interval:interval ,
        nextInterval: nextIntervalObj,
        progress : {
            set : nextSet,
            interval :nextInterval,
            intervalId :nextIntervalId
        }
    });

};


export default workoutReducer;