
import {applyMiddleware, createStore, compose} from 'redux';
import thunk  from 'redux-thunk';
import allReducers from './reducers'

import {signalRMiddleware} from './middleware/signalRMiddleware';

const initialState = {};

const middleware = [ signalRMiddleware, thunk];

const store = createStore(
    allReducers,
    initialState,
    compose(
        applyMiddleware(...middleware),
        //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
);
//

//store.subscribe(()=> console.log((store.getState())));

export  default store;

