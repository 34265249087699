import React, {Component} from "react";

import NavigationPrompt from "react-router-navigation-prompt";
import {connect} from "react-redux";
import {endWorkout} from "../actions";
import {Button, Modal} from 'react-bootstrap';


class BlockLeavePage extends Component {
    onUnload = e => { // the method that will be used for both add and remove event
        if(this.props.workoutSummarySent)
            return '';
        let confirmationMessage = "your workout is not saved yet. are you sure you want to leave?"
        e.preventDefault();
        e.returnValue = confirmationMessage;
        return confirmationMessage
    }

    componentDidMount() {
        window.history.pushState(null, document.title, window.location.href);

        window.addEventListener('popstate', function (event){
            if(this.props.workoutSummarySent===false) {
                window.history.pushState(null, document.title, window.location.href);
            }
        }.bind(this));
        window.addEventListener("beforeunload", this.onUnload);
    }
    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.onUnload);
    }


    render() {

        return (
            <NavigationPrompt when={this.props.workoutSummarySent===false} allowGoBack={false} >
                {({ onConfirm, onCancel }) => (
                    <Modal show={true}>
                        <Modal.Header  >
                            <Modal.Title>Your workout is not saved</Modal.Title>
                        </Modal.Header>

                        <Modal.Body scrollable={true} size='xl'>
                            <p>Do you really want to leave without saving the workout statistics</p>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={onCancel}>No</Button>
                            <Button variant="success" onClick={()=> {this.props.endWorkout();window.location.href = '/home'}}>Yes</Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </NavigationPrompt>
        )
    }
}

const mapStateToProps = state =>({
    workoutSummarySent:  state.workout.workoutSummarySent
});
export default connect(mapStateToProps,{endWorkout})(BlockLeavePage)
