import  React ,{Component}from "react";
import {connect} from "react-redux";
import powerwatts_logo from "../assets/powerwatts_logo.png";
import ExternalApplications from './ExternalApplications';
import IntroductionMessage from './IntroductionMessage';
import {connectToBle, disconnectBle, connectMockBle } from "../actions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClipboard} from "@fortawesome/free-solid-svg-icons";
import UserProfile from "./UserProfile";
import TrainingPeaksWorkouts from "./TrainingPeaksWorkouts";
import {Tabs, Tab} from "react-bootstrap";
import {LogoutControl} from "./StudioControls";
import TrainingPlans from "./TrainingPlans";


class HomeMessage extends Component {

    render() {
        return (
            <div  className="homeMessageContainer">
                <h2><img src={powerwatts_logo} alt="powerwatts" />  Virtual Studio</h2>
                <p><span className="clientName">Welcome {this.props.user.full_name}</span> - Not you? <LogoutControl/></p>
                <div>
                    <p className='text-center'>Choose your ride for today</p>
                    <p className='text-center'>Click on the <FontAwesomeIcon  icon={faClipboard}/> to see the Workout Summary and Notes</p>
                </div>

                <Tabs defaultActiveKey="connect"  id="uncontrolled-tab-example" variant="dark">
                    <Tab eventKey="connect" title="Connect">
                        <ExternalApplications/>
                    </Tab>
                    <Tab eventKey="training-plans" title="Training Plans">

                        <TrainingPlans/>
                    </Tab>
                    <Tab eventKey="trainingpeaks-wod" title="TrainingPeaks Workouts">

                        <TrainingPeaksWorkouts/>
                    </Tab>
                    <Tab eventKey="introduction" title="Introduction Workouts">
                        <IntroductionMessage/>
                    </Tab>
                    <Tab eventKey="profile" title="Profile" >
                        <UserProfile/>
                    </Tab>
                </Tabs>
            </div>
        );
    }
}

const mapStateToProps = state =>({
    user : state.user.info,

});
export default connect(mapStateToProps,{connectToBle,disconnectBle, connectMockBle})(HomeMessage)