import {RESET_SESSION,BLE_CADENCE_CHANGED, BLE_POWER_CHANGED, BLE_STATUS_CHANGED, connectionStatus} from '../actions'


const initialState = {
    connection_status: connectionStatus.not_connected,
    remark : null,
    device_name: null,
    power:null,
    cadence:null
};


const bleReducer = (state = initialState, action) => {
    switch (action.type) {
        case RESET_SESSION:
            if (state.connection_status !== connectionStatus.connected) {
                return initialState;
            }
            return state;

        case BLE_STATUS_CHANGED:
            return  Object.assign({}, state, {
                connection_status : action.payload.status,
                remark : action.payload.remark,
                device_name : action.payload.device_name
            });
        case BLE_POWER_CHANGED:
            return  Object.assign({}, state, {
                power: action.payload.value
            });

        case BLE_CADENCE_CHANGED:
            return  Object.assign({}, state, {
                cadence: action.payload.value
            });
        default:
            return state;
    }
};

export default bleReducer;