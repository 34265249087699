import {connect} from "react-redux";
import React, {Component} from "react";
import {Button, Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClipboard} from "@fortawesome/free-solid-svg-icons";
import {hideWorkoutNotes,loadWorkoutNotes} from  '../actions'
import { Event } from "./Tracking";
class  WorkoutNotes extends Component {

    constructor(props)
    {
        super(props);

        this.state = {
            show: false
        };
    }
    close = () => this.setShow(false);
    showNotes(){
        Event("view_notes", { wid:this.props.wid, workout_name:this.props.name})
        this.props.loadWorkoutNotes(this.props.wid)
        this.setShow(true);
    }
    hideNotes(){
        this.props.hideWorkoutNotes()
        this.setShow(false);
    }
    setShow(show)
    {
        this.setState({show : show})
    }
    render(){
        let body = (this.props.notes!==null ) ? this.props.notes.html : '';

        return (
            <>
                <Button variant="dark" onClick={this.showNotes.bind(this)}>
                    <FontAwesomeIcon  icon={faClipboard} onClick={this.showNotes.bind(this)} />
                </Button>&nbsp;
                <Modal show={this.state.show} onHide={this.close} dialogClassName="workoutNoteModal">
                    <Modal.Header closeButton>
                        <Modal.Title>Workout Notes</Modal.Title>
                    </Modal.Header>
                    <Modal.Body scrollable={true} size='xl'><div dangerouslySetInnerHTML={{__html: body}} /></Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={()=> {this.hideNotes.bind(this);this.close() }}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

}


const mapStateToProps = state => ({
    notes: state.workout.notes,
});
export default connect(mapStateToProps, {hideWorkoutNotes,loadWorkoutNotes})(WorkoutNotes)