import React from "react";
import {Link} from 'react-router-dom';
import landingBackground from "../assets/landing-background.jpg";
import powerwatts_logo from "../assets/powerwatts_logo.png";
import login from "../assets/login.PNG";
import sign_up from "../assets/sign-up.PNG";
import {Event} from "../Components/Tracking";

export const Landing = (props) => {

    let sectionStyle = {
        backgroundImage: `url(${landingBackground})`,
        height: '100vh',
        width: '100vw',
        margin: 0,
        padding: '30vh',

    }
    Event("page_view", {page_title:"Landing Page"})
    return (

        <div className="text-center" style={sectionStyle}>
            <h2><img height="100vh" src={powerwatts_logo} alt="powerwatts"/></h2>
            <p>
                <Link
                    onClick={() =>
                        Event("click", {page:"landing page", component:"Landing", target:"Login"})
                    }
                    to="/home">
                    <img height="70vh" src={login}/>
                </Link>
            </p>
            <p>

                <a onClick={() =>
                    Event("click", {page:"landing page", component:"Landing", target:"Sign Up"})
                } href="https://booking.powerwatts.co.il/auth/pwhome_registration"> <img height="70vh"
                                                                                         src={sign_up}/></a>


            </p>


            <div onClick={() =>
                Event("click", {page:"landing page", component:"Landing", target:"Learn More"})
            }
                 className="learnMoreContainer">
                <a href="http://booking.powerwatts.co.il/index/learn" target="_blank">Learn More</a>
            </div>

        </div>


    )
}