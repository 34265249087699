import React, { Component } from "react";
import { Col} from "react-bootstrap";
import {PropTypes} from "prop-types";
import {connect} from "react-redux";
import { workoutStatus} from '../actions';



class StepIndicator extends Component {



    render() {

        let text='';
        let colorClass='';
        if ( this.props.status && this.props.status ===  workoutStatus.started ){
            switch (this.props.type) {
                case 'next':
                    let nextInterval = this.props.nextInterval;
                    text = ( nextInterval && nextInterval.shortText) ? nextInterval.shortText : '';
                    colorClass = 'NextTargetColor';
                    break;
                case 'current':
                    let current = this.props.interval;
                    text = (current.shortText) ? current.shortText : '';
                    colorClass = 'OnTargetColor';
                    break;
                default:
                    text = '';
            }
        }
        return (

                <Col className={`stepIndicator w-100 ${colorClass} text-center text-nowrap`}>
                    {text}
                </Col>

        )

    }

}


StepIndicator.propTypes = {
    interval: PropTypes.object,
    //nextInterval: PropTypes.object,
    status: PropTypes.string
};
const mapStateToProps = state =>({
    deviceConnectionStatus : state.ble.connection_status,
    status: state.workout.status,
    interval: state.workout.interval,
    timer: state.timer,
    nextInterval: state.workout.nextInterval,
    visualExperience : state.visualExperience
});
export default connect(mapStateToProps,{})(StepIndicator)