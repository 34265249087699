import React, {Component} from "react";
import Select from 'react-select'
import {connect} from "react-redux";
import {changeWorkout, workoutStatus} from "../actions";

class WorkoutsSelector extends Component {
    constructor(props) {
        super(props);
        const options =[];
        options.push({value: 'Select Workout', label:'Select Workout'})
        props.workoutsList.map((item) => {
            options.push({value: item.wid, label:item.name})
            return
        });


        this.state = {
            select: {
                value: options[0],
                options // all available options
            }
        };
    }

    setValue = value => {
        this.setState(prevState => ({
            select: {
                ...prevState.select,
                value
            }
        }));
    };
    handleChange = value => {

        this.setValue(value);
        this.props.changeWorkout(value.value);
    };

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (prevProps.workoutId !== this.props.workoutId) {
            this.setValue({value: this.props.workoutId, label: this.props.workoutName})
        }
    }

    render() {

        if (this.props.status !== workoutStatus.pending_start){ //show this component only before the workout stats
            return '';
        }
        const {select} = this.state;
        const customStyles = {
            option: (styles, {data, isDisabled, isFocused, isSelected}) => {
                return {
                    ...styles,
                    fontSize: '12px',
                    textAlign: 'left',
                    width: 'auto',
                }
            },
        }
        return (
            <Select
                name="form-field-name"
                value={select.value}
                onChange={this.handleChange}
                options={select.options}
                styles={customStyles}
            />
        );
    }
}

const mapStateToProps = state =>({
    externalId : state.session.external_id,
    workoutId : state.workout.plan.workoutId,
    workoutName : state.workout.plan.workoutName,
    workoutsList: state.session.workouts,
    status: state.workout.status
});
export default connect(mapStateToProps,{
    changeWorkout: changeWorkout
})(WorkoutsSelector)