import {
    TOGGLE_FULL_METRICS,
    TOGGLE_ACCURACY_GAUGE,
    CHAT_MESSAGE,
    CLEAR_CHAT_MESSAGE,
    CONTROL_MESSAGE, PRE_SWITCH_INTERVAL, START_INTERVAL, TOGGLE_RIDER_METRICS,
    TOGGLE_REPORT_CARD, TOGGLE_WORKOUT_PROFILE, TOGGLE_WORKOUT_SUMMARY, TOGGLE_ALL_ACTIVE_METRICS
} from '../actions'


let initialState = {
    workout_status: null,
    display_next_interval: false,
    display_workout_profile: true,
    display_workout_summary: false,
    display_course: false,
    display_workout_height: null,
    display_course_height: null,
    summary_display_watts: true,
    summary_display_pcp: null,
    summary_display_watts_offset: null,
    summary_display_cadence_offset: null,
    summary_display_ranking: null,
    summary_display_time_in_zone: null,
    chat_message: null,

    display_accuracy_gauge: false,
    display_full_metrics: false,
    display_report_card: true,
    display_rider_metrics: true,

    coach_screen_display_rider_metrics:true,
    coach_display_all_active_metrics:false
};


const visualExperienceReducer = (state=initialState, action) => {
    switch (action.type) {
        case  START_INTERVAL:
            return Object.assign({}, initialState,  action.payload.visualExperience);

        case CHAT_MESSAGE:
            return Object.assign({}, state, {
                chat_message:action.payload
            });
        case CLEAR_CHAT_MESSAGE:
            return Object.assign({}, state, {
                chat_message : null
            });
        case TOGGLE_ACCURACY_GAUGE:
            return Object.assign({}, state, {
                display_accuracy_gauge:action.payload.show
            });
        case TOGGLE_FULL_METRICS:
            return Object.assign({}, state, {
                display_full_metrics:action.payload.show
            });
        case TOGGLE_WORKOUT_PROFILE:
            initialState.display_workout_profile =  action.payload.show; //override default values so it will not be reverted on next interval
            return Object.assign({}, state, {
                display_workout_profile:action.payload.show
            });
        case TOGGLE_REPORT_CARD:
            initialState.display_report_card =  action.payload.show; //override default values so it will not be reverted on next interval
            return Object.assign({}, state, {
                display_report_card:action.payload.show
            });
        case TOGGLE_RIDER_METRICS:
            initialState.coach_screen_display_rider_metrics =  action.payload.show; //override default values so it will not be reverted on next interval
            return Object.assign({}, state, {
                coach_screen_display_rider_metrics:action.payload.show
            });
        case TOGGLE_ALL_ACTIVE_METRICS:
            initialState.coach_display_all_active_metrics =  action.payload.show; //override default values so it will not be reverted on next interval
            return Object.assign({}, state, {
                coach_display_all_active_metrics:action.payload.show
            });
        case CONTROL_MESSAGE:
            return Object.assign({}, state, action.payload);
        case PRE_SWITCH_INTERVAL:
            return Object.assign({}, state, {
                display_next_interval : true
            });
        case TOGGLE_WORKOUT_SUMMARY:
            return Object.assign({}, state, {
                display_workout_summary : action.payload.show
            });
        default:
            return state;
    }
};
export  default visualExperienceReducer;