import {metricsChanged, workoutStatus} from "../actions";
import {round} from "../utils/statistics";
import {connect} from "react-redux";
import MetricsGauge from "./MetricsGauge";
import {isEmpty} from "../utils/statistics";
import {EstimateSpeed} from "../utils/SpeedCalculator";

class UserMetricsGauge extends  MetricsGauge{


    metricsSubset() {

        let cp  = this.props.user.critical_power;
        let weight =  this.props.user.isWeightMetric ?  this.props.user.weight : this.props.user.weight * 0.453592
        let device  = this.props.device;
        let metrics  = this.props.metrics;
        let interval  = this.props.interval;
        let nextInterval = this.props.nextInterval;
        let started = (this.props.status === workoutStatus.started);


        let load  = {
            power : (!started)? 0 : this.getPower(interval),
            nextPower : (!started)? 0 : this.getPower(nextInterval),
            power_window_low : (!started)? 0 : !isEmpty(interval.powerWindowLow) ? interval.powerWindowLow : 30,
            power_window_high : (!started)? 0 : !isEmpty(interval.powerWindowHigh) ? interval.powerWindowHigh : 30,
            cadence : (!started)? 0 : !isEmpty(interval.cadence) ? interval.cadence :  85,
            cadence_window_low :   (!started)? 0 : !isEmpty(interval.cadenceWindowLow) ? interval.cadenceWindowLow :  30,
            cadence_window_high :  (!started)? 0 :  !isEmpty(interval.cadenceWindowHigh) ? interval.cadenceWindowHigh  : 30,
            next_power_window_low : (!started  || !nextInterval)? 0 : !isEmpty(nextInterval.powerWindowLow) ? nextInterval.powerWindowLow : 30,
            next_power_window_high : (!started || !nextInterval)? 0 : !isEmpty(nextInterval.powerWindowHigh) ? nextInterval.powerWindowHigh : 30,

        };
        if (started) {
            load = this.fixSelfPacedWindow(load, cp);
        }


        return  {
            workout_ts :(!started)? 0  :  this.props.timer.workout,
            cp : cp,
            watts:  device.power,
            power:  Math.round(device.power/cp*100),
            avgPower:  (!started)? 0 :Math.round((metrics.interval.power/cp)*100),
            avgWatts: (!started)? 0 : Math.round(metrics.interval.power),
            powerLoad: (!started)? 0 :Math.round(load.power),
            nextPowerLoad: (!started)? 0 :Math.round(load.nextPower),
            wattsLoad: (!started)? 0 :Math.round(load.power/100 *cp),
            wattsWindowLow: (!started)? 0 :Math.round(load.power_window_low),
            wattsWindowHigh: (!started)? 0 :Math.round(load.power_window_high),
            powerWindowLow: (!started)? 0 :Math.round(load.power_window_low/cp*100),
            powerWindowHigh: (!started)? 0 :Math.round(load.power_window_high/cp*100),
            nextPowerWindowLow: (!started)? 0 :Math.round(load.next_power_window_low/cp*100),
            nextPowerWindowHigh: (!started)? 0 :Math.round(load.next_power_window_high/cp*100),
            cadence: Math.round(device.cadence),
            avgCadence: (!started)? 0 :round(metrics.interval.cadence,1),
            cadenceLoad: (!started)? 0 :Math.round(load.cadence),
            cadenceWindowLow: (!started)? 0 : Math.round(load.cadence_window_low),
            cadenceWindowHigh: (!started)? 0 : Math.round(load.cadence_window_high),
            powerWindowOriginal: load.power_window_original,
            cadenceWindowOriginal: load.cadence_window_original,
            speed:  EstimateSpeed( device.power, weight, 0) ,
            //tbz: this.accuPerc(wInfo.clientMetrics.interval.cadence_accuracy, 'tbz'),
            //tiz: this.accuPerc(wInfo.clientMetrics.interval.cadence_accuracy, 'tiz'),
            //toz: this.accuPerc(wInfo.clientMetrics.interval.cadence_accuracy, 'toz'),
        };
    }
}

const mapStateToProps = state =>({
    status: state.workout.status,
    user: state.user,
    device: state.ble,
    timer: state.timer,
    metrics: state.metrics,
    interval : (state.workout.interval) ? state.workout.interval : null,
    nextInterval : (state.workout.nextInterval) ? state.workout.nextInterval : null,
    visualExperience : state.visualExperience

});

export default connect(mapStateToProps,{metricsChanged})(UserMetricsGauge)
