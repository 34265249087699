import  React ,{Component}from "react";
import { isEmpty} from "../utils/statistics";
import {connect} from "react-redux";


class Message extends Component {
    render() {

        let msg = this.props.message;
        let colorClass = "text-success";
        if(isEmpty(msg)){
            return '';
        }
        return (
            <div  className={`coachMessage ${colorClass} text-left`}>
                <img alt="message from coach" src={msg.avatar} height="50vh" /> {msg.message}
            </div>

        );
    }

}

const mapStateToProps = state =>({
    message: state.visualExperience.chat_message,
});
export default connect(mapStateToProps,{})(Message)