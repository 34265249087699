


export const PageView = () => {
    window.gtag('send', 'page_view', {
        page_location: window.location.href,
        page_path: window.location.pathname
    });
}
export const Event = (event_name, eventParameters) => {
    window.gtag('event',event_name, eventParameters);
};

export const TrackingProps = (properties) => {
    window.gtag('set', 'user_properties',properties)
};
