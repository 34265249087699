import {

    HUB_CONNECTION_IS_READY,
    RESET_SESSION,
    SESSIONS_LIST_READY,
    SESSION_READY,
    SESSIONS_LIST_PENDING,
    TP_LIST_READY,
    TP_LIST_PENDING,
    CONNECTIONS_LIST_READY,
    CONNECTIONS_LIST_PENDING,
    COACH_SESSION_READY,
    COACH_SYNC_TIMERS,
    SUBSCRIPTION_NOT_ACTIVE, PLANS_LIST_PENDING,PLANS_LIST_READY
} from '../actions'
import {uniqueId} from "../utils/statistics";

const initialState = {
    sessionId: null,
    locationId: Math.round(Math.random()*1000+9000),
    workout_url :null,
    zoom_id :null,
    zoom_password :null,
    video_id :null,
    video_start_sec :null,
    hubConnectionReady: false,
    coachSession: false,
    syncRidersTimers: false,
    coach_name: null,
    coach_id:null,
    external_id:null,
    sessions :[],
    workouts :[],
    introductions :[],
    training_peaks_wods :null,
    tp_workouts_status :null,
    training_plans :null,
    training_plans_status :null,
    connections :[],
    connections_status :null,
    sessions_status :null,
    coach_identifier:null,
};

const sessionReducer = (state = initialState, action) => {
    switch (action.type) {
        case SUBSCRIPTION_NOT_ACTIVE:
            return Object.assign({}, state, {
                workouts: [] //remove only solo and video workouts
            });

        case RESET_SESSION:
            return Object.assign({}, initialState, {
                workouts: state.workouts,
                sessions: state.sessions,
                introductions: state.introductions,
                training_peaks_wods: state.training_peaks_wods,
                training_plans: state.training_plans,
                sessions_status: null
            });
        case SESSIONS_LIST_READY:
            return Object.assign({}, state, {
                sessions: action.payload.sessions,
                workouts: action.payload.workouts,
                introductions: action.payload.introductions,
                sessions_status: SESSION_READY
            });
        case TP_LIST_PENDING:
            return Object.assign({}, state, {
                training_peaks_wods: action.payload.training_peaks_wods,
                tp_workouts_status: 'pending'
            });
        case TP_LIST_READY:
            return Object.assign({}, state, {
                training_peaks_wods: action.payload.training_peaks_wods,
                tp_workouts_status: null
            });

        case PLANS_LIST_PENDING:
            return Object.assign({}, state, {
                training_plans_status: 'pending'
            });
        case PLANS_LIST_READY:
            return Object.assign({}, state, {
                training_plans: action.payload.training_plans,
                training_plans_status: null
            });
        case SESSIONS_LIST_PENDING:
            return Object.assign({}, state, {
                sessions: [],
                workouts:[],
                introductions:[],
                training_peaks_wods:[],
                sessions_status: SESSIONS_LIST_PENDING
            });
        case CONNECTIONS_LIST_PENDING:
            return Object.assign({}, state, {
                connections:[],
                connections_status: CONNECTIONS_LIST_PENDING
            });
        case CONNECTIONS_LIST_READY:
            return Object.assign({}, state, {
                connections: action.payload.connections,
                connections_status: CONNECTIONS_LIST_READY
            });
        case SESSION_READY:
            return Object.assign({}, state, {
                sessionId: typeof  action.payload.sessionId==='undefined' ? uniqueId() :  action.payload.sessionId,
                workout_url: typeof  action.payload.workout_url==='undefined' ? null: action.payload.workout_url,
                zoom_id: typeof  action.payload.zoom_id==='undefined' ? null: action.payload.zoom_id,
                zoom_password: typeof  action.payload.zoom_password==='undefined' ? null: action.payload.zoom_password,
                auto_start_in_seconds: typeof  action.payload.auto_start_in_seconds==='undefined' ? null: action.payload.auto_start_in_seconds,
                auto_start_at: typeof  action.payload.auto_start_in_seconds==='undefined' ? null: action.payload.auto_start_at,
                video_id: typeof  action.payload.video_id==='undefined' ?  null :   action.payload.video_id,
                video_start_sec:  action.payload.video_start_sec ? parseInt(action.payload.video_start_sec) : null,
                coach_name:  typeof action.payload.coach_name ==='undefined'  ?null : action.payload.coach_name ,
                coach_id:   typeof action.payload.coach_id  ==='undefined'  ? null : action.payload.coach_id ,
                external_id:   typeof action.payload.external_id  ==='undefined'  ? null : action.payload.external_id ,
            });
        case COACH_SESSION_READY:
            return Object.assign({}, state, {
                coachSession:true,
                coach_identifier: action.payload.connect_time + '@pw.coach'
            });
        case COACH_SYNC_TIMERS:
            return Object.assign({}, state, {
                syncRidersTimers:true
            });
        case HUB_CONNECTION_IS_READY:
            console.log('updated hubConnectionReady');
            return Object.assign({}, state, {
                hubConnectionReady: true,
            });
        default:
            return state;
    }
};

export default sessionReducer;