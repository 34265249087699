import {accuracyStatus, intervalType} from "../actions";
import {EstimateSpeed} from './SpeedCalculator';

export const DEFAULT_POWER_WINDOW = 30;
export  const DEFAULT_CADENCE_WINDOW = 15;
export  const DEFAULT_CADENCE = 90;
export const MAX_RELATIVE_POWER = 330;
export const MAX_CADENCE = 250;

export const isEmpty =  (value)=> {
    if(typeof(value) === "undefined")
        return true;
    switch (value) {
        case "":
        case 0:
        case "0":
        case null:
        case false:

            return true;
        default:
            return false;
    }

};

export function uniqueId(){
    return  Math.random().toString(36).substring(2) + Date.now().toString(36);
}
export function round(number, digits) {
    if(typeof number==="undefined" || number===null){
        number = 0;
    }
    number = Number(number)
    if (isNaN(number)) {
        number = 0;
    }
    return parseFloat(number.toFixed(digits));
}

export function roundStr(number, digits) {
    if(typeof number==="undefined" || number===null){
        number = 0
    }
    number = Number(number)
    if (isNaN(number)) {
        number = 0;
    }
    return number.toFixed(digits);
}

export function getPower(interval, timeFromStart){
    if (!isEmpty(interval.rampUp)){
        let startPower = interval.rampUp[0];
        let endPower = interval.rampUp[1];
        let duration = interval.durationInSeconds;
        return  Math.round(startPower + timeFromStart * (endPower-startPower) / duration);
    }
    else {
        return interval.relativePower;
    }

}
export function isGameInterval(type) {
    switch (type) {
        case intervalType.tiz:
        case intervalType.taz:
        case intervalType.toz:
        case intervalType.tar :
        case intervalType.agr :
        case intervalType.kom :
        case intervalType.kod :
        case intervalType.spr :
            return true;
        default:
            return false;
    }
}

export function formatCurrency(price, currency){
    let number = round(price,2);
    return number.toLocaleString('en-US', { style: 'currency', currency: currency })
}


export function toSetInterval(workout, second){
    let accTime =0;
    let data = {
        setId: null,
        intervalIndex: null,
        intervalId: null,
        secondInInterval :  null,
        intervalType :  null
    };
    if ( second>workout.duration ){
        console.log('second value is too big !!!!' + (second) );
        return data;
    }
    if ( second<0 ){
        return {
            setId: 0,
            intervalIndex: 0,
            intervalId: 0,
            secondInInterval :  second,
            intervalType :  null
        };
    }

    for (let set of workout.sets) {
        for (let interval of set.intervals) {
            if (second - accTime < interval.durationInSeconds) {
                return {
                    setId: set.index,
                    intervalIndex: interval.index,
                    intervalId: interval.intervalId,
                    secondInInterval :  second - accTime,
                    intervalType :  interval.intervalType,
                    powerWindow : interval.powerWindowLow,
                    cadenceWindow : interval.cadenceWindowLow,
                }
            }
            accTime += interval.durationInSeconds;
        }
    }
    console.log('didnt find matching interval for ' + (second) );
    return null;
}

export function toRelative(value, min, max,scale=270){
    return Math.round((value-min)/(max-min)*scale);
}
export function getIntervalCriticalPower(user, interval){
    let cp = user.critical_power;
    // TODO: handle here more cp times !!!!!!!!!
    //let criticalPowerTime = interval.criticalPowerTime ? interval.criticalPowerTime : 20;

    return cp;
}


export function convertLoadToUserMetrics(user, interval, time){

    let minPower,maxPower ,power;
    let cp = getIntervalCriticalPower(user, interval);


    if(!isEmpty(interval.relativePower)){
        power = minPower = maxPower = parseFloat(interval.relativePower) *cp/100;
    }
    if(!isEmpty(interval.rampUp)) {
        power = (interval.rampUp[0]+interval.rampUp[1])/2 * cp/100;
        minPower = interval.rampUp[0] * cp/100;
        maxPower = (!isEmpty(interval.rampUp[1]) ?interval.rampUp[1] : MAX_RELATIVE_POWER)  * cp/100;
    }


    switch(interval.powerWindowLow){
        case -1:
            minPower = 0;
            maxPower = interval.relativePower * cp/100;
            break
        case -2:
            minPower =  interval.relativePower * cp/100;
            maxPower = MAX_RELATIVE_POWER* cp/100;
            break;
        default:
            minPower -= (interval.powerWindowLow  ? interval.powerWindowLow : DEFAULT_POWER_WINDOW);
            maxPower += (interval.powerWindowHigh ? interval.powerWindowHigh : DEFAULT_POWER_WINDOW);
            break;
    }


    let cadence = !isEmpty(interval.cadence) ?  interval.cadence :DEFAULT_CADENCE;
    let minCadence = interval.cadenceWindowLow ? cadence-interval.cadenceWindowLow : cadence- DEFAULT_CADENCE_WINDOW;
    let maxCadence = interval.cadenceWindowHigh ? cadence+interval.cadenceWindowHigh : cadence + DEFAULT_CADENCE_WINDOW;
    return {
        critical_power : cp,
        power : Math.round(power),
        power_window_low : Math.round(minPower) ,
        power_window_high : Math.round(maxPower),
        cadence : Math.round(cadence),
        cadence_window_low :  Math.round(minCadence),
        cadence_window_high : Math.round(maxCadence)
    }
}

export function standardDeviation(values){
    let avg = average(values);

    let squareDiffs = values.map(function(value){
        let diff = value - avg;
        let sqrDiff = diff * diff;
        return sqrDiff;
    });

    let avgSquareDiff = average(squareDiffs);

    let stdDev = Math.sqrt(avgSquareDiff);
    return stdDev;
}

export function average(data){
    let sum = data.reduce(function(sum, value){
        return sum + value;
    }, 0);
    return sum / data.length;

}

export const accuracyClass = (status) => {
    switch (status) {
        case accuracyStatus.high:
            return 'AboveTargetColor';
        case accuracyStatus.in_zone:
            return 'OnTargetColor';
        case accuracyStatus.low:
            return 'BelowTargetColor';
        default:
            return 'NextTargetColor';
    }
};

export const speedAndDistance = (weight, samples) => {
    let avgSpeed = 0; let maxSpeed = 0; let distance = 0;
    let speed = 0;  let count = 0;
    let lastTs = null;
    samples.map(sample => {
        if (lastTs === null) {
            lastTs = sample.ts;
        }
        speed = EstimateSpeed(sample.p, weight, 0);
        count++;
        if (avgSpeed===0) {
            avgSpeed = speed;
        } else {
            avgSpeed += (speed - avgSpeed) / count;
        }
        if (speed > maxSpeed) {
            maxSpeed = speed;
        }
        return
    });
    return [avgSpeed,maxSpeed, distance];
}


export const normalizedPower = (samples) => {
    let chunkAvg, normalizedPower, chunksCount, count;
    let startTs = null;
    chunkAvg = normalizedPower = count = chunksCount = 0;
    samples.map(sample => {
        if (startTs === null) {
            startTs = sample.ts;
        }
        if (sample.ts - startTs < 30000) {
            count++
            chunkAvg = chunkAvg + (sample.p - chunkAvg) / count;
        } else {
            normalizedPower += Math.pow(chunkAvg, 4);
            chunksCount++;
            count = chunkAvg = 0;
            startTs = null;
        }
    });
    if (chunkAvg > 0) {
        normalizedPower += Math.pow(chunkAvg, 4)  ;
        chunksCount++;
    }
    normalizedPower = chunksCount>0 ? Math.pow(normalizedPower/chunksCount , 0.25) : 0;
    return normalizedPower;
}