import React from "react";
import {Card, Col, Row, Container, ListGroup, ListGroupItem, } from "react-bootstrap";
import { Link } from 'react-router-dom';

import trainAlone from "../assets/train-alone.png";
import trainVCoach from "../assets/coached-workout.png";
import groupSession from "../assets/live-session.png";
import {connect, useDispatch} from "react-redux";
import {listSessions} from "../actions";

import HomeMessage from '../Components/HomeMessage';
import WorkoutNotesControl from '../Components/WorkoutNotes';
import PaidSessionLink from '../Components/PaidSessionLink';
import {useClearCache} from "react-clear-cache";
import { Event } from "../Components/Tracking";


const Home = (props) => {
    const { isLatestVersion, emptyCacheStorage } = useClearCache();

    let dispatch = useDispatch();


    if (!isLatestVersion) {
        return (
            <Container className="flex-grow-1 mt-5  h-100">

                <Row>
                    <Col className="text-center">
                        <div  className="homeMessageContainer">

                        You are not using the latest version of the App.
                        <a href="#" onClick={e => {
                            e.preventDefault();
                            emptyCacheStorage();
                        }}> Click here to Update the version </a>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
    let isCoach = typeof props.user.info.roles!== 'undefined' && props.user.info.roles.includes('Coach');
    if( props.workoutsList.length===0) {
        dispatch(listSessions());
    }
    Event("page_view", {page_title:"home page"})
    return (
        <Container className="fluid mt-5  h-100">
            <Row >
                <Col className="text-center">

                    <HomeMessage/>
                </Col>
            </Row>

             <Row >
                <Col>
                    <Card >
                        <Card.Img variant="top" src={trainAlone} />
                        <Card.Body>
                            <Card.Title>Solo Workout</Card.Title>
                        </Card.Body>
                        <ListGroup className="sessionsList list-group-flush">
                            {
                                props.workoutsList.map((item,key) => {
                                    let category = item.category !== null ? item.category : ''
                                    let intensity = item.intensity !== null ? item.intensity : ''
                                    return (
                                        <ListGroupItem key={key}>


                                            <Link
                                                onClick={() =>
                                                    Event("click", {target:"solo workout", workout_name:"item.name"})
                                                }
                                                className="text-nowrap" to={{ pathname: "private-workout",
                                                    state: {wid: item.id},
                                                }}>{item.name}
                                            </Link>

                                            <Row>
                                                <Col> <WorkoutNotesControl key={'private'+key} wid={item.wid} name={item.name} /></Col>
                                                <Col>{intensity}%</Col>
                                                <Col>{category}</Col>
                                            </Row>


                                        </ListGroupItem>
                                    )}
                                )
                            }
                        </ListGroup>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src={trainVCoach} />
                        <Card.Body>
                            <Card.Title>Coached Workout</Card.Title>
                        </Card.Body>
                        <ListGroup className="sessionsList list-group-flush">
                            {
                                props.workoutsList.map((item, key) => {
                                    let category = item.category !== null ? item.category : ''
                                    let intensity = item.intensity !== null ? item.intensity : ''
                                        if (item.virtual_coach) {
                                            return (
                                                <ListGroupItem key={key}>
                                                    <Link
                                                        onClick={() =>
                                                            Event("click", {target:"coached workout", workout_name:"item.name"})
                                                        }
                                                        className="text-nowrap" to={{
                                                        pathname: "virtual-workout",
                                                        state: {wid: item.id},
                                                    }}>{item.name}

                                                    </Link>
                                                    <Row>
                                                        <Col> <WorkoutNotesControl key={'private'+key} wid={item.wid} name={item.name} /></Col>
                                                        <Col>{intensity}%</Col>
                                                        <Col>{category}</Col>
                                                    </Row>
                                                </ListGroupItem>
                                            )
                                        }
                                    }
                                )
                            }
                        </ListGroup>

                    </Card>
                </Col>

                <Col>
                    <Card >
                        <Card.Img variant="top" src={groupSession}/>
                        <Card.Body>
                            <Card.Title>LIVE Classes</Card.Title>
                        </Card.Body>
                        <ListGroup className="list-group-flush">
                            {
                                props.sessionsList.map((item,key) => {
                                    return (
                                        <PaidSessionLink key={key} session={item}/>
                                    )}
                                )
                            }
                        </ListGroup>
                    </Card>
                </Col>
            </Row>


        </Container>
    );

};




const mapStateToProps = state => ({
    sessionsList: state.session.sessions,
    workoutsList: state.session.workouts,
    introductionsList: state.session.introductions,
    user: state.user,

});
export default connect(mapStateToProps)(Home)
