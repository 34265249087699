import {EXTERNAL_METRICS_BROADCAST, MAX_IDLE_SECONDS} from '../actions';

const initialState = {
    usersMetrics: {},
    users: new Map()
};

const generalMetricsReducer = (state = initialState, action) => {

    switch (action.type) {
        case EXTERNAL_METRICS_BROADCAST:
            let intervalId = action.payload.intervalId;
            let usersMetrics = state.usersMetrics;
            let usersMap = state.users;
            let userGrades = [];

            let lastMetrics = (typeof usersMetrics[intervalId] !== "undefined" ? usersMetrics[intervalId] : new Map());

            action.payload.metrics.map(uMetrics => {
                lastMetrics.set(uMetrics.email, uMetrics);
                let user = usersMap.get(uMetrics.email);
                let visible = typeof user!=='undefined' ?  user.visible : true;
                usersMap.set(uMetrics.email, {
                    lastActivity: uMetrics.timestamp,
                    visible: visible,
                    idle: false,
                    nickName:uMetrics.nickName
                });
                return null;
            });
            for (let elem of usersMap) {
                if (Date.now() - elem[1].lastActivity > MAX_IDLE_SECONDS * 1000) {
                    elem[1].idle = true;
                    usersMap.set(elem[0], elem[1]);
                }
            }
            for (let elem of lastMetrics) {
                userGrades.push({email: elem[0], grade: elem[1].grade});
            }

            userGrades = userGrades.sort((a, b) => {
                return (a.grade >= b.grade ? -1 : 1)
            });
            let uData = null;
            userGrades.map((elem, key) => {
                uData = lastMetrics.get(elem.email);
                let user = usersMap.get(elem.email);
                if (typeof user.visible !== 'undefined' && user.visible  && !user.idle) {
                    uData.rank = key + 1;
                    lastMetrics.set(elem.email, uData);
                }
                return elem;
            });

            usersMetrics[intervalId] = lastMetrics;
            return Object.assign({}, state, {
                usersMetrics: usersMetrics
            });
        default:
            return initialState;
    }
};
export default generalMetricsReducer;