import React, { Component } from "react";
import {connect} from "react-redux";
import {BleControls} from './StudioControls';
import {  connectToBle, disconnectBle} from "../actions";

import {connectionStatus, workoutStatus} from '../actions';



class WorkoutStatusMessage extends Component
{
    state = {
        count: 0
    };


    render() {

        let text, colorClass, bleText;
        let showBleButton = false;
        if (this.props.deviceConnectionStatus !== connectionStatus.connected) {
            bleText = 'Please connect your Power meter';
            text = '';
            colorClass = "text-danger";
            showBleButton = true;
        } else if (!this.props.interval) {
            text = 'Start the workout when you are ready';
            colorClass = "text-danger";
        } else if (this.props.status) {
            switch (this.props.status) {
                case workoutStatus.pending_start:
                    text = 'Pending auto start - Get ready.';
                    colorClass = "text-warning";
                    break;
                case workoutStatus.autoPaused:
                case workoutStatus.paused:
                    text = 'Paused'
                    break;
                case workoutStatus.started:
                    let visualExperience = this.props.visualExperience;
                    if (visualExperience.display_next_interval) {
                        let nextInterval =  this.props.nextInterval;
                        text = (visualExperience.display_next_interval && nextInterval && nextInterval.shortText) ? 'Get Ready: '+ nextInterval.shortText : '';
                        colorClass = 'text-warning';
                    }
                    break;
                default:
                    text = '&nbsp;';
            }
        }
        return (

            <div  className={`workoutStatusMessage ${colorClass} text-center text-nowrap`}>
                {text}
                {(showBleButton) ?
                    <BleControls
                        message={bleText}
                        status={this.props.deviceConnectionStatus}
                        connect={this.props.connectToBle}

                        disconnect={this.props.disconnectBle}
                        deviceName={this.props.deviceName}
                        connectionRemark={this.props.connectionRemark}
                    /> : ''}
            </div>

        )
    }
}


const mapStateToProps = state =>({
    deviceConnectionStatus : state.ble.connection_status,
    status: state.workout.status,
    interval: state.workout.interval,
    timer: state.timer,
    nextInterval: state.workout.nextInterval,
    visualExperience : state.visualExperience,
    connectionRemark: state.ble.remark,
    deviceName: state.ble.device_name,





});
export default connect(mapStateToProps,{disconnectBle, connectToBle})(WorkoutStatusMessage)