import React, { Component} from "react";
import {Button, Modal, Table} from "react-bootstrap";
import {roundStr} from "../utils/statistics";
import {connect} from "react-redux";
import {endWorkout, toggleWorkoutSummary, stopTimer} from "../actions";

class WorkoutSummary extends Component {


    render() {
        let show = this.props.visualExperience.display_workout_summary;

        return (
            <Modal show={show} onHide={()=> {  this.props.toggleWorkoutSummary(false);}} >
                <Modal.Header closeButton>
                    <Modal.Title>Summary</Modal.Title>
                </Modal.Header>
                <Modal.Body>Great Ride Today! here are your summary Stats
                    <Table striped borderless className="summaryTable" hover>
                        <body>
                            <tr>
                                <th className="text-right">Avg Watts:</th>
                                <td>{roundStr(this.props.metrics.avg_watts,0)} W</td>
                            </tr>
                            <tr>
                                <th className="text-right">Normalized Power:</th>
                                <td>{roundStr(this.props.normalizedPower,0)} W</td>
                            </tr>
                            <tr>
                                <th className="text-right">IF:</th>
                                <td>{roundStr((this.props.normalizedPower/this.props.critical_power*100),2)}%</td>
                            </tr>
                            <tr>
                                <th  className="text-right">Kj:</th>
                                <td>{roundStr(this.props.metrics.kj)}KJ</td>
                            </tr>
                            <tr>
                                <th  className="text-right">Distance:</th>
                                <td>{roundStr(this.props.metrics.distance,2)} Km</td>
                            </tr>
                            <tr>
                                <th  className="text-right">Avg Speed:</th>
                                <td>{roundStr(this.props.metrics.avg_speed,2)} Kph</td>
                            </tr>



                        </body>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger"  onClick={()=> {  this.props.toggleWorkoutSummary(false);}} >
                        Keep Riding
                    </Button>
                    <Button variant="success" onClick={()=> {this.props.endWorkout(); this.props.toggleWorkoutSummary(false); this.props.stopTimer()}}>
                        Save & Exit
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = state => ({
    status: state.workout.status,
    critical_power: state.user.critical_power,
    metrics: state.metrics.workout,
    normalizedPower: state.metrics.normalized_power,
    timer: state.timer.workout,
    visualExperience : state.visualExperience
});
export default connect(mapStateToProps, {endWorkout, toggleWorkoutSummary, stopTimer})(WorkoutSummary)