import React, {Component} from "react";
import {connect} from "react-redux";
import {roundStr} from "../utils/statistics";
import {Table} from "react-bootstrap";

class Dashboard extends Component {
    constructor(props)
    {
        super(props);
    }
    isVisible(){
        return this.props.visualExperience.display_rider_metrics;
    }

    render() {

        if (!this.isVisible()) {
            return '';
        }
        return <Table  striped hover table variant="dark">
            <tr>
                <td className="largeDigits"> {roundStr(this.props.metrics.distance,2)}</td>
                <td className="largeDigits">{roundStr(this.props.metrics.speed,1)}</td>
                <td className="largeDigits">{roundStr(this.props.metrics.avg_speed,1)}</td>
                <td className="largeDigits">{roundStr(this.props.metrics.kj)}</td>

            </tr>
            <tr>
                <td className="smallLabel">Km</td>
                <td className="smallLabel">Kph</td>
                <td className="smallLabel">A.Kph</td>
                <td className="smallLabel">KJ</td>
            </tr>
        </Table>;
    }

}

const mapStateToProps = state => ({
    status: state.workout.status,
    metrics: state.metrics.workout,
    timer: state.timer.workout,
    visualExperience : state.visualExperience
});
export default connect(mapStateToProps, {})(Dashboard)