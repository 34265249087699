import React, {Component} from "react";
import {connect} from "react-redux";

import {Card, Col, Collapse, ListGroup, ListGroupItem, Row} from "react-bootstrap";
import YouTube from "react-youtube";
import WorkoutNotesControl from "./WorkoutNotes";
import {Link} from "react-router-dom";
import {Event} from "./Tracking";
import Loading from "./Loading";
import {listTrainingPlans} from '../actions'
import {faChalkboardTeacher, faBiking} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class TrainingPlans extends Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
        this.props.listTrainingPlans();
    }


    render() {
        if (this.props.training_plans===null) {
            return (<Loading/>)
        }
        const opts = {
            height:"100%",
            playerVars: {
                autoplay: 0,
                class:'video',
                controls:0,
                autohide:1,
                modestbranding:1,
                fs:0,
                showinfo:0,
                rel:0
            },
        };

        return (
            <div className="introductionContainer shadowContainer">
                {
                    this.props.training_plans.map((plan, key) => {
                        return (
                            <div>
                                
                                <Row>
                                    <Col className="text-left">
                                        <Card>
                                            <Card.Body>
                                                <Card.Title>{plan.training_plan_name}</Card.Title>
                                            </Card.Body>
                                            <ListGroup className="introductionList list-group-flush">
                                                {
                                                    plan.workouts.map((workout, key) => {
                                                        let defaultPath = workout.has_video ?  "virtual-workout": "private-workout";
                                                        let intensity = workout.intensity !== null ? workout.intensity : ''
                                                            return (
                                                                <ListGroupItem key={key}>


                                                                    <Link
                                                                        onClick={() =>
                                                                            Event("click", {
                                                                                page: "home",
                                                                                component: "TrainingPlans",
                                                                                target: "training plan workout",
                                                                                workout_name: workout.workout_name
                                                                            })
                                                                        }
                                                                        className="text-nowrap" to={{
                                                                        pathname: defaultPath,
                                                                        state: {wid: workout.id},
                                                                    }}>{workout.workout_name}
                                                                    </Link>
                                                                    <Row>
                                                                        <Col>
                                                                            <WorkoutNotesControl key={'plan_workout' + workout.id}
                                                                                                 wid={workout.cpx_workout_id}
                                                                                                 name={workout.workout_name}/>
                                                                        </Col>
                                                                        <Col>{intensity}%</Col>
                                                                        <Col>
                                                                            <Link
                                                                                title = "Solo Workout"
                                                                                onClick={() =>
                                                                                    Event("click", {
                                                                                        page: "home",
                                                                                        component: "TrainingPlans",
                                                                                        target: "training plan workout",
                                                                                        workout_name: workout.workout_name
                                                                                    })
                                                                                }
                                                                                className="text-nowrap" to={{
                                                                                pathname: "private-workout",
                                                                                state: {wid: workout.id},
                                                                            }}>
                                                                                <FontAwesomeIcon  icon={faBiking}/>
                                                                            </Link>
                                                                            &nbsp;
                                                                            {(workout.has_video) ?
                                                                            <Link
                                                                                title = "Coached Workout"
                                                                                onClick={() =>
                                                                                    Event("click", {
                                                                                        page: "home",
                                                                                        component: "TrainingPlans",
                                                                                        target: "training plan workout",
                                                                                        workout_name: workout.workout_name
                                                                                    })
                                                                                }
                                                                                className="text-nowrap" to={{
                                                                                pathname: "virtual-workout",
                                                                                state: {wid: workout.id},
                                                                            }}>
                                                                                <FontAwesomeIcon  icon={faChalkboardTeacher}/>
                                                                            </Link> : '' }
                                                                        </Col>
                                                                    </Row>

                                                                </ListGroupItem>
                                                            )
                                                        }
                                                    )
                                                }
                                            </ListGroup>
                                        </Card>
                                    </Col>
                                    <Col className="text-center">
                                        {plan.description} <br/> <br/>
                                        <a href={plan.ebook_url} target="_blank">View E-Book</a>
                                        { (plan.video_id!==null) ?
                                        <div className="video">
                                            <YouTube videoId={plan.video_id} opts={opts}/>
                                        </div>
                                        : ''}
                                    </Col>
                                </Row>

                            </div>
                        )
                    })
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    training_plans: state.session.training_plans,

});
export default connect(mapStateToProps,{listTrainingPlans})(TrainingPlans)
