import {connectionStatus} from "./ble";
import  {dispatchConnectionChange,powerChanged,cadenceChanged} from './ble';
import {Event} from "../Components/Tracking";


let powerTimer = null;
let cadenceTimer = null;

export function connectMockBle() {
    return function (dispatch) {
        dispatchConnectionChange(dispatch,connectionStatus.not_connected,'not connected');
        setTimeout(()=> {
            dispatchConnectionChange(dispatch,connectionStatus.connectingServer,'found server');
        }, 500);
        setTimeout(()=> {
            dispatchConnectionChange(dispatch,connectionStatus.connectingService,'found service');
        }, 1000);

        setTimeout(()=> {
            dispatchConnectionChange(dispatch,connectionStatus.connected,'found characteristic');
        }, 1500);
        setTimeout(()=> {
            Event("use_simulator",{});
            Event("ble_connection",{status:'connected' ,trainer_type:'mock_ble'});
            dispatchConnectionChange(dispatch,connectionStatus.connected,'connected', 'MOCK BLE');
        }, 2000);
    }
}



export  function mockPower(value) {
    return function (dispatch) {
        powerChanged(dispatch,value);
    };

}


export  function mockCadence(value) {
    return function (dispatch) {
        cadenceChanged(dispatch,value);
    };
}


export const stopMockNotifications = () => {
    clearInterval(powerTimer);
    clearInterval(cadenceTimer);
    return { type: 'TIMER_STOP' };
}