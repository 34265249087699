import React, {Component} from "react";
import {connect, useDispatch} from "react-redux";
import {Button, Card, ListGroup, ListGroupItem, Modal, Table} from 'react-bootstrap';
import Loading from './Loading';
import { loadSubscriptions, subscriptionNotActive,loadingState, ProductType} from '../actions'
import {Event} from "./Tracking";
import {Link} from "react-router-dom";

class UserSubscription extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show: true
        };
    }

    close = () => this.setShow(false);
    keepOpen = () => this.setShow(true);

    setShow(show)
    {
        this.setState({show : show})
    }

    componentDidMount() {
        this.props.loadSubscriptions();
    }

    render() {
        let subscriptions_state = this.props.subscriptions_state;
        if (subscriptions_state !== loadingState.ready) {
            return <Loading/>
        }
        let subscriptions = this.props.subscriptions;
        let has_active_membership = this.props.has_active_membership;
        let allow_purchase = this.props.allow_purchase;
        let is_trial_account = this.props.is_trial_account;

        if (allow_purchase && !has_active_membership) {
            this.props.subscriptionNotActive();
            return this.buySubscriptionPopup();
        }

        return (
            <div className="subscriptionsContainer">

                { (!allow_purchase || is_trial_account ?
                    <h4 className="text-warning">Trial Account</h4> :
                    <h4 className="text-warning">Membership</h4> )
                }

                <Card >
                    <ListGroup className="subscriptionsList list-group-flush">
                        {subscriptions.map((subscription, key) => {
                            return (
                                <ListGroupItem key={key}>
                                    <div className="subscription_name">{subscription.name}</div>
                                    <div className="subscription_type">{subscription.type}</div>
                                    <div className="expiration">{subscription.period_text} {subscriptions.is_trial?'Trial':''}</div>
                                </ListGroupItem>
                            )
                        })}
                    </ListGroup>
                </Card>


                { (allow_purchase && (is_trial_account || !has_active_membership) )?
                    <Link
                        onClick={() =>
                            Event("click", {target:"buy now"})
                        }
                        className="text-nowrap" to={{ pathname: "checkout/contract"}}>
                        <Button variant="success">Subcribe for $4.95USD/month</Button>
                    </Link>
                    :''}

                <hr/>
                { allow_purchase  ?
                <Link
                    onClick={() =>
                        Event("click", {target:"buy now"})
                    }
                    className="text-nowrap" to={{ pathname: "checkout/service"}}>
                    <Button variant="success">Buy Live Classes Package</Button>
                </Link>
                    :''}
            </div>
        )
    }


    buySubscriptionPopup() {
        return (
        <>
            <div className="text-danger">Your do not have an active PowerWatts Online Membership!</div>
            <div className="text-center">
                <Link
                    onClick={() =>
                        Event("click", {target:"buy now"})
                    }
                    className="text-nowrap" to={{ pathname: "checkout/contract" }}>
                    <Button variant="success">Buy Now</Button>
                </Link>
            </div>
            <div className="text-center">
                <Link
                    onClick={() =>
                        Event("click", {target:"buy now"})
                    }
                    className="text-nowrap" to={{ pathname: "checkout/service" }}>
                    <Button variant="success">Buy Live Classes Package</Button>
                </Link>
           </div>


            <Modal show={this.state.show} onHide={this.close} dialogClassName="subscriptionExpiredModal">
                <Modal.Header closeButton>
                    <Modal.Title>Subscription Expired</Modal.Title>
                </Modal.Header>
                <Modal.Body scrollable={true} size='xl'>
                    <div>
                        You do not have an active PowerWatts Online Membership!<br/>
                        The cost is $4.95USD/month plus applicable taxes<br/>
                        You can cancel your membership at any time without penalty
                    </div>

                </Modal.Body>
                <Modal.Footer>

                    <Button variant="warning" onClick={() => {
                        Event("click", {target: "buy learn more"});
                        let url ='https://www.powerwatts.com/';
                        let  win = window.open(url, '_blank');
                        win.focus();
                    }}>Lean More</Button>



                    <Link
                        onClick={() =>
                            Event("click", {target:"buy now"})
                        }
                        className="text-nowrap" to={{ pathname: "checkout/contract" }}>
                        <Button variant="success">Buy Now</Button>
                    </Link>



                </Modal.Footer>
            </Modal>
        </>
        )
    }


}

const mapStateToProps = state => ({
    subscriptions: state.subscription.subscriptions,
    booking_list: state.subscription.booking_list,
    has_active_membership: state.subscription.has_active_membership,
    allow_purchase: state.subscription.allow_purchase,
    is_trial_account: state.subscription.is_trial_account,
    subscriptions_state: state.subscription.subscriptions_state,
    bookings_state: state.subscription.bookings_state,
    last_error: state.subscription.last_error
});
export default connect(mapStateToProps, {loadSubscriptions,subscriptionNotActive})(UserSubscription)