const $ = window.$;

let proxyhub =  null;



export function SignalR_notifyMetricChange(data){
    try {
        if(proxyhub!==null) {
            proxyhub.server.sendCPXDataPointna(data);
        }
    } catch (error) {
        console.error(error);
    }
}

export function SignalR_initConnection(accessToken,
                               email, notifyConnectionReady,
                                       broadcastMetricsCB,
                               DeliverMessageObjCB,
                               relayUserProfileDTCB,
                               relayMasterControlDTOCB,
                               setMasterControlDTOCB,
                               setVisualExperienceCB,
                               processUserProfileCB,
                               broadcastCPXDataPointCB) {
    try {
        $.ajaxSetup({
            beforeSend: function(xhr) {
                xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
            }
        });
        // Start the connection.
        $.connection.hub.url = "https://cpx-pw.azurewebsites.net/signalr";
        $.connection.hub.qs = {
            email: email
        };

        // Declare a proxy to reference the hub.
        proxyhub = $.connection.realTimeDataHub;

        proxyhub.client.broadcastCPXDataPoint = function (message) {
            //console.log(message);
            broadcastCPXDataPointCB(message)

        };

        proxyhub.client.DeliverMessageObj = function (msg) {
            console.log('DeliverMessageObj');
            console.log(msg);
            if( msg.userEmail && msg.userEmail===email) {
                DeliverMessageObjCB(msg)
            } else{
                console.log('ignore message to '  + msg.userEmail );
            }
        };

        proxyhub.client.relayUserProfileDTO = function (u) {
            console.log(u);
            relayUserProfileDTCB(u)
        };

        proxyhub.client.relayMasterControlDTO = function (e, u) {
            console.log("relayMasterControlDTO");
            console.log(u);
            relayMasterControlDTOCB(e,u)
        };

        proxyhub.client.setMasterControlDTO = function (email, ve) {
            console.log("setMasterControlDTO");
            console.log(email);
            console.log(ve);
            setMasterControlDTOCB(email, ve)
        };
        proxyhub.client.setVisualExperience = function (email, ve) {
            console.log('setVisualExperience');
            console.log(email);
            console.log(ve);
            setVisualExperienceCB(email, ve)
        };

        proxyhub.client.relayVisualExperienceStringTest = function (u) {
            console.log("relayVisualExperienceStringTest");
            console.log(u);
        };

        proxyhub.client.processUserProfile = function (u) {

            processUserProfileCB(u);
        };

        $.connection.hub.disconnected(function () {
            alert ("Reconned")
        });


        $.connection.hub.start({jsonp: true, transport:['webSockets','longPolling']})
            .done(() => {
                console.log('subscribeToBroadcasts');
                return proxyhub.server.subscribeToBroadcasts()
            }).fail((err)=>{
                console.log(err);
            }).then(() => {
                console.log('notifyConnectionReady');
                return notifyConnectionReady()
            }).then(() => {
                console.log('broadcastMetricsCB');
                return broadcastMetricsCB()
            }).then(() => {
                console.log('sendUserProfile');
                // Sending a data packet w/ a rtId sets the group in order to listen for commands to this group
                return  proxyhub.server.sendUserProfile(email)
            })




    } catch (error) {
        alert(error);
    }
}

