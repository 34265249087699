import {connect, useDispatch, useSelector} from "react-redux";
import React,{}  from "react";
import {Col, Container,  Row} from "react-bootstrap";
import Scheduler from "../Components/Scheduler";
import ClientMetrics from "../Components/ClientMetrics";
import UserMetricsGauge from "../Components/UserMetricsGauge";
import StudioControls from "../Components/StudioControls";
import StepIndicator from "../Components/StepIndicator";
import VirtualRider from "../Components/VirtualRider";
import WorkoutStatusMessage from "../Components/WorkoutStatusMessage";
import Message from "../Components/Message";
import Timer from "../Components/Timer";
import CoachVideo from "../Components/CoachVideo";
import ZoomVideo from "../Components/ZoomVideo";
import WorkoutImage from "../Components/WorkoutImage";
import Dashboard from "../Components/Dashboard";
import WorkoutSummary from "../Components/WorkoutSummary";
import BlockLeavePage from "../Components/BlockLeavePage";
//import LeaderBoard from "../Components/LeaderBoard";
import { useAuth0 } from "../react-auth0-spa";
import Loading from '../Components/Loading';
import {startSession,resetSession} from "../actions";
import AccuracyGauge from "../Components/AccuracyGauge";
import GameGauge from "../Components/GameGauge";
import {Event} from "../Components/Tracking";

const dummy = () => '';
const extendedMetrics = () =>  <ClientMetrics mode='short'/>;
const shortMetrics = () =>  <ClientMetrics mode='short'/>;
const zoom = () =>  <div className="zoomVideoContainer" ><ZoomVideo/></div>;
const video = () =><div    className="videoContainer" ><CoachVideo/></div>;




export const TrainAloneStudio = (props) => {
    let wid=1;
    if(typeof  props.location.state!=="undefined") {
        wid = props.location.state.wid;
    }
    return <CommonLayout component={dummy} metricsClass="metricsContainerLarge"  metrics={extendedMetrics} exteranalId={wid} />
};


export const TrainWithVirtualCoachStudio = (props) => {
    let wid=1;
    if(typeof  props.location.state!=="undefined") {
        wid = props.location.state.wid;
    }
    return <CommonLayout component={video} metricsClass="metricsContainer" metrics={shortMetrics} exteranalId={wid}/>
};

export const TrainWithGroupStudio = (props) => {

    let externalId=1;
    if(typeof  props.location.state!=="undefined") {
        externalId = props.location.state.externalId;
    }
    return <CommonLayout component={zoom}  metricsClass="metricsContainer" metrics={shortMetrics} exteranalId={externalId} />
};


const CommonLayout =(props) => {
   /* const viewPercentage = 0.23;
    const [dimensions, setDimensions] = React.useState({
        height:  window.innerWidth*viewPercentage,
        width: window.innerWidth*viewPercentage
    });

    useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerWidth * viewPercentage,
                width: window.innerWidth * viewPercentage
            });
        }
        window.addEventListener('resize', handleResize)
    });
    */
    Event("page_view", {page_title:"Workout Page"})
    let dispatch = useDispatch();
    const { loading, user } = useAuth0();

    if (loading || !user) {
        return <Loading />;
    }
    dispatch(resetSession());
    dispatch(startSession(props.exteranalId));
    let dim = window.innerWidth*0.23;
    return (
        <Container   fluid>
            <BlockLeavePage/>
            <StudioControls />
            <VirtualRider/>
            <Scheduler/>
            <WorkoutSummary/>

            <Row class="headerRow"  style={{backgroundColor:'#212220' , marginBottom:'10px'}} >

                <Col><Timer className="workout-timer text-left"  timer_type="workout" direction="up"/> </Col>
                <StepIndicator type="current"/>
                <Col ><Timer timer_type="interval" direction="down"  className="interval-timer text-center"/></Col>
                <StepIndicator type="next"/>
                <Col ><Timer className="workout-timer text-right"  timer_type="workout" direction="down"/> </Col>
            </Row>



            <Row className="justify-content-md-center">
                <div className="shadowContainer"> {props.component()} </div>
                <div className="gaugeContainer text-center shadowContainer">
                    <UserMetricsGauge width={dim}  height={dim}/>
                    <Dashboard/>
                    <GameGauge width={dim} />
                    <WorkoutStatusMessage/>
                    <AccuracyGauge/>
                    <Message/>

                </div>
                <div className="shadowContainer">
                    <div  className={props.metricsClass} >

                        {props.metrics()}
                    </div>
                </div>
            </Row>

            <Row  >
                <Col style={{marginTop:'20px'}}><WorkoutImage/></Col>
            </Row>



        </Container>
    );
};
const mapStateToProps = state =>({

});
 connect(mapStateToProps,{})(CommonLayout)

