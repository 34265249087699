import React, {Component} from "react";

import {roundStr} from "../utils/statistics";
import {toPath,toRotate, toArcPath} from "../utils/SvgHelpers";
import {toRelative} from "../utils/statistics";



const  POWER_START_ANGLE = 180;

 const isEmpty =  (value)=> {
     if(typeof(value) == "undefined")
         return true;
    switch (value) {
        case "":
        case 0:
        case "0":
        case null:
        case false:

            return true;
        default:
            return false;
    }

};



 class  MetricsGauge extends Component {
     static defaultProps = {
         colors: {
             primaryColor : '#ffffff',
             yellow:'#FFFF15',
             blue: '#3595F0',
             green: '#2F8F00',

             red: '#C01818',
             grey: '#888'
         },
         primaryColor: '#ffffff',
         gradient: [],
         width: 450,
         height: 450,
         margin:10,
         powerMin: 0,
         powerMax: 250,
         cadenceMin: 0,
         cadenceMax: 270,


     };

     constructor(props) {
         super(props);
         this.resizeGauge = this.resizeGauge.bind(this);
         window.addEventListener('resize', this.resizeGauge, false);
         this.state = {
             lastBeep: null,
             lastMetricsBroadcast: null
         };
         this.metrics = null;
     }



     getPower(interval){
         if(isEmpty(interval)){
             return 0;
         }
         if (!isEmpty(interval.rampUp)){
             let startPower = interval.rampUp[0];
             let endPower = interval.rampUp[1];
             let duration = interval.durationInSeconds;
             let timeFromStart = this.props.timer.interval;
             return  Math.round(startPower + timeFromStart * (endPower-startPower) / duration);
         }
         else {
             return interval.relativePower;
         }

    }




     fixSelfPacedWindow(load, cp) {
         let maxPower = this.props.powerMax;
         let maxCadence = this.props.cadenceMax;
         load.power_window_original = null;
         load.cadence_window_original = null
         switch (load.power_window_low) {
             case -1:  // max limit
                 load.power_window_original = -1;
                 load.power_window_low = load.power / 100 * cp;
                 load.power_window_high = 0;
                 break;
             case -2: //min limit
                 load.power_window_original = -2;
                 load.power_window_low = 0
                 load.power_window_high = (maxPower - load.power) / 100 * cp;
                 break;
             default:
                 break;
         }
         switch (load.next_power_window_low) {
             case -1:  // max limit
                 load.next_power_window_low = load.nextPower / 100 * cp;
                 load.next_power_window_high = 0;
                 break;
             case -2: //min limit
                 load.next_power_window_low = 0
                 load.next_power_window_high = (maxPower - load.nextPower) / 100 * cp;
                 break;
             default:
                 break;
         }
         if(load.cadence_window_low===-2) {
             load.cadence_window_original = -2;
             load.cadence_window_low = 0
             load.cadence_window_high = (maxCadence - load.cadence);
         }
         if(load.cadence_window_high===-1) {
             load.cadence_window_original = -1;
             load.cadence_window_low = load.cadence;
             load.cadence_window_high =  0;
         }
         return load;
     }

     resizeGauge() {
/*
         const width = this.props.width;
         const height = this.props.height;

         if (window.innerWidth > width) {
             if (!this.state|| this.state.width < width) {
                 this.setState({ width, height });
             }

         } else {
             this.setState({
                 width: window.innerWidth,
                 height: window.innerWidth * .5
             });
         }

 */
     }

     getStyles(metrics) {
         /*metrics.power = 120;
         metrics.avgPower = 60;
         metrics.powerLoad =90;
         metrics.powerWindow = 20;


         metrics.cadence = 90;
         metrics.avgCadence = 75;
         metrics.cadenceLoad = 90;
         metrics.cadenceWindow = 10;

          */

         let width = parseInt(this.props.width);
         let height = parseInt(this.props.height);
         let margin = parseInt(this.props.margin);
         let powerMin = parseInt(this.props.powerMin);
         let powerMax = parseInt(this.props.powerMax);
         let powerRadius = Math.round((width-2*margin)/2  )

         let powerCenterX = Math.round(width / 2)
         let powerCenterY =Math.round(powerRadius+margin)
         let innerPowerRadius = powerRadius*0.70;
         let needleWidth = (powerRadius-innerPowerRadius) * 0.5;
         let rectDim = Math.round(this.props.width/20);

         let smallGaugeRadius = Math.round(powerRadius /2.5)
         let smallGaugeInnerRadius = smallGaugeRadius*0.6
         let smallNeedleWidth = (smallGaugeRadius-smallGaugeInnerRadius) * 0.5;
         let smallRectDim = Math.round(this.props.width/30);

         let cadenceMin = parseInt(this.props.cadenceMin);
         let cadenceMax = parseInt(this.props.cadenceMax);
         let cadenceCenterX = margin + smallGaugeRadius;
         let cadenceCenterY = height - margin - smallGaugeRadius;

         let accuracyCenterX = width - 1.5*margin - smallGaugeRadius;
         let accuracyCenterY =  height - smallGaugeRadius;




         let power =   toRelative(metrics.power   ,powerMin,powerMax);
         let avgPower = toRelative(metrics.avgPower,powerMin,powerMax);
         let powerLoad = toRelative(metrics.powerLoad,powerMin,powerMax);
         let nextPowerLoad = toRelative(metrics.nextPowerLoad,powerMin,powerMax);
         let powerWindowLow =  toRelative(metrics.powerWindowLow,powerMin,powerMax);
         let powerWindowHigh =  toRelative(metrics.powerWindowHigh,powerMin,powerMax);
         let nextPowerWindowLow =  toRelative(metrics.nextPowerWindowLow,powerMin,powerMax);
         let nextPowerWindowHigh =  toRelative(metrics.nextPowerWindowHigh,powerMin,powerMax);

         let powerColor = this.getColor(metrics.power,metrics.powerLoad-metrics.powerWindowLow , metrics.powerLoad+metrics.powerWindowHigh);

         let powerAvgColor = this.getColor(metrics.avgPower,metrics.powerLoad-metrics.powerWindowLow , metrics.powerLoad+metrics.powerWindowHigh);
         let cadence =   toRelative(metrics.cadence,cadenceMin,cadenceMax);
         let avgCadence = toRelative(metrics.avgCadence,cadenceMin,cadenceMax);
         let cadenceLoad = toRelative(metrics.cadenceLoad,cadenceMin,cadenceMax);
         let cadenceWindowLow = toRelative(metrics.cadenceWindowLow,cadenceMin,cadenceMax);
         let cadenceWindowHigh = toRelative(metrics.cadenceWindowHigh,cadenceMin,cadenceMax);
         let cadenceColor = this.getColor(metrics.cadence,metrics.cadenceLoad-metrics.cadenceWindowLow , metrics.cadenceLoad+metrics.cadenceWindowHigh);
         let cadenceAvgColor = this.getColor(metrics.avgCadence,metrics.cadenceLoad-metrics.cadenceWindowLow , metrics.cadenceLoad+metrics.cadenceWindowHigh);

         let tbz =   toRelative(metrics.tbz,0,100);
         let toz =   toRelative(100-metrics.toz,0,100);



         return {
             colors: this.props.colors,
             powerColor: powerColor,
             avgPowerColor: powerAvgColor,
             cadenceColor: cadenceColor,
             avgCadenceColor: cadenceAvgColor,
             width:width,
             height:height,
             margin: margin,
             fonts: {
                 tiny: Math.round(0.03 * height) + 'px',
                 small: Math.round(0.05 * height) + 'px',
                 smallMedium: Math.round(0.06 * height) + 'px',
                 medium: Math.round(0.07 * height) + 'px',
                 large: Math.round(0.10*height) + 'px'
             },
             powerGauge: {
                 centerX: powerCenterX,
                 centerY: powerCenterY,
                 radios: powerRadius,
                 rectangleDim: rectDim,
                 innerRadius: innerPowerRadius,
                 needleRadius: innerPowerRadius + 1.3*needleWidth,
                 arcAngle : power,
                 needleWidth:needleWidth,
                 startAngle:  POWER_START_ANGLE
             },
             cadenceGauge: {
                 centerX: cadenceCenterX,
                 centerY: cadenceCenterY,
                 radios: smallGaugeRadius,
                 rectangleDim: smallRectDim,
                 needleRadius: smallGaugeInnerRadius + +1.3*smallNeedleWidth,
                 innerRadius: smallGaugeInnerRadius,
                 needleWidth:smallNeedleWidth,
                 arcAngle : cadence
             },

             accuracyGauge: {
                 centerX: accuracyCenterX,
                 centerY: accuracyCenterY,
                 radios: smallGaugeRadius,
                 innerRadius: smallGaugeInnerRadius,
                 tbzRotation : tbz,
                 tozRotation : toz
             },


             powerAvg:{
                 cx:powerCenterX-(innerPowerRadius+rectDim/2)*Math.cos(avgPower/180*Math.PI),
                 cy:powerCenterY-(innerPowerRadius+rectDim/2)*Math.sin(avgPower/180*Math.PI),
                 r:avgPower-45
             },
             cadenceAvg:{
                 cx:cadenceCenterX-(smallGaugeInnerRadius+smallRectDim/2)*Math.cos(avgCadence/180*Math.PI),
                 cy:cadenceCenterY-(smallGaugeInnerRadius+smallRectDim/2)*Math.sin(avgCadence/180*Math.PI),
                 r:avgCadence-45
             },
             powerTarget :{
                 load:{
                     mx: Math.round(powerCenterX-(innerPowerRadius)*Math.cos(powerLoad/180*Math.PI)),
                     my: Math.round(powerCenterY-(innerPowerRadius)*Math.sin(powerLoad/180*Math.PI)),
                     lx: Math.round(powerCenterX-(powerRadius)*Math.cos(powerLoad/180*Math.PI)),
                     ly: Math.round(powerCenterY-(powerRadius)*Math.sin(powerLoad/180*Math.PI)),
                 },
                 min:{
                     mx: Math.round(powerCenterX-(innerPowerRadius+needleWidth-7)*Math.cos((powerLoad-powerWindowLow)/180*Math.PI)),
                     my: Math.round(powerCenterY-(innerPowerRadius+needleWidth-7)*Math.sin((powerLoad-powerWindowLow)/180*Math.PI)),
                     lx: Math.round(powerCenterX-(innerPowerRadius+needleWidth+1)*Math.cos((powerLoad-powerWindowLow)/180*Math.PI)),
                     ly: Math.round(powerCenterY-(innerPowerRadius+needleWidth+1)*Math.sin((powerLoad-powerWindowLow)/180*Math.PI)),
                 } ,
                 max:{
                     mx: Math.round(powerCenterX-(innerPowerRadius+needleWidth-7)*Math.cos((powerLoad+powerWindowHigh)/180*Math.PI)),
                     my: Math.round(powerCenterY-(innerPowerRadius+needleWidth-7)*Math.sin((powerLoad+powerWindowHigh)/180*Math.PI)),
                     lx: Math.round(powerCenterX-(innerPowerRadius+needleWidth+1)*Math.cos((powerLoad+powerWindowHigh)/180*Math.PI)),
                     ly: Math.round(powerCenterY-(innerPowerRadius+needleWidth+1)*Math.sin((powerLoad+powerWindowHigh)/180*Math.PI)),
                 },
                 arc: {
                     startAngle : POWER_START_ANGLE+powerLoad-powerWindowLow,
                     arcAngle : powerWindowHigh +powerWindowLow,
                     radius: innerPowerRadius+needleWidth
                 }
             },
             nextPowerTarget :{
                 min:{
                     mx: Math.round(powerCenterX-(innerPowerRadius+needleWidth+3)*Math.cos((nextPowerLoad-nextPowerWindowLow)/180*Math.PI)),
                     my: Math.round(powerCenterY-(innerPowerRadius+needleWidth+3)*Math.sin((nextPowerLoad-nextPowerWindowLow)/180*Math.PI)),
                     lx: Math.round(powerCenterX-(innerPowerRadius+needleWidth+14)*Math.cos((nextPowerLoad-nextPowerWindowLow)/180*Math.PI)),
                     ly: Math.round(powerCenterY-(innerPowerRadius+needleWidth+14)*Math.sin((nextPowerLoad-nextPowerWindowLow)/180*Math.PI)),
                 } ,
                 max:{
                     mx: Math.round(powerCenterX-(innerPowerRadius+needleWidth+3)*Math.cos((nextPowerLoad+nextPowerWindowHigh)/180*Math.PI)),
                     my: Math.round(powerCenterY-(innerPowerRadius+needleWidth+3)*Math.sin((nextPowerLoad+nextPowerWindowHigh)/180*Math.PI)),
                     lx: Math.round(powerCenterX-(innerPowerRadius+needleWidth+14)*Math.cos((nextPowerLoad+nextPowerWindowHigh)/180*Math.PI)),
                     ly: Math.round(powerCenterY-(innerPowerRadius+needleWidth+14)*Math.sin((nextPowerLoad+nextPowerWindowHigh)/180*Math.PI)),
                 },
                 arc: {
                     startAngle : POWER_START_ANGLE+nextPowerLoad-nextPowerWindowLow,
                     arcAngle : nextPowerWindowHigh +nextPowerWindowLow,
                     radius: innerPowerRadius+needleWidth+13
                 }
             },

             cadenceTarget :{
                 load:{
                     mx: Math.round(cadenceCenterX-(smallGaugeInnerRadius)*Math.cos(cadenceLoad/180*Math.PI)),
                     my: Math.round(cadenceCenterY-(smallGaugeInnerRadius)*Math.sin(cadenceLoad/180*Math.PI)),
                     lx: Math.round(cadenceCenterX-(smallGaugeRadius)*Math.cos(cadenceLoad/180*Math.PI)),
                     ly: Math.round(cadenceCenterY-(smallGaugeRadius)*Math.sin(cadenceLoad/180*Math.PI)),
                 },
                 min:{
                     mx: Math.round(cadenceCenterX-(smallGaugeInnerRadius+smallNeedleWidth-7)*Math.cos((cadenceLoad-cadenceWindowLow)/180*Math.PI)),
                     my: Math.round(cadenceCenterY-(smallGaugeInnerRadius+smallNeedleWidth-7)*Math.sin((cadenceLoad-cadenceWindowLow)/180*Math.PI)),
                     lx: Math.round(cadenceCenterX-(smallGaugeInnerRadius+smallNeedleWidth)*Math.cos((cadenceLoad-cadenceWindowLow)/180*Math.PI)),
                     ly: Math.round(cadenceCenterY-(smallGaugeInnerRadius+smallNeedleWidth)*Math.sin((cadenceLoad-cadenceWindowLow)/180*Math.PI)),
                 },
                 max:{
                     mx: Math.round(cadenceCenterX-(smallGaugeInnerRadius+smallNeedleWidth-7)*Math.cos((cadenceLoad+cadenceWindowHigh)/180*Math.PI)),
                     my: Math.round(cadenceCenterY-(smallGaugeInnerRadius+smallNeedleWidth-7)*Math.sin((cadenceLoad+cadenceWindowHigh)/180*Math.PI)),
                     lx: Math.round(cadenceCenterX-(smallGaugeInnerRadius+smallNeedleWidth+1)*Math.cos((cadenceLoad+cadenceWindowHigh)/180*Math.PI)),
                     ly: Math.round(cadenceCenterY-(smallGaugeInnerRadius+smallNeedleWidth+1)*Math.sin((cadenceLoad+cadenceWindowHigh)/180*Math.PI)),
                 },
                 arc: {
                     startAngle : POWER_START_ANGLE+cadenceLoad-cadenceWindowLow,
                     arcAngle : cadenceWindowHigh +cadenceWindowLow,
                     radius: smallGaugeInnerRadius+smallNeedleWidth
                 }
             }
         }
     }




     accuPerc(metrics,type){
         let value= 0;

         switch(type){
             case 'tbz' : value =  metrics.tbz; break;
             case 'tiz' : value =  metrics.tiz;break;
             case 'toz' : value =  metrics.toz;break;
             default: value = 0;
         }
         if (metrics.tot===0)
             return 0.0;
         return Math.round(value / metrics.tot*100);
     }

     getColor(value, min, max) {
         if (value > max) {
             return this.props.colors.red;
         }
         if (value < min) {
             return this.props.colors.blue;
         }
         return this.props.colors.green;

     }

     isVisible(){
         return this.props.visualExperience.display_rider_metrics;
     }


     componentDidUpdate(prevProps, prevState) {
         if(typeof prevProps.metricsChanged!== "undefined") {
             if(this.props.timer.workout !== prevState.lastMetricsBroadcast) {
                 this.props.metricsChanged(this.metricsSubset());
                 this.setState({
                     lastMetricsBroadcast: this.props.timer.workout
                 })
             }
         }
         return true;
     }


    render() {
        let metrics =   this.metricsSubset();

        let showNextPower = this.props.visualExperience.display_next_interval;
        let display_rider_metrics = this.isVisible();
        if (display_rider_metrics===false){
            return '';
        }

        if(showNextPower && this.props.timer.workout !== this.state.lastBeep){
            window.beep();
            this.setState({
                lastBeep: this.props.timer.workout
            })
        }

        const styles = this.getStyles(metrics);
        const viewBox = "0 0 " + styles.width + ' ' + styles.height;
        return (
            <svg  viewBox={viewBox} width={this.props.width}>

                <g fill="none" fillOpacity="1">
                    <g id="powerGaugeFrame" stroke={styles.colors.primaryColor} strokeWidth="2">

                        <path d={toArcPath(
                            styles.powerGauge.centerX,
                            styles.powerGauge.centerY,
                            styles.powerGauge.radios,
                            POWER_START_ANGLE ,
                            270,
                            0
                        )}
                              id="power-gauge"
                        />
                        <path d={toArcPath(
                            styles.powerGauge.centerX,
                            styles.powerGauge.centerY,
                            styles.powerGauge.innerRadius,
                            POWER_START_ANGLE ,
                            270,
                            0
                        )}
                              id="power-gauge-inner"
                        />
                        <path
                            d={toPath(
                                styles.powerGauge.centerX-styles.powerGauge.innerRadius,
                                styles.powerGauge.centerY,
                                styles.powerGauge.centerX-styles.powerGauge.radios,
                                styles.powerGauge.centerY
                            )}
                            id="power-gauge-start"
                        />
                        <path
                            d={toPath(
                                styles.powerGauge.centerX,
                                styles.powerGauge.centerY+styles.powerGauge.innerRadius,
                                styles.powerGauge.centerX,
                                styles.powerGauge.centerY+styles.powerGauge.radios
                            )}
                            id="power-gauge-end"
                        />
                    </g>

                    <g id="power-metrics" strokeWidth="3" fillOpacity="0.7">
                        <path d={toArcPath(
                            styles.powerGauge.centerX,
                            styles.powerGauge.centerY,
                            styles.powerGauge.needleRadius,
                            styles.powerGauge.startAngle ,
                            styles.powerGauge.arcAngle,
                            0
                        )}
                              id="power-gauge-needle"
                              stroke={styles.powerColor} strokeWidth={styles.powerGauge.needleWidth}
                        />

                        <path d={toArcPath(
                            styles.powerGauge.centerX,
                            styles.powerGauge.centerY,
                            styles.powerTarget.arc.radius,
                            styles.powerTarget.arc.startAngle ,
                            styles.powerTarget.arc.arcAngle,
                            0
                        )}
                              id="power-target-arc"
                              stroke={styles.colors.primaryColor} strokeWidth="3"
                        />


                        <path
                            stroke={styles.colors.primaryColor}  strokeWidth="1"
                            d={toPath(styles.powerTarget.load.mx,styles.powerTarget.load.my, styles.powerTarget.load.lx,styles.powerTarget.load.ly)}
                            id="power-target"
                          />

                        <path
                            stroke={styles.colors.primaryColor}
                            d={toPath(styles.powerTarget.min.mx,styles.powerTarget.min.my, styles.powerTarget.min.lx,styles.powerTarget.min.ly)}
                            id="power-low-bound"
                          />

                        <path
                            stroke={styles.colors.primaryColor}
                            d={toPath(styles.powerTarget.max.mx,styles.powerTarget.max.my, styles.powerTarget.max.lx,styles.powerTarget.max.ly)}
                            id="power-high-bound"
                          />

                        <rect
                            stroke={styles.colors.primaryColor}
                            fill={styles.avgPowerColor}
                            strokeWidth="2"
                            transform={toRotate(styles.powerAvg.r,styles.powerAvg.cx,styles.powerAvg.cy)}
                            id="power-average-box"
                            width={styles.powerGauge.rectangleDim}
                            height={styles.powerGauge.rectangleDim}
                            x={styles.powerAvg.cx}
                            y={styles.powerAvg.cy}/>
                    </g>

{((showNextPower) ?
                    <g id="next-power-metrics" strokeWidth="3" fillOpacity="0.7">

                        <path
                            stroke={styles.colors.yellow}
                            d={toPath(styles.nextPowerTarget.min.mx,styles.nextPowerTarget.min.my, styles.nextPowerTarget.min.lx,styles.nextPowerTarget.min.ly)}
                            id="next-power-low-bound"
                        />

                        <path
                            stroke={styles.colors.yellow}
                            d={toPath(styles.nextPowerTarget.max.mx,styles.nextPowerTarget.max.my, styles.nextPowerTarget.max.lx,styles.nextPowerTarget.max.ly)}
                            id="next-power-high-bound"
                        />
                        <path d={toArcPath(
                            styles.powerGauge.centerX,
                            styles.powerGauge.centerY,
                            styles.nextPowerTarget.arc.radius,
                            styles.nextPowerTarget.arc.startAngle ,
                            styles.nextPowerTarget.arc.arcAngle,
                            0
                        )}
                              id="next-power-target-arc"
                              stroke={styles.colors.yellow} strokeWidth="3"
                        />


                    </g>
: '' )}


                    <g id="cadenceGaugeFrame" stroke={styles.colors.primaryColor} strokeWidth="2" >
                        <path d={toArcPath(
                            styles.cadenceGauge.centerX,
                            styles.cadenceGauge.centerY,
                            styles.cadenceGauge.radios,
                            POWER_START_ANGLE ,
                            270,
                            0
                        )}
                              id="cadence-gauge"
                        />

                        <path d={toArcPath(
                            styles.cadenceGauge.centerX,
                            styles.cadenceGauge.centerY,
                            styles.cadenceGauge.innerRadius,
                            POWER_START_ANGLE ,
                            270,
                            0
                        )}
                              id="cadence-inner-gauge"
                        />

                        <path
                            d={toPath(
                                styles.cadenceGauge.centerX-styles.cadenceGauge.innerRadius,
                                styles.cadenceGauge.centerY,
                                styles.cadenceGauge.centerX-styles.cadenceGauge.radios,
                                styles.cadenceGauge.centerY
                            )}
                            id="cadence-gauge-start"
                        />
                        <path
                            d={toPath(
                                styles.cadenceGauge.centerX,
                                styles.cadenceGauge.centerY+styles.cadenceGauge.innerRadius,
                                styles.cadenceGauge.centerX,
                                styles.cadenceGauge.centerY+styles.cadenceGauge.radios
                            )}
                            id="cadence-gauge-end"
                        />

                    </g>


                    <g  id="cadence-metrics"  strokeWidth="3" fillOpacity="0.7">

                        <path d={toArcPath(
                            styles.cadenceGauge.centerX,
                            styles.cadenceGauge.centerY,
                            styles.cadenceGauge.needleRadius,
                            styles.cadenceGauge.startAngle ,
                            styles.cadenceGauge.arcAngle,
                            180
                        )}
                              id="cadence-gauge-needle"
                              stroke={styles.cadenceColor} strokeWidth={styles.cadenceGauge.needleWidth}
                        />


                        <path
                            stroke={styles.colors.primaryColor}
                            d={toPath(styles.cadenceTarget.load.mx, styles.cadenceTarget.load.my, styles.cadenceTarget.load.lx, styles.cadenceTarget.load.ly)}
                            id="cadence-target"  strokeWidth="1"
                        />
                        <path
                            stroke={styles.colors.primaryColor}
                            d={toPath(styles.cadenceTarget.min.mx, styles.cadenceTarget.min.my, styles.cadenceTarget.min.lx, styles.cadenceTarget.min.ly)}
                            id="cadence-target-low-bound"
                        />
                        <path
                            stroke={styles.colors.primaryColor}
                            d={toPath(styles.cadenceTarget.max.mx, styles.cadenceTarget.max.my, styles.cadenceTarget.max.lx, styles.cadenceTarget.max.ly)}
                            id="cadence-target-high-bound"
                        />

                        <path d={toArcPath(
                            styles.cadenceGauge.centerX,
                            styles.cadenceGauge.centerY,
                            styles.cadenceTarget.arc.radius,
                            styles.cadenceTarget.arc.startAngle ,
                            styles.cadenceTarget.arc.arcAngle,
                            0
                        )}
                              id="power-target-arc"
                              stroke={styles.colors.primaryColor} strokeWidth="3"
                        />


                        <rect
                            stroke={styles.colors.primaryColor}
                            id="cadence-average-box"
                            fill={styles.avgCadenceColor}
                            strokeWidth="2"
                            transform={toRotate(styles.cadenceAvg.r,styles.cadenceAvg.cx,styles.cadenceAvg.cy)}
                            width={styles.cadenceGauge.rectangleDim}
                            height={styles.cadenceGauge.rectangleDim}
                            x={styles.cadenceAvg.cx}
                            y={styles.cadenceAvg.cy}/>
                    </g>


                    <g fill="none" fillOpacity="1" stroke="#000000" strokeWidth="2">


                    </g>




                </g>

                <g id='texts-power-value' textAnchor="middle" >
                    <text x={styles.powerGauge.centerX +styles.powerGauge.innerRadius*0.40 } y={styles.powerGauge.centerY -styles.powerGauge.innerRadius*0.55 } fill={styles.colors.primaryColor} fontSize={styles.fonts.smallMedium}>{Math.round(metrics.wattsLoad)}     </text>
                    <text  x={styles.powerGauge.centerX +styles.powerGauge.innerRadius*0.50 }y={styles.powerGauge.centerY} fill={styles.powerColor} fontSize={styles.fonts.large}>{Math.round(metrics.watts)}    </text>
                    <text x={styles.powerGauge.centerX +styles.powerGauge.innerRadius*0.40 }  y={styles.powerGauge.centerY+styles.powerGauge.innerRadius*0.35} fill={styles.avgPowerColor} fontSize={styles.fonts.smallMedium}>{Math.round(metrics.avgWatts)}     </text>
                </g>

                <g id='texts-power-percentage' textAnchor="middle" >
                    <text x={styles.powerGauge.centerX -styles.powerGauge.innerRadius*0.33 } y={styles.powerGauge.centerY -styles.powerGauge.innerRadius*0.55 } fill={styles.colors.primaryColor} fontSize={styles.fonts.smallMedium}>{Math.round(metrics.powerLoad)}%    </text>
                    <text  x={styles.powerGauge.centerX -styles.powerGauge.innerRadius*0.50 }y={styles.powerGauge.centerY} fill={styles.powerColor} fontSize={styles.fonts.large}>{Math.round(metrics.power)}%    </text>
                    <text x={styles.powerGauge.centerX -styles.powerGauge.innerRadius*0.40 }  y={styles.powerGauge.centerY+styles.powerGauge.innerRadius*0.35} fill={styles.avgPowerColor} fontSize={styles.fonts.smallMedium}>{Math.round(metrics.avgPower)}%    </text>
                </g>


                <g id="texts-cadence-value"  textAnchor="middle">

                    <text x={styles.cadenceGauge.centerX} y={styles.cadenceGauge.centerY } fill={styles.cadenceColor} fontSize={styles.fonts.medium}>{Math.round(metrics.cadence)}</text>
                    <text x={styles.cadenceGauge.centerX} y={styles.cadenceGauge.centerY+styles.cadenceGauge.innerRadius*0.6 } fill={styles.avgCadenceColor} fontSize={styles.fonts.small}>{roundStr(metrics.avgCadence,1)}   </text>

                </g>

            </svg>
        )
    }

}


/*
for speed
<g id="texts-speed-value"  textAnchor="middle">
    <text x={styles.cadenceGauge.centerX/2} y={styles.cadenceGauge.centerY +  0.8*styles.cadenceGauge.radios  } fill={styles.colors.primaryColor} fontSize={styles.fonts.tiny}>{roundStr(metrics.speed,2)}Kph</text>
</g>
 */

export default MetricsGauge;

