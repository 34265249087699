import {
    RESET_SESSION,
    PAUSE_TIMER,
    START_TIMER,
    START_WORKOUT,
    STOP_TIMER,
    SKIP_TIMER,
    TIMER_TICK,
    timerStatus, SWITCH_INTERVAL, MainTimer, FAST_FORWARD, SyncTimer, SESSION_READY
} from '../actions'
import {Event} from "../Components/Tracking";


const initialState = {
    status: null,
    workout: 0,
    interval: 0,
    workoutStartTime: null
};

const timerReducer = (state = initialState, action) => {

    switch (action.type) {
        case RESET_SESSION:
            if(MainTimer) {
                clearInterval(MainTimer);
            }
            if(SyncTimer) {
                clearInterval(SyncTimer);
            }
            return initialState;

        case START_TIMER:
            return Object.assign({}, state, {
                status: timerStatus.started,
                workout: action.payload.startSec,
                workoutStartTime : state.workout<=0 || state.workoutStartTime === null  ?   new Date().toUTCString() :state.workoutStartTime
            });
        case  FAST_FORWARD:
            return Object.assign({}, state, {
                status: timerStatus.started,
                workout: action.payload.second,
                interval: action.payload.secondInInterval,
                workoutStartTime :  new Date(new Date() - action.payload.second*1000).toUTCString()
            });
        case STOP_TIMER:
            Event("end_workout",{duration_is_sec: state.workout });
            return Object.assign({}, state, {
                status: null,
                workout: 0,
                interval: 0
            });

        case PAUSE_TIMER:
            return Object.assign({}, state, {
                status: timerStatus.paused,
            });

        case START_WORKOUT:
            if(state.workout<=0){
                return Object.assign({}, state, {
                    status: timerStatus.started,
                    workout: 0,
                    interval: 0,
                    workoutStartTime : state.workout<=0 ?   new Date().toUTCString() :state.workoutStartTime
                });
            }

            return state;
        case SKIP_TIMER:
            if(state.workout<0) {
                return Object.assign({}, state, {
                    status: timerStatus.started,
                    workout: 0,
                    interval: 0
                });
            }  else {
                let skipTime = action.payload.duration - state.interval;
                return Object.assign({}, state, {
                    status: timerStatus.started,
                    workout: state.workout + skipTime,
                    interval: 0
                });
            }

        case SWITCH_INTERVAL:
            return Object.assign({}, state, {
                interval: 0
            });
        case TIMER_TICK:
            return Object.assign({}, state, {
                status: timerStatus.started,
                workout: state.workout + 1,
                interval: state.workout<0 ? state.workout+ 1  : state.interval + 1,
            });
        default:
            return state;
    }
};
export default timerReducer;