import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';


import App from './App';
import {Provider} from 'react-redux'
import store from './store'
import config from "./auth_config.json";
import { Auth0Provider } from "./react-auth0-spa";
import history from "./utils/history";
import * as serviceWorker from './serviceWorker';



const onRedirectCallback = appState => {
    history.push(
        appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
    );
};

function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}


ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Auth0Provider
                domain={config.domain}
                client_id={config.clientId}
                //redirect_uri={window.location.href}
                redirect_uri={window.location.origin}
                onRedirectCallback={onRedirectCallback}
            >
            <App workoutId={getParameterByName('wid')}/>
            </Auth0Provider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
