import  React ,{Component}from "react";
import {connect} from "react-redux";
import strava_btn from "../assets/strava_connect.png";
import training_peaks_btn from "../assets/trainingpeaks_connect.jpg";

import {listConnections, loadUserMetrics, presetCriticalPower, setCriticalPower} from "../actions";
import {isEmpty} from "../utils/statistics";

class ExternalApplications extends Component {

    constructor(props) {
        super(props);
        this.cp20m = '';
    }


    componentDidMount(){
        this.props.listConnections();

    }
    saveCp20m(){
        this.props.presetCriticalPower( this.cp20m);
        return false;
    }
    handleCpChange (event) {

        this.cp20m = parseInt(event.target.value)
        this.props.setCriticalPower(event.target.value);
        return true;
    };
    render() {
        if (this.props.connectionsList.length === 0) {
            this.props.listConnections();
            return '';
        }
        let st_connected = this.props.connectionsList.strava.connected;
        let tp_connected = this.props.connectionsList.training_peaks.connected;
        return (

            <div className="introductionContainer shadowContainer">

                <p>
                    <a href={this.props.connectionsList.training_peaks.authorization_url}><img class="connect"  src={training_peaks_btn} alt="TrainingPeaks Connect"/></a>
                    <span className="text-success">{(tp_connected? 'Connected': '')}</span>
                </p>
                <p className="text-center">
                    <a href={this.props.connectionsList.strava.authorization_url}><img class="connect" src={strava_btn} alt="Strava Connect"/></a>
                    <span className="text-success">{(st_connected? 'Connected': '')}</span>
                </p>

                <small><b>New</b> Connect With TrainingPeaks button - your WOD will be added the <b>TrainingPeaks workouts</b> section</small>

            </div>

        )
    }

}

const mapStateToProps = state => ({
    connectionsList: isEmpty(state.session.connections) ? [] : state.session.connections,
    critical_power: state.user.critical_power,
});
export default connect(mapStateToProps,{listConnections, loadUserMetrics, presetCriticalPower,setCriticalPower})(ExternalApplications)
