import React, {useState, Component} from "react";
import {Button, Col,  Form, Navbar,NavDropdown, Row,Modal} from "react-bootstrap";
import {PropTypes} from "prop-types";
import {connect } from "react-redux";
import {workoutStatus} from "../actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPlay,
    faPause,
    faStop,
    faForward,
    faWifi,
    faBolt,
    faTasks,
    faSignOutAlt,
    faVideo,
    faDesktop
} from '@fortawesome/free-solid-svg-icons';
import { useAuth0 } from "../react-auth0-spa";
import powerwatts_logo from "../assets/powerwatts_logo.png";
import { useWakeLock } from 'react-screen-wake-lock';


import {
    setCriticalPower,
    startWorkout,
    switchInterval,
    endInterval,
    endWorkout,
    pauseWorkout,
    connectToBle,
    disconnectBle,
    connectMockBle,
    startTimer,
    skipTimer,
    stopTimer,
    pauseTimer,
    toggleWorkoutProfile,
    toggleReportCard,
    toggleRiderMetrics,
    toggleDisplayAllActiveMetrics,
    connectionStatus
} from "../actions";
import {Event} from "./Tracking";



class StudioControls extends Component {
    skip(){
        this.props.skipTimer();

    }
    render() {
        if(this.props.coachSession===true){
            return this.coachControls();
        }
        return this.userControls();

    }

    coachControls() {
        return (
            <div className='text-center'>
            <Navbar  style={{backgroundColor:'#000'}} className='text-nowrap'  expand="lg">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <ConferenceControls
                        cpx_id={this.props.user.cpxUserId}
                        external_id={this.props.externalId}
                        cpx_coach_id={this.props.cpxCoachId}
                    />
                    <WorkoutControls
                        initialized = {this.props.workoutInitialized}
                        status={this.props.workoutStatus}
                        start={this.props.startWorkout}
                        startTimer={this.props.startTimer}
                        end={this.props.endWorkout}
                        stopTimer={this.props.stopTimer}
                        pause={this.props.pauseWorkout}
                        pauseTimer={this.props.pauseTimer}
                        skip={this.skip.bind(this)}/>

                    <VisualExperienceControls
                        toggleWorkoutProfile = {this.props.toggleWorkoutProfile}
                        toggleReportCard = {this.props.toggleReportCard}
                        toggleRiderMetrics = {this.props.toggleRiderMetrics}
                        toggleDisplayAllActiveMetrics = {this.props.toggleDisplayAllActiveMetrics}
                    />
                </Navbar.Collapse>

            </Navbar>

             </div>

        )
    }


    userControls() {

        return (
            <Navbar  style={{backgroundColor:'#000'}}   expand="lg">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">

                    <RiderInfo user={this.props.user}  mockble={this.props.connectMockBle}/>
                    <LogoutControl/>

                    <UserControls
                        criticalPower = {this.props.critical_power}
                        setCp = {this.props.setCriticalPower}
                    />


                    <Col className="text-center" lg={7}>
                    <img  height="35vh" src={powerwatts_logo} alt="powerwatts" />
                    </Col>

                    <Col className="text-right text-nowrap">


                        {/*<MockControls
                            connctionStatus={this.props.bleConnectionStatus}
                            remark={this.props.bleRemark}
                            workoutStatus = {this.props.workoutStatus}
                            connect={this.props.connectMockBle}

                            stop={this.props.stopMockNotifications}
                        />*/}
                        <BleControls
                            status={this.props.bleConnectionStatus}
                            connect={this.props.connectToBle}

                            disconnect={this.props.disconnectBle}
                            deviceName = {this.props.deviceName}
                            connectionRemark = {this.props.connectionRemark}
                        />
                    <WorkoutControls
                        initialized = {this.props.workoutInitialized}
                        status={this.props.workoutStatus}
                        start={this.props.startWorkout}
                        startTimer={this.props.startTimer}
                        end={this.props.endWorkout}
                        stopTimer={this.props.stopTimer}
                        pause={this.props.pauseWorkout}
                        pauseTimer={this.props.pauseTimer}
                        skip={this.skip.bind(this)}/>
                    </Col>
                    <ScreenLock/>



                </Navbar.Collapse>
            </Navbar>
        );
    }
}

export const BleControls  =  (props) => {
    let message = typeof props.message !== "undefined" ? props.message :''
    switch(props.status) {
        case   connectionStatus.failed:
        case   connectionStatus.not_connected:
            return (

                <span  className="text-nowrap text-warning">
                    <span className="text-danger" style={{cursor: 'pointer'}} onClick={()=>{ props.connect();}}>{message}</span>
                    <Button alt= {props.connectionRemark} variant="info"  onClick={()=>{props.connect()}}> <FontAwesomeIcon  icon={faWifi}  /></Button>


                </span>


            );
        case connectionStatus.connected:
            return (
                <span>

                    <Button alt="{props.deviceName}" variant="secondary"    onClick={()=>{props.disconnect()}}>Disconnect</Button> &nbsp;

                </span>
            )
        default:
            return (
                <span>
                    {props.connectionRemark}
                </span>
            )
    }
};

export const LogoutControl = () => {
    const {logout } = useAuth0();
    const [show, setShow] = useState(false);
    const close = () => setShow(false);
    const confirm = () => setShow(true);

    const logoutWithRedirect = () =>
        logout({
            returnTo: window.location.origin + '/logout'
        });
    return (
        <>
            <Button variant="light" onClick={confirm}>
                <FontAwesomeIcon  icon={faSignOutAlt}  />
            </Button>
            <Modal show={show} onHide={close}>
                <Modal.Header closeButton>
                    <Modal.Title>Logout</Modal.Title>
                </Modal.Header>
                <Modal.Body >Are you sure you want to quit the workout and sign-out?</Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={close}>
                        No
                    </Button>
                    <Button variant="success" onClick={()=> {logoutWithRedirect(); close();}}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};

export class UserControls extends Component {

    handleSubmit(event){
        event.preventDefault();
    }
    handleCpChange (event) {
        event.preventDefault();
       this.props.setCp(event.target.value);


    };

    render() {

        return (
            <Form inline class="cp_form" onSubmit={this.handleSubmit.bind(this)}>
                <Form.Group controlId="formBasicRange">
                    <Form.Label>CP20m: </Form.Label>
                    <Form.Control style={{width:'75px'}} type="number"   value={this.props.criticalPower} onChange={this.handleCpChange.bind(this)}/>
                </Form.Group>
            </Form>
        );
    }

}


export const MockControls = (props) => {
    const [show, setShow] = useState(false);
    const close = () => setShow(false);
    const confirm = () => setShow(true);
    if (props.connctionStatus !== connectionStatus.connected) {

        return (
            <>
                <Button variant="light" onClick={confirm}><FontAwesomeIcon icon={faBolt}/></Button>

                <Modal show={show} onHide={close}>
                    <Modal.Header closeButton>
                        <Modal.Title>Power/Cadence Simulator</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to use this Simulator for your Power/Cadence data?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={close}>
                            No
                        </Button>
                        <Button variant="success" onClick={()=> {props.connect(); close();}}>
                           Yes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
    return (
        <Col>&nbsp;</Col>
    );

};

export const RiderInfo = (props) => {
    const [count, setCount] = useState(0);
    const handleIncrement = () => {
        setCount(prevCount => prevCount + 1);
        if (count>3){
            props.mockble();
            setCount(0);
        }
    };

    let user = props.user;
    return (
        <Form inline>
            <Row>
                <Col  className="HeaderColor   text-nowrap">
                    <img alt={user.full_name} src={user.picture} height="50vh" style={{borderRadius: "50%"}} onClick={handleIncrement}/>
                </Col>
                <Col>&nbsp;</Col>
            </Row>
        </Form>
    );

};

const VisualExperienceControls = (props) => {
    return (

        <NavDropdown title={<Button variant="light"><FontAwesomeIcon  icon={faTasks}/></Button>} id="basic-nav-dropdown">
            <NavDropdown.Item onClick={()=>props.toggleDisplayAllActiveMetrics()}>Show All Active Workouts</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>props.toggleRiderMetrics()}>User Gauges</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>props.toggleReportCard()}>Report Card</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>props.toggleWorkoutProfile()}>Workout Profile</NavDropdown.Item>

        </NavDropdown>
    )
};

const WorkoutControls = (props)=> {

    if(props.initialized){
        return (
            <span>
                {(props.status!==workoutStatus.pending_start) ?
                    <Button variant="light"  onClick={()=>{Event("click",{target:"start_workout"}); props.startTimer();props.start()}}><FontAwesomeIcon  icon={faPlay}  /> </Button>
                : '' }
                &nbsp;
                <Button variant="light" onClick={()=>{Event("click",{target:"skip_interval"});props.skip()}}> <FontAwesomeIcon  icon={faForward}  /></Button>
                &nbsp;
                <Button variant="light"  onClick={()=>{Event("click",{target:"pause_workout"});props.pauseTimer();props.pause()}}> <FontAwesomeIcon icon={faPause}  /> </Button>
                &nbsp;
                <Button variant="light"  onClick={()=>{Event("click",{target:"stop_workout"});props.stopTimer();props.end()}}> <FontAwesomeIcon  icon={faStop}  /></Button>
            </span>

        );
    } else {
        return '';
    }
};


const ConferenceControls = (props)=> {

    if (props.cpx_coach_id!==props.cpx_id) {
        return '';
    }
    return (
        <Col>
            <form method="POST" action="https://booking.powerwatts.co.il/workout/startZoomMeeting" target="_blank">
                <input type="hidden" name="cpx_id" value={props.cpx_id}/>
                <input type="hidden" name="external_id" value={props.external_id}/>
                <Button type="submit" variant="light" > <FontAwesomeIcon  icon={faVideo}  /></Button>
            </form>
        </Col>
    );

};


const ScreenLock = (props) => {
    const {released, request, release} = useWakeLock({
        onRequest: () => {document.body.requestFullscreen();},
        onError: () => alert('An error happened'),
        onRelease: () => {document.exitFullscreen();},
    });
    return (
        <>
            <Button  variant="light" onClick={()=> (released === false ? release() : request())  } >
                <FontAwesomeIcon  icon={faDesktop}  />
            </Button>

        </>
    );
}



StudioControls.propTypes = {
    startWorkout: PropTypes.func,
    endWorkout: PropTypes.func,
    switchInterval: PropTypes.func,
    sets: PropTypes.array,
    workoutInitialized: PropTypes.bool,
    bleConnectionStatus: PropTypes.string,
    deviceName: PropTypes.string,
    connectionRemark: PropTypes.string
};
const mapStateToProps = state =>({
    workoutInitialized: state.workout.initialized,
    coachSession: (state.session.coachSession===true),
    workoutStatus: state.workout.status,
    bleConnectionStatus: state.ble.connection_status,
    interval : (state.workout.interval) ? state.workout.interval : null,
    user : state.user.info,
    cpxUserId : state.user.cpxUserId,
    cpxCoachId : state.session.coach_id,
    externalId : state.session.external_id,
    critical_power: state.user.critical_power,
    deviceName: state.ble.device_name,
    connectionRemark: state.ble.remark,


});

export default connect(mapStateToProps,{
    startWorkout,switchInterval,endInterval,pauseWorkout,endWorkout, connectToBle, disconnectBle,
    connectMockBle, setCriticalPower,
    startTimer, stopTimer,pauseTimer,skipTimer, toggleWorkoutProfile, toggleReportCard, toggleRiderMetrics, toggleDisplayAllActiveMetrics,

})(StudioControls)
