

let ble_sint16 = ['getInt16', 2, true];
let ble_uint8 = ['getUint8', 1];
let ble_uint16 = ['getUint16', 2, true];
let ble_uint32 = ['getUint32', 4, true];
// TODO: paired 12bit uint handling
let ble_uint24 = ['getUint8', 3];

const CadenceTimeoutMilliseconds = 3000;
const CrankTimeFractionOfSecond = 1024;

const differenceBetweenTwoUInt16Values = (newValue, oldValue) => {
    let difference = 0;
    if (newValue >= oldValue) { // Counter has not rolled over
        difference = newValue - oldValue;
    } else { // Counter has rolled over
        difference = 65536 - oldValue + newValue;
    }

    return difference;
}



// https://www.bluetooth.com/specifications/gatt/viewer?attributeXmlFile=org.bluetooth.characteristic.cycling_power_measurement.xml
let cycling_power_measurement = [
    [0, [ [ble_sint16, 'instantaneous_power'] ]],
    [1, [ [ble_uint8, 'pedal_power_balance'] ]],
    [2, [ /* Pedal Power Balance Reference */]],
    [4, [ [ble_uint16, 'accumulated_torque'] ]],
    [8, [ /* Accumulated Torque Source */]],
    [16, [ [ble_uint32, 'cumulative_wheel_revolutions'], [ble_uint16, 'last_wheel_event_time'] ]],
    [32, [ [ble_uint16, 'cumulative_crank_revolutions'], [ble_uint16, 'last_crank_event_time'] ]],
    [64, [ [ble_sint16, 'maximum_force_magnitude'], [ble_sint16, 'minimum_force_magnitude'] ]],
    [128, [ [ble_sint16, 'maximum_torque_magnitude'], [ble_sint16, 'minimum_torque_magnitude'] ]],
    [256, [ [ble_uint24, 'maximum_minimum_angle'] ]],
    [512, [ [ble_uint16, 'top_dead_spot_angle'] ]],
    [1024, [ [ble_uint16, 'bottom_dead_spot_angle'] ]],
    [2048, [ [ble_uint16, 'accumulated_energy'] ]],
    [4096, [ /* Offset Compensation Indicator */]]
];

// https://www.bluetooth.com/specifications/gatt/viewer?attributeXmlFile=org.bluetooth.characteristic.csc_measurement.xml
let csc_measurement = [
    [1, [ [ble_uint32, 'cumulative_wheel_revolutions'], [ble_uint16, 'last_wheel_event_time'] ]],
    [2, [ [ble_uint16, 'cumulative_crank_revolutions'], [ble_uint16, 'last_crank_event_time'] ]]
];


class BleCharacteristicParser {
    getData(dataview) {
        let offset = 0;
        let mask;
        if(this.mask_size === 16) {
            mask = dataview.getUint16(0, true);
            offset += 2;
        } else {
            mask = dataview.getUint8(0);
            offset += 1;
        }

        let fieldArrangement = [];

        // Contains required fields
        if(this.fields[0][0] === 0) {
            for(let fdesc of this.fields[0][1]) {
                fieldArrangement.push(fdesc);
            }
        }

        for(let [flag, fieldDescriptions] of this.fields) {
            if(mask & flag) {
                for(let fdesc of fieldDescriptions) {
                    fieldArrangement.push(fdesc);
                }
            }
        }

        let data = {};
        for(let field of fieldArrangement) {
            var [[accessor, fieldSize, endianness], fieldName] = field;
            let value;
            if(endianness) {
                value = dataview[accessor](offset, endianness);
            } else {
                value = dataview[accessor](offset);
            }

            data[fieldName] = value;
            offset += fieldSize;
        }

        return data;
    }
}

class CyclingSpeedCadenceMeasurementParser extends BleCharacteristicParser {
    constructor () {
        super();
        this.fields = csc_measurement;
        this.mask_size = 8;
    }
}

export class CyclingPowerMeasurementParser extends BleCharacteristicParser {
    constructor () {
        super();
        this.fields = cycling_power_measurement;
        this.mask_size = 16;
    }
}

export class Meter {
    constructor () {
        this.listeners = {};
        this.timeoutID = undefined;
        this.milliTimeout = 8000;
    }

    clearValueOnTimeout(value) {
        if(this.timeoutID !== undefined) {
            clearTimeout(this.timeoutID);
        }
        this.timeoutID = setTimeout(() => {
            this.timeoutID = undefined;
            if(value.constructor === Array) {
                for(let v of value) {
                    this.dispatch(v, 0);
                }
            } else {
                this.dispatch(value, 0);
            }
        }, this.milliTimeout);
    }

    addListener(type, callback) {
        if(!(type in this.listeners)) {
            this.listeners[type] = [];
        }

        this.listeners[type].push(callback);
    }

    dispatch(type, value) {
        if(!(type in this.listeners)) {
            this.listeners[type] = [];
        }

        for(let l of this.listeners[type]) {
            l(value);
        }
    }
}

export class BleMeter extends Meter {
    constructor (device, server, service, characteristic) {
        super();

        this.device = device;
        this.server = server;
        this.service = service;
        this.characteristic = characteristic;

        this.name = this.device.name;
        this.id = this.device.id;

        this.listening = false;

        this.device.addEventListener('gattserverdisconnected', e => {
            this.gattserverdisconnected(e)
                .catch(error => {
                    console.log("Error: ", error);
                });
        });
    }

    async gattserverdisconnected(e) {

        if (this.device) {
            console.log('Reconnecting');
            this.server = await this.device.gatt.connect();
            this.service = await this.server.getPrimaryService(this.serviceId);
            this.characteristic = await this.service.getCharacteristic(this.characteristicId);
            if (this.listening) {
                this.listening = false;
                this.listen();
            }
        }
    }
}

export class BlePowerCadenceMeter extends BleMeter {
    constructor (device, server, service, characteristic) {
        super(device, server, service, characteristic);

        this.serviceId = 0x1818;
        this.characteristicId = 0x2A63;
        this.parser = new CyclingPowerMeasurementParser();

        this.isCrankDataInitialized = false;
        this.lastCrankRevolutions = 0;
        this.lastCrankTime = 0;
        this.lastCrankUpdateTime = new Date().getTime();
        this.lastWheelRevolutions = 0;
        this.lastWheelTime = 0;
    }

    listen() {
        if(!this.listening) {
            this.characteristic.addEventListener('characteristicvaluechanged', event => {
                let data = this.parser.getData(event.target.value);

                let power = data['instantaneous_power'];
                let crankRevolutions = data['cumulative_crank_revolutions'];
                let crankTime = data['last_crank_event_time'];
                let wheelRevolutions = data['cumulative_wheel_revolutions'];
                let wheelTime = data['last_wheel_event_time'];

                /* Crank Calc */
                if (crankTime && crankRevolutions) {
                    if (this.isCrankDataInitialized) {
                        let crankRevolutionsDifference = differenceBetweenTwoUInt16Values(crankRevolutions, this.lastCrankRevolutions);
                        let crankTimeDifference = differenceBetweenTwoUInt16Values(crankTime, this.lastCrankTime);

                        let revs = crankRevolutionsDifference;
                        let duration = crankTimeDifference / CrankTimeFractionOfSecond;

                        // Update the cadence if we have a valid duration and atleast one revolution
                        if (duration > 0 && revs > 0) {
                            let rpm = (revs / duration) * 60;
                            this.dispatch("cadence", rpm);

                            this.lastCrankUpdateTime = new Date().getTime();
                        }
                        // Calculate the amount of time since cadence has been updated and send update if over timeout threshold
                        else {
                            var numMillisecondsSinceUpdate = new Date().getTime() - this.lastCrankUpdateTime;

                            if (numMillisecondsSinceUpdate > CadenceTimeoutMilliseconds) {
                                this.dispatch("cadence", 0);

                                this.lastCrankUpdateTime = new Date().getTime();
                            }
                        }
                    }

                    this.lastCrankRevolutions = crankRevolutions;
                    this.lastCrankTime = crankTime;
                    this.isCrankDataInitialized = true;
                }
                /* End Crank Calc */

                /* Wheel Calc */
                if(wheelRevolutions !== undefined && wheelTime !== undefined) {
                    if(this.lastWheelTime > wheelTime) {
                        this.lastWheelTime = this.lastWheelTime - 65536;
                    }
                    if(this.lastWheelRevolutions > wheelRevolutions) {
                        this.lastWheelRevolutions = this.lastWheelRevolutions - 65536;
                    }

                    let wheelRevs = wheelRevolutions - this.lastWheelRevolutions;
                    let wheelDuration = (wheelTime - this.lastWheelTime) / 1024;
                    let wheelRpm = 0;
                    if(wheelDuration > 0) {
                        wheelRpm = (wheelRevs / wheelDuration) * 60;
                    }

                    this.lastWheelRevolutions = wheelRevolutions;
                    this.lastWheelTime = wheelTime;
                    this.dispatch('wheelrpm', wheelRpm);
                }
                /* End Wheel Calc */

                this.dispatch('power', power);

                this.clearValueOnTimeout(['power', 'cadence', 'wheelrpm']);
            });
            this.characteristic.startNotifications();
            this.listening = true;
        }
    }

}

export class BlePowerMeter extends BleMeter {
    constructor (device, server, service, characteristic) {
        super(device, server, service, characteristic);

        this.serviceId = 0x1818;
        this.characteristicId = 0x2A63;
        this.parser = new CyclingPowerMeasurementParser();
    }

    listen() {
        if(!this.listening) {
            this.characteristic.addEventListener('characteristicvaluechanged', event => {
                let data = this.parser.getData(event.target.value);
                let power = data['instantaneous_power'];
                this.dispatch('power', power);
                this.clearValueOnTimeout('power');
            });
            this.characteristic.startNotifications();
            this.listening = true;
        }
    }

}

export class BleCadenceMeter extends BleMeter  {
    constructor (device, server, service, characteristic) {
        super(device, server, service, characteristic);

        this.serviceId = 0x1816;
        this.characteristicId = 0x2A5B;
        this.parser = new CyclingSpeedCadenceMeasurementParser();

        this.lastCrankRevolutions = 0;
        this.lastCrankTime = 0;
        this.lastWheelRevolutions = 0;
        this.lastWheelTime = 0;
    }

    listen() {
        if(!this.listening) {
            this.characteristic.addEventListener('characteristicvaluechanged', event => {
                let data = this.parser.getData(event.target.value);
                let crankRevolutions = data['cumulative_crank_revolutions'];
                let crankTime = data['last_crank_event_time'];
                let wheelRevolutions = data['cumulative_wheel_revolutions'];
                let wheelTime = data['last_wheel_event_time'];

                if(crankRevolutions !== undefined && crankTime !== undefined) {
                    if(this.lastCrankTime > crankTime) {
                        this.lastCrankTime = this.lastCrankTime - 65536;
                    }
                    if(this.lastCrankRevolutions > crankRevolutions) {
                        this.lastCrankRevolutions = this.lastCrankRevolutions - 65536;
                    }

                    let revs = crankRevolutions - this.lastCrankRevolutions;
                    let duration = (crankTime - this.lastCrankTime) / 1024;
                    let rpm = 0;
                    if(duration > 0) {
                        rpm = (revs / duration) * 60;
                    }

                    this.lastCrankRevolutions = crankRevolutions;
                    this.lastCrankTime = crankTime;

                    this.dispatch('cadence', rpm);
                }

                if(wheelRevolutions !== undefined && wheelTime !== undefined) {
                    if(this.lastWheelTime > wheelTime) {
                        this.lastWheelTime = this.lastWheelTime - 65536;
                    }
                    if(this.lastWheelRevolutions > wheelRevolutions) {
                        this.lastWheelRevolutions = this.lastWheelRevolutions - 65536;
                    }

                    let wheelRevs = wheelRevolutions - this.lastWheelRevolutions;
                    let wheelDuration = (wheelTime - this.lastWheelTime) / 1024;
                    let wheelRpm = 0;
                    if(wheelDuration > 0) {
                        wheelRpm = (wheelRevs / wheelDuration) * 60;
                    }

                    this.lastWheelRevolutions = wheelRevolutions;
                    this.lastWheelTime = wheelTime;

                    this.dispatch('wheelrpm', wheelRpm);
                }

                this.clearValueOnTimeout(['cadence', 'wheelrpm']);
            });
            this.characteristic.startNotifications();
            this.listening = true;
        }
    }

}

export class BleHRMeter extends BleMeter {
    constructor (device, server, service, characteristic) {
        super(device, server, service, characteristic);

        this.serviceId = 0x180D;
        this.characteristicId = 0x2A37;
    }

    listen() {
        if(!this.listening) {
            this.characteristic.addEventListener('characteristicvaluechanged', event => {
                let hr = event.target.value.getUint8(1);
                this.dispatch('hr', hr);
                this.clearValueOnTimeout('hr');
            });
            this.characteristic.startNotifications();
            this.listening = true;
        }
    }

}

export class VirtualPowerMeter extends Meter {
    constructor () {
        super();
        this.listening = false;
        this.watts = 0;

        this.id = 'virtual';
        this.name = 'Virtual Power Meter';
    }

    listen() {
        if(!this.listening) {
            document.getElementById('ui-vpower-container').style.display = 'block';
            let $vpower = document.getElementById('vpower');
            $vpower.value = this.watts;
            $vpower.onchange = e => {
                this.watts = parseInt($vpower.value);
            };

            setInterval(() => {
                this.dispatch('power', this.watts);
            }, 750);

            this.listening = true;
        }
    }

}

export class CycleopsMagnetoPowerCurve extends Meter {
    constructor () {
        super();
        this.listening = false;

        this.id = 'cycleopsmagnetopowercurve';
        this.name = 'Cycleops Magneto Power Curve';
    }

    listen(opts) {
        if(!this.listening) {
            let cadenceMeter = opts.cadenceMeter;
            if(cadenceMeter !== undefined) {
                cadenceMeter.addListener('wheelrpm', (wheelrpm) => {
                    // Hardcoded to 28mm tires
                    let wheelCircumference = 2136;
                    let kph = (wheelCircumference * wheelrpm * 60) / 1000000;
                    let watts = this.Exponential_DoubleAsymptoticExponentialB_model(kph);
                    if(watts < 0) {
                        watts = 0;
                    }
                    this.dispatch('power', watts);
                    this.clearValueOnTimeout('power');
                });

                this.listening = true;
            }
        }
    }

    // Derived from data found here:
    //    https://machiine.com/2018/how-accurate-is-zwifts-power-estimate-for-classic-trainers/
    // Curve fitting utilizing:
    //    http://zunzun.com/
    Exponential_DoubleAsymptoticExponentialB_model(x_in) {
        let temp;
        temp = 0.0;

        // coefficients
        let a = -4.2557472799016870E+02;
        let b = 1.6719781580876170E-02;
        let c = -7.3525587442334214E+01;
        let d = -1.3986922573862917E-01;

        temp = a * (1.0 - Math.exp(b * x_in)) + c * (1.0 - Math.exp(d * x_in));
        return temp;
    }

}
