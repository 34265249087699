import {
    accuracyStatus,
    fastForward, intervalType,
    pauseTimer,
    stopSyncTimers,
    pauseWorkout,
    startTimer,
    startWorkout,
    startSession,
    timerStatus,
    workoutStatus, resetSession, SyncTimer
} from "../actions";
import {SignalR_initConnection, SignalR_notifyMetricChange} from '../signalr_connection';
import {setUserProfileMetrics} from './user';
import {isEmpty, toSetInterval} from '../utils/statistics';

export const START_HUB_CONNECTION = 'START_HUB_CONNECTION';
export const HUB_CONNECTION_IS_READY = 'HUB_CONNECTION_IS_READY';
export const CHAT_MESSAGE = 'CHAT_MESSAGE';
export const CLEAR_CHAT_MESSAGE = 'CLEAR_CHAT_MESSAGE';
export const CONTROL_MESSAGE = 'CONTROL_MESSAGE';
export const EXTERNAL_METRICS_BROADCAST = 'EXTERNAL_METRICS_BROADCAST';
export const MAX_IDLE_SECONDS = 60;
export const COACH_NICKNAME = '__COACH__';
export const locationIds = {
    rider: 'vstudio',
    coach: 'vstudio_coach'
}


export function startHubConnection() {
    return function (dispatch, getState) {
        let accessToken = getState().user.info.token;
        let email = getState().user.info.email;

        SignalR_initConnection(
            accessToken, email,
            () => {
                return signalrRConnectionReady(dispatch);
            },
            (metrics) => {
                return broadcastMetrics(getState, metrics)
            },
            (message) => {
                dispatch(realTimeMessageReceived(message))
            },
            (user) => {
                dispatch(relayUserProfile(dispatch, getState, user))
            },
            (e, u) => {
                dispatch(relayMasterControl(dispatch, getState, e, u))
            },
            (email, ve) => {
                dispatch(cpxControlMessageReceived(email, ve))
            },
            (email, ve) => {
                dispatch(setVisualExperience(dispatch, getState, email, ve))
            },
            (userProfile) => {
                /*
CpFiveM: 303
CpFiveS: 857
CpFourtyFiveM: 0
CpFourtyFiveS: 0
CpOneM: 469
CpSixtyM: 0
CpTenM: 279
CpTenS: 791
CpThirtyM: 247
CpThirtyS: 540
CpThreeM: 0
CpTwentyM: 270
CpTwentyS: 637
CpTwoM: 338
                 */
                dispatch(setUserProfileMetrics(userProfile));

            },
            (message) => {
                let workoutId =  (typeof getState().workout.plan.workoutId === 'undefined' ? null : getState().workout.plan.workoutId);
                let sessionId = (typeof getState().session.sessionId === 'undefined' ? null : getState().session.sessionId);
                let showAll = getState().visualExperience.coach_display_all_active_metrics;
                if (message.rtid === sessionId || (showAll && message.wId === workoutId)) {
                    dispatch(processBroadcastDataPoint(message));
                }
            }
        );
        dispatch(
            {
                type: START_HUB_CONNECTION
            }
        )
    }
}


export function signalrRConnectionReady(dispatch) {

    dispatch({
        type: HUB_CONNECTION_IS_READY
    });
}

export function broadcastSyncMessage(getState) {
    if (!getState().session.hubConnectionReady) {
        console.log('signalR connection is not ready.')
        return false;
    }
    let secInWorkout = getState().timer.workout === null ? 0 : getState().timer.workout;
    let data = {
        rtid: (typeof getState().session.sessionId === 'undefined' ? null : getState().session.sessionId),
        wId: (typeof getState().workout.plan.workoutId === 'undefined' ? null : getState().workout.plan.workoutId),
        cId: locationIds.coach,
        ticks: Date.now(),
        Location: (typeof getState().session.locationId === 'undefined' ? null : getState().session.locationId),
        IsPaused: getState().workout.status !== workoutStatus.started ,
        u: [
            {
                //email: 'coach+' + getState().user.info.email,
                email: getState().session.coach_identifier,
                nickName: COACH_NICKNAME,
                cp: 0,
                w: 0,
                wt: 0,
                wai: 0,
                wtw: 0,
                c: 0,
                cai: 0,
                ct: 0,
                ctw: 0,
                b: getState().workout.progress.intervalId === null ? 0 : getState().workout.progress.intervalId
            }
        ],
        WorkoutStartTime: getState().timer.workoutStartTime,
        eMinutes: secInWorkout / 60
    };
    try {
        SignalR_notifyMetricChange(data);
    } catch (error) {
        console.error(error);
    }
}

export function broadcastMetrics(getState, metrics) {
    if (!getState().session.hubConnectionReady) {
        console.log('signalR connection is not ready.')
        return false;
    }
    let secInWorkout = getState().timer.workout === null ? 0 : getState().timer.workout;
    if (typeof metrics === 'undefined') {
        metrics = {};
    }
    let wtw, ctw;
    if (metrics.powerWindowOriginal !== null) {
        wtw = 0; // metrics.powerWindowOriginal;
    } else {
        wtw = typeof metrics.wattsWindowLow === 'undefined' ? 0 : Math.max(metrics.wattsWindowLow, metrics.wattsWindowHigh);
    }
    if (metrics.cadenceWindowOriginal !== null) {
        ctw = 0; // metrics.cadenceWindowOriginal;
    } else {
        ctw =  typeof metrics.cadenceWindowLow === 'undefined' ? 0 : metrics.cadenceWindowLow;
    }

    let data = {
        rtid: (typeof getState().session.sessionId === 'undefined' ? null : getState().session.sessionId),
        wId: (typeof getState().workout.plan.workoutId === 'undefined' ? null : getState().workout.plan.workoutId),
        cId: locationIds.rider,
        ticks: Date.now(),
        Location: (typeof getState().session.locationId === 'undefined' ? null : getState().session.locationId),
        IsPaused: getState().workout.status !== workoutStatus.started,
        u: [
            {
                email: getState().session.coachSession === true ? getState().session.coach_identifier : getState().user.info.email,
                nickName: getState().session.coachSession === true ? COACH_NICKNAME : getState().user.info.nickname,
                cp: typeof metrics.cp === 'undefined' ? 0 : metrics.cp,
                w: typeof metrics.watts === 'undefined' || metrics.watts === null ? 0 : metrics.watts,
                wt: typeof metrics.wattsLoad === 'undefined' ? 0 : metrics.wattsLoad,
                wai: typeof metrics.avgWatts === 'undefined' ? 0 : metrics.avgWatts,
                wtw: wtw,
                c: typeof metrics.cadence === 'undefined' ? 0 : metrics.cadence,
                //cai: typeof metrics.avgCadence === 'undefined' ? 0 : metrics.avgCadence,
                ct: typeof metrics.cadenceLoad === 'undefined' ? 0 : metrics.cadenceLoad,
                ctw: ctw,
                b: getState().workout.progress.intervalId === null ? 0 : getState().workout.progress.intervalId
            }
        ],
        WorkoutStartTime: getState().timer.workoutStartTime,
        eMinutes: secInWorkout / 60
    };
    try {
        SignalR_notifyMetricChange(data);
    } catch (error) {
        console.error(error);
    }

}


let timerId = null;

export function realTimeMessageReceived(data) {
    return function (dispatch) {
        if (timerId !== null) {
            clearTimeout(timerId);
        }
        timerId = setTimeout(() => {
            clearMessage(dispatch);
        }, 12000);
        dispatch(
            {
                type: CHAT_MESSAGE,
                payload: {
                    type: (data.messageType) ? data.messageType : null,
                    from: (data.fromID) ? data.fromID : null,
                    avatar: (data.fromPic) ? data.fromPic : null,
                    message: (data.message) ? data.message : null,
                }
            }
        )
    };
}

function analyzeBroadcastData(uData) {
    let avgCadence = uData.cai;
    let avgPower = uData.cp !== 0 ? uData.wai / uData.cp * 100 : 0;
    let currentPower = uData.cp !== 0 ? uData.w / uData.cp * 100 : 0;
    let deltaPower = (uData.wai - uData.wt);
    let cpAccStatus = accuracyStatus.in_zone;
    let pAccStatus = accuracyStatus.in_zone;
    switch (uData.wtw) {
        case -2:
            if (uData.w < uData.wt) {
                cpAccStatus = accuracyStatus.low
            }
            if (uData.wai < uData.wt - uData.wtw) {
                pAccStatus = accuracyStatus.low
            }
            break;
        case -1:
            if (uData.w > uData.wt) {
                cpAccStatus = accuracyStatus.high
            }
            if (uData.wai > uData.wt - uData.wtw) {
                pAccStatus = accuracyStatus.high
            }
            break;
        default:
            if (uData.w > uData.wt + uData.wtw) {
                cpAccStatus = accuracyStatus.high
            } else if (uData.w < uData.wt - uData.wtw) {
                cpAccStatus = accuracyStatus.low
            }
            if (uData.wai > uData.wt + uData.wtw) {
                pAccStatus = accuracyStatus.high
            } else if (uData.wai < uData.wt - uData.wtw) {
                pAccStatus = accuracyStatus.low
            }
    }




    let cAccStatus = accuracyStatus.in_zone;
    if (avgCadence > uData.ct + uData.ctw) {
        cAccStatus = accuracyStatus.high
    } else if (avgCadence < uData.ct - uData.ctw) {
        cAccStatus = accuracyStatus.low
    }

    let pAvgAcc = (1 - Math.abs(uData.wai - uData.wt) / uData.wt);
    let cAvgAcc = uData.ct > 0 ? (1 - Math.abs(uData.cai - uData.ct) / uData.ct) : 1;

    return {
        timestamp: Date.now(),
        email: uData.email,
        nickName: uData.nickName,
        cp: uData.cp,
        avgPower: avgPower,
        avgWatts: uData.wai,
        currentPower: currentPower,
        currentWatts: uData.w,
        deltaPower: deltaPower,
        avgCadence: uData.cai,
        currentCadence: uData.c,
        currentPowerStatus: cpAccStatus,
        avgPowerStatus: pAccStatus,
        avgCadenceStatus: cAccStatus,
        powerAccuracy: pAvgAcc,
        cadenceAccuracy: cAvgAcc
    };
}

export function processBroadcastDataPoint(message) {
    return function (dispatch, getState) {
        let second = Math.floor(message.eMinutes * 60);


            if( message.cId === locationIds.coach &&  getState().session.coachSession  ) {
                let sender = message.u[0].email;
                if(SyncTimer && getState().session.coach_identifier !== sender) {
                    if (parseInt(sender) > parseInt(getState().session.coach_identifier)) {
                        console.log('another main coach is found in session - stopping sync !')
                        stopSyncTimers();
                    }
                }
            }


        if (getState().session.coachSession === false && message.cId === locationIds.coach) {
            if(second >= 0) {
                if (Math.abs(getState().timer.workout - second) >= 4) {  //sync timers (delay of 2 seconds)
                    second = second+1;
                    console.log('lost sync - re-syncing to sec ' + (second));
                    dispatch(fastForward(second));//sync message are on delay of 1 sec
                }
                if (getState().timer.status !== timerStatus.paused && message.IsPaused === true) {  //handle pause
                    dispatch(pauseTimer());
                    dispatch(pauseWorkout());
                }
                if (getState().timer.status !== timerStatus.started && message.IsPaused === false) { //handle restart
                    dispatch(startTimer());
                    dispatch(startWorkout());
                }
            }

            if (getState().workout.plan.workoutId !== message.wId) { //handle change workout
                let externalId = getState().session.external_id
                dispatch(resetSession());
                dispatch(startSession(externalId));
            }
        }

        //if(getState().session.coachSession===true) {  //only the coach should analyze riders stats.
        let progress = toSetInterval(getState().workout.plan, second);
        if (progress === null) {
            console.log('didnt get a valid progress value - dropping message');
            return;
        }
        let filtered = [];
        let lastMetrics = {};
        let metrics = [];

        let intervalsMetricsMap = getState().generalMetrics.usersMetrics;
        for (let uData of message.u) {
            if (uData.email !== '' && uData.nickName !== COACH_NICKNAME) {
                if (typeof uData.cai === 'undefined') {
                    if (typeof intervalsMetricsMap[progress.intervalId] === "undefined") {
                        uData.cai = uData.c;
                    }else  if (progress.secondInInterval !== null && progress.secondInInterval > 0 && getState().generalMetrics.usersMetrics[progress.intervalId].has(uData.email)) {
                        lastMetrics = getState().generalMetrics.usersMetrics[progress.intervalId].get(uData.email);
                        uData.cai = lastMetrics.avgCadence + (uData.c - lastMetrics.avgCadence) / progress.secondInInterval;
                        if (isEmpty(uData.wai) || isNaN((uData.wai))) {
                            uData.wai = isEmpty(lastMetrics.wai) ? 0 : lastMetrics.wai;
                        }
                    } else {
                        uData.cai = uData.c;
                    }
                    if(uData.wtw===0){
                        uData.wtw = progress.powerWindow;
                    }
                    if(uData.ctw===0){
                        uData.ctw = progress.cadenceWindow;
                    }
                }

                metrics = analyzeBroadcastData(uData, progress.secondInInterval);

                if (typeof lastMetrics.zones === 'undefined') {
                    lastMetrics.zones = {
                        tbz: 0,
                        tiz: 0,
                        toz: 0,
                        tot: 0,
                    };
                }
                metrics.zones = lastMetrics.zones;
                metrics.zones.tot++;
                switch (metrics.currentPowerStatus) {
                    case accuracyStatus.in_zone :
                        metrics.zones.tiz++;
                        break;
                    case accuracyStatus.high :
                        metrics.zones.toz++;
                        break;
                    case accuracyStatus.low :
                        metrics.zones.tbz++;
                        break;
                    default:
                        break;
                }
                let grade = 0;
                switch (progress.intervalType) {
                    case intervalType.warm_up:
                    case intervalType.recover:
                        grade = 0;
                        break;
                    case intervalType.active_recovery:
                    case intervalType.power:
                    case intervalType.power_slope:
                        grade = metrics.powerAccuracy * 100;
                        break;
                    case intervalType.tar:
                    case intervalType.power_cadence:
                        grade = 100 * (metrics.powerAccuracy + metrics.cadenceAccuracy) / 2;
                        break;
                    case intervalType.agr:
                        grade = metrics.avgPower;
                        break;
                    case intervalType.tiz:
                        grade = ((metrics.zones.tot === 0) ? 0 : metrics.zones.tiz / metrics.zones.tot * 100 - Math.abs(metrics.deltaPower)) / 100;
                        break;
                    case intervalType.toz:
                        grade = ((metrics.zones.tot === 0) ? 0 : (metrics.zones.tbz+metrics.zones.toz) / metrics.zones.tot * 100 - Math.abs(metrics.deltaPower)) / 100;
                        break;
                    default:
                        break;
                }
                metrics.grade = grade;
                filtered.push(metrics);
            }
        }
        dispatch(
            {
                type: EXTERNAL_METRICS_BROADCAST,
                payload: {
                    setId: progress.setId,
                    intervalIndex: progress.intervalIndex,
                    intervalId: progress.intervalId,
                    secondInInterval: progress.secondInInterval,
                    metrics: filtered
                }
            }
        )
        //}
    };
}


export function clearMessage(dispatch, getState) {
    dispatch(
        {
            type: CLEAR_CHAT_MESSAGE
        }
    )
}

export function relayUserProfile(dispatch, getState, u) {

}

export function relayMasterControl(dispatch, getState, e, u) {

}

export function setVisualExperience(dispatch, getState, email, ve) {

}

export function cpxControlMessageReceived(data) {
    return function (dispatch, getState) {
        let status = getState().workout.status;
        let paused = (typeof data.isPaused === 'undefined') ? true : data.isPaused;
        let newStatus = null;
        switch (status) {
            case workoutStatus.started:
                newStatus = paused ? workoutStatus.paused : workoutStatus.started;
                break;
            case workoutStatus.stopped:
                newStatus = paused ? workoutStatus.stopped : workoutStatus.started;
                break;
            case workoutStatus.autoPaused:
            case workoutStatus.paused:
                newStatus = paused ? workoutStatus.paused : workoutStatus.started;
                break;
            default:
                newStatus = paused ? workoutStatus.paused : workoutStatus.started;
        }

        dispatch({
            type: CONTROL_MESSAGE,
            payload: { // MasterControlDTO
                "workout_status": newStatus,
                "display_workout_profile": (typeof data.cdwv === 'undefined') ? null : data.cdwv,
                "display_course": (typeof data.cdcv === 'undefined') ? null : data.cdcv,
                "display_workout_height": (typeof data.cdwh === 'undefined') ? null : data.cdwh,
                "display_course_height": (typeof data.cdch === 'undefined') ? null : data.cdch,
                "summary_display_watts": (typeof data.rc_aw === 'undefined') ? null : data.rc_aw,
                "summary_display_pcp": (typeof data.rc_pcp === 'undefined') ? null : data.rc_pcp,
                "summary_display_watts_offset": (typeof data.rc_pof === 'undefined') ? null : data.rc_pof,
                "summary_display_cadence_offset": (typeof data.rc_cof === 'undefined') ? null : data.rc_cof,
                "summary_display_ranking": (typeof data.rc_rav === 'undefined') ? null : data.rc_rav,
                "summary_display_time_in_zone": (typeof data.rc_tiz === 'undefined') ? null : data.rc_tiz,
                /*  --- not relevant to cpx home
                "display_rider": (typeof data.rdavailable === 'undefined') ? false :data.rdavailable,
                "rdwv": (typeof data.rdwv === 'undefined') ? false :data.rdwv,
                "rdcv": (typeof data.rdcv === 'undefined') ? false :data.rdcv,
                "rdwh":(typeof data.rdwh === 'undefined') ? 0 :data.rdwh,
                "rdch": (typeof data.rdch === 'undefined') ? 0 :data.rdch,
                */
            }
        });
    }
}

