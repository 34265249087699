import  {Component} from "react";

import {connect} from "react-redux";
import {
    mockPower,
    mockCadence,
    timerStatus,connectionStatus
} from "../actions";


const isEmpty =  (value)=> {
    if(typeof(value) == "undefined")
        return true;
    switch (value) {
        case "":
        case 0:
        case "0":
        case null:
        case false:

            return true;
        default:
            return false;
    }

};



class VirtualRider extends Component {
    constructor(props){
        super(props);
        this.scheduledActions = [];
    }


    getPower(interval){
        if (!isEmpty(interval.rampUp)){
            let startPower = interval.rampUp[0];
            let endPower = interval.rampUp[1];
            let duration = interval.durationInSeconds;
            let timeFromStart = this.props.timer.interval>=0 ? this.props.timer.interval : 0 ;
            return  Math.round(startPower + timeFromStart * (endPower-startPower) / duration);
        }
        else return interval.relativePower;

    }

    getLoad(interval) {
        let started  = (this.props.timer.status===timerStatus.started);

        return {
            power : (!started)? 0 : this.getPower(interval),
            power_window_low : (!started)? 0 : !isEmpty(interval.powerWindowLow) ? interval.powerWindowLow : this.props.powerMin,
            power_window_high : (!started)? 0 : !isEmpty(interval.powerWindowHigh) ? interval.powerWindowHigh : this.props.powerMax,
            cadence : (!started)? 0 : !isEmpty(interval.cadence) ? interval.cadence :  85,
            cadence_window_low :   (!started)? 0 : !isEmpty(interval.cadenceWindowHigh) ? interval.cadenceWindowHigh :  this.props.cadenceMin,
            cadence_window_high :  (!started)? 0 :  !isEmpty(interval.cadenceWindowLow) ? interval.cadenceWindowLow :  this.props.cadenceMax
        };

    }
    componentDidUpdate(prevProps, prevState, snapshot){
        if(prevProps.timer.workout !== this.props.timer.workout) {

            let interval = this.props.workout.interval;
            let tiStatus = this.props.timer.status;

            let isMockBle = (this.props.ble.connection_status === connectionStatus.connected) && (this.props.ble.device_name ==='MOCK BLE');
            let isIntervalDataReady  = this.props.workout.initialized && interval!== null;
            let isTimerRunning =  tiStatus===timerStatus.started;
            let powAccuracy = 0.05;
            let cadAccuracy = 0.1;
            let offset = (Math.ceil(Math.random()-0.5));

            let powerCallback  = this.props.mockPower;
            let cadenceCallback  = this.props.mockCadence;

            if(isMockBle && isIntervalDataReady && isTimerRunning) {
                let cp = this.props.user.critical_power;
                let load = this.getLoad(interval);
                let power =  offset+Math.round((1-powAccuracy +2*powAccuracy*Math.random())*load.power/100 * cp) ;
                let cadence =  Math.round((1-cadAccuracy +2*cadAccuracy*Math.random())*load.cadence);

                setTimeout(powerCallback(power),Math.random()*1000);
                setTimeout(cadenceCallback(cadence),Math.random()*1000);
            }else if(isMockBle){
                setTimeout(powerCallback(Math.random()*200),Math.random()*1000);
                setTimeout(cadenceCallback(Math.random()*100),Math.random()*1000);

            }
        }
    }


    render() {
        return '';
    }
}



const mapStateToProps = state =>({
    workout: state.workout,
    timer: state.timer,
    ble: state.ble,
    user: state.user
});
export default connect(mapStateToProps,{mockPower,mockCadence})(VirtualRider)
//export default WorkoutSteps;
