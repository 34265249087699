import {isGameInterval} from "../utils/statistics";
export const TOGGLE_ACCURACY_GAUGE = 'TOGGLE_ACCURACY_GAUGE';
export const TOGGLE_FULL_METRICS = 'TOGGLE_FULL_METRICS';
export const TOGGLE_RIDER_METRICS = 'TOGGLE_RIDER_METRICS';
export const TOGGLE_REPORT_CARD = 'TOGGLE_REPORT_CARD';
export const TOGGLE_WORKOUT_PROFILE = 'TOGGLE_WORKOUT_PROFILE';
export const TOGGLE_WORKOUT_SUMMARY = 'TOGGLE_WORKOUT_SUMMARY';
export const TOGGLE_ALL_ACTIVE_METRICS = 'TOGGLE_ALL_ACTIVE_METRICS';

export function toggleAccuracyGauge(show){
    return function (dispatch, getState) {
        let intervalType =  getState().workout.interval.intervalType;
        if (!isGameInterval(intervalType)) {
            dispatch({
                type: TOGGLE_ACCURACY_GAUGE,
                payload: {
                    show: show
                }
            });
        }
    }
}

export function toggleFullMetrics(show=null){
    return function (dispatch, getState) {
        show = !getState().visualExperience.display_full_metrics;
        dispatch({
            type :TOGGLE_FULL_METRICS,
            payload:{
                show:show
            }
        });
    }
}


export function toggleDisplayAllActiveMetrics(show=null){
    return function (dispatch, getState) {
        show = !getState().visualExperience.coach_display_all_active_metrics;
        dispatch({
            type :TOGGLE_ALL_ACTIVE_METRICS,
            payload:{
                show:show
            }
        });
    }
}

export function toggleWorkoutSummary(show=null){
    return function (dispatch, getState) {
        if (show === null){
            show = !getState().visualExperience.display_workout_summary;
        }
        dispatch({
            type :TOGGLE_WORKOUT_SUMMARY,
            payload:{
                show:show
            }
        });
    }
}


export function toggleRiderMetrics(show=null){
    return function (dispatch, getState) {
        show = show===null ? !getState().visualExperience.coach_screen_display_rider_metrics: show;
        dispatch({
            type :TOGGLE_RIDER_METRICS,
            payload:{
                show:show
            }
        });
    }
}

export function toggleReportCard(show=null){
    return function (dispatch, getState) {
        show = show===null ? !getState().visualExperience.display_report_card: show;
        dispatch({
            type :TOGGLE_REPORT_CARD,
            payload:{
                show:show
            }
        });
    }
}

export function toggleWorkoutProfile(show=null){
    return function (dispatch, getState) {
        show = show===null ? !getState().visualExperience.display_workout_profile: show;
        dispatch({
            type :TOGGLE_WORKOUT_PROFILE,
            payload:{
                show:show
            }
        });
    }
}
