import {
    CONNECT_USER,
    SET_CRITICAL_POWER,
    CONNECT_COACH, SET_PROFILE_METRICS
} from '../actions'
import {isEmpty} from "../utils/statistics";

const initialState = {
    info:null,
    weight: null,
    critical_power: 200,
    cp: [],
    age: null,
    birthDate: null,
    isMale: null,
    isWeightMetric: null,
    primaryPowerWattsCenter: null,
    wPrime: null,
    wPrimeCP: null,
    height: null,
    services:[],
    contracts:[],
    connected_to_strava: false,
    connected_to_training_peaks: false,


};

const userReducer = (state=initialState, action) => {
    switch (action.type) {
        case CONNECT_USER:
            return  Object.assign({}, state, {
                info: action.payload
            });

        case CONNECT_COACH:
            return {
                coach: action.payload.coach
            };
        case SET_CRITICAL_POWER:
            if (!action.payload.time || action.payload.time === 20) {
                return  Object.assign({}, state, {
                    critical_power: action.payload.critical_power
                });
            }
            break;

        case SET_PROFILE_METRICS:

            if (typeof action.payload.full_name!== "undefined"){
                let name_parts = action.payload.full_name.split(' ');
                state.info.first_name = name_parts[0];
                state.info.last_name = ((name_parts.length>1) ? name_parts[1] :'');
            }
            if (typeof action.payload.nickname!== "undefined") {
                state.info.nickname = action.payload.nickname;
            }
            return  Object.assign({}, state, action.payload);

        default:
            return state;

    }

}

export default userReducer;