import React, {Component} from "react";
import {Col, Table} from 'react-bootstrap';
import {connect} from "react-redux";
import {loadWorkout,  toggleFullMetrics, intervalType} from "../actions";
import {round, roundStr, accuracyClass} from '../utils/statistics';
import {AutoFocus} from "./AutoFocus";
import {toRank} from "../utils/SvgHelpers";


class ClientMetrics extends Component {


    componentDidMount(){
       /* let workout_url  =this.props.workout_url;
        if(workout_url!== null) {
            this.props.loadWorkout(workout_url);
        }
        */
     }
    shouldComponentUpdate(nextProps, nextState, nextContext){
        return(
            this.props.workout.initialized !== nextProps.workout.initialized ||
            this.props.workout.progress.set !== nextProps.workout.progress.set  ||
            this.props.workout.progress.interval !== nextProps.workout.progress.interval ||
                this.props.metrics.interval !== nextProps.workout.progress.interval
        )
    }



    render() {
        let workout = this.props.workout;
        if (!workout.initialized) {
            return <Col >
                <p className="text-warning"> Loading Workout...</p>
            </Col>
        }
        let display_rider_metrics = this.props.display_rider_metrics;
        if (display_rider_metrics===false){
            return '';
        }



        let sets = workout.plan.sets;
        let currentSet = workout.progress.set;
        let currentInterval = workout.progress.interval ;
        let currentIndex = workout.progress.intervalId ;

        let progressStatus = null;
        if (currentSet!==null){
            progressStatus =  (currentInterval+1) + '/' + sets[currentSet].intervals.length;
        }

        let extendedMode = (this.props.mode === 'extended');

        return (

                <Table striped hover table variant="dark" className="metricsTable">
                    <tr className='currentSetHeader'>
                        <th className="text-center  HeaderColor" colspan={3} >{workout.plan.workoutName}</th>

                    </tr>
                   

                    {sets.map((set, setId) => {
                        return <>
                            {(set.topic!=='') ?
                            <tr >
                                <th className="text-left HeaderColor" colSpan={extendedMode?6:2}>{set.topic}</th>

                            </tr> : '' }

                            {set.intervals.map((interval) => {


                                return <>
                                    {(interval.intervalId === currentIndex) ? <AutoFocus location={interval.intervalId}/> :'' }
                                    <StepMetrics
                                        key={"interval_"+interval.intervalId}
                                        interval={interval}
                                        data={this.getMetrics(interval.intervalId)}
                                        generalMetrics={this.getGeneralMetrics(interval.intervalId)}
                                        mode = {this.props.mode}
                                        user = {this.props.user}
                                        //header={ interval.shortText}
                                        //intervalType = { interval.intervalType  }
                                    />
                                </>

                            })}

                        </>
                    })}
                </Table>

        )
    }

    getGeneralMetrics(intervalID) {
        if (intervalID === null || typeof this.props.generalMetrics[intervalID] === "undefined") {
            return null;
        }
        let metricsMap = this.props.generalMetrics[intervalID];
        let email = this.props.user.info.email;
        let metrics = metricsMap.get(email);
        if (typeof metrics === "undefined" || metrics === null) {
            return null;
        }
        return metrics;
    }

    getMetrics(intervalID){

        let metrics = this.props.metrics.intervals.find(e => (e.intervalId===intervalID) );
        if (typeof metrics==="undefined") {
            if (this.props.workout.progress.intervalId===intervalID){
                metrics = this.props.metrics.interval;
                let cp = this.props.user.critical_power;
                return {
                    average_power: metrics.power,
                    relative_power: (metrics.power/cp)*100,
                    average_cadence  : metrics.cadence,
                    cadence_accuracy : null,
                    grade : null
                }
            }
            return null;
        }

        return metrics;
    }

    getSetMetrics(setId){
        let metrics = this.props.metrics.sets.find(e => (e.setId===setId) );
        if (typeof metrics==="undefined")
            return null;
        return metrics;
    }
}

export const GameMetrics = (props) =>{
    let data = props.data;
    if(data === null)
        return '';
    let interval = props.interval;
    let user = props.user;
    let type = interval.intervalType;


    let grade = null;
    let rank = typeof data.rank !=='undefined' ? toRank(data.rank) : '';
    switch(type){
        case intervalType.tar:
            if(typeof data.grade==='undefined' || data.grade===null)
                return '';
            grade = roundStr(data.grade,3)
            return (
                <div className="gameMetrics">
                    <span className="rank" style={{color:rank.color}}>{rank.icon}</span>&nbsp;<span className="gameStatistic tar" >{grade}</span>
                </div>
            );
        case intervalType.tiz:
            if(typeof data.zones==='undefined')
                return '';

            let tizPercentage = round(data.zones.tiz/data.zones.tot*100,1);
            return (
                <div >
                    <span className="rank"  style={{color:rank.color}}>{rank.icon}</span>&nbsp;<span className="gameStatistic tiz" >{tizPercentage}%  {roundStr(data.deltaPower,2)}</span>
                </div>
            );
        case intervalType.spr:
            let unit;
            if(typeof user ==='undefined' || typeof user.weight==='undefined' || user.weight===null) {
                grade = roundStr(data.avgWatts, 0);
                unit = 'W' ;
            }else {
                let weight =  user.isWeightMetric ?  user.weight : user.weight * 0.453592
                grade = roundStr(data.avgWatts / weight, 2);
                unit = 'W/Kg';
            }
            return (
                <div >
                    <span className="rank"  style={{color:rank.color}}>{rank.icon}</span>&nbsp;<span className="gameStatistic spr" >{grade}{unit}</span>
                </div>
            );

        case intervalType.agr:

            grade = roundStr(data.grade,1)
            return (
                <div >
                    <span className="rank"  style={{color:rank.color}}>{rank.icon}</span>&nbsp;<span className="gameStatistic agr" >{grade}%</span>
                </div>
            );
        default:
            return '';
    }
};


const StepMetrics = (props) => {
    let extendedMode = (props.mode === 'extended');
    let interval = props.interval;
    let user = props.user;
    let header = interval.shortText;
    let data = props.data;
    let generalMetrics = props.generalMetrics;
    let hasData =  data !== null;
    let pAccClass=null;
    let cAccClass=null;
    if(hasData) {
        pAccClass = accuracyClass(data.power_status);
        cAccClass = accuracyClass(data.cadence_status);
    }
    let power = (!hasData || !data.relative_power) ? '' : round(data.relative_power,0)+'%';
    let watts = (!hasData || !data.average_power) ? '' : round(data.average_power,0)+'w';
    let cadence = (!hasData) ? '' : roundStr(data.average_cadence,1);
    return (
        <tr>
            <td className="text-nowrap">{header}
                {(hasData) ?
                    <div className="text-nowrap">
                        <span className={pAccClass}>{power}</span> &nbsp; <span className={pAccClass}>{watts}</span> &nbsp; <span className={cAccClass}>{cadence}</span>
                    </div>
                 : '' }

                <GameMetrics data={generalMetrics} interval={interval} user={user}/>
            </td>
            {extendedMode ? <td className={pAccClass}>{(!hasData || !data.grade) ? '' : data.grade}</td> : ''}
            {extendedMode ?<td>{(!hasData) ? '' : <Accuracy data={data.power_accuracy}/>}</td>: ''}
            {extendedMode ? <td className={cAccClass}>{(!hasData ||  !data.cadence_accuracy) ? '' : <Accuracy data={data.cadence_accuracy}/>}</td>: ''}
        </tr>
    )
};


const Accuracy = (props) => {
    let data = props.data;
    if(typeof data==="undefined" || data===null){
        return '';
    }
    return (
        <div >
            <div className="text-nowrap text-center ">
                <span className="font-weight-light text-primary"> {data.tbz}% </span>
                <span className="font-weight-bold text-success"> {data.tiz}% </span>
                <span className="font-weight-light text-danger"> {data.toz}% </span>
            </div>
            <div className="text-sm-center">
                <span className="text-warning "> Δ{data.diff} </span>
            </div>
        </div>
    );
};





const mapStateToProps = state => ({
    workout: state.workout,
    user: state.user,
    metrics: state.metrics,
    generalMetrics: state.generalMetrics.usersMetrics,
    workout_url: state.session.workout_url,
    display_full_metrics: state.visualExperience.display_full_metrics,
    display_rider_metrics: state.visualExperience.display_rider_metrics
});
export default connect(mapStateToProps, {loadWorkout,toggleFullMetrics})(ClientMetrics)
