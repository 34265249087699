
import React, { Component } from "react";

import {connect} from "react-redux";

class Timer extends Component {

    render() {
        let injectedClassName = this.props.className;
        let seconds ,limit, levels;
        switch(this.props.timer_type){
            case 'workout':
                seconds = this.props.timer.workout ?  this.props.timer.workout : 0;
                limit =  this.props.workout.initialized ? this.props.workout.plan.duration: 0;
                if(seconds<0){
                    seconds = 0;
                }
                levels= 3;
                break;
            case 'interval':
                seconds = this.props.timer.interval ?  this.props.timer.interval : 0;
                limit =  this.props.workout.initialized &&  this.props.workout.interval && this.props.workout.interval.durationInSeconds ? this.props.workout.interval.durationInSeconds: 0;
                levels = 2;
                break;
            default:
                 seconds = 0;
                 limit = 0;
                 levels = 3;
        }
        let sign = seconds<0 ? '-' : '';
        if (  this.props.direction==='down'){
            seconds = seconds>0 ?  limit - seconds : Math.abs(seconds);
        }

        let time = new Date(null);
        time.setSeconds(seconds);

        let h = time.getUTCHours();
        let m = (time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes());
        let s = (time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds());


        if (levels ===2) {
            return (
               <div className={injectedClassName}>{sign}{m}:{s}</div>
            )
        }

        return (
           <div className={injectedClassName}>{sign}{h}:{m}:{s}</div>
        )
    }

}



const mapStateToProps = state =>({
    workout: state.workout,
    timer: state.timer,
});
export default connect(mapStateToProps,{})(Timer)