import React, {Component, useState} from "react";
import {connect} from "react-redux";
import {selectProduct, loadingState, ProductType} from "../actions";
import Loading from "./Loading";
import {Row, Col, Card, ListGroup, ListGroupItem, Button} from "react-bootstrap";
import {roundStr,formatCurrency} from "../utils/statistics";

import {faCheck, faPlay} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {text} from "@fortawesome/fontawesome-svg-core";

class ProductsSelector extends Component {
    constructor(props) {
        super(props)
        let pid = ((typeof this.props.product_id!=="undefined") ? this.props.product_id : null);
    }
    setProduct(type, id){
        this.props.selectProduct(type, id)
    }
    renderPackages() {
        let selected = this.props.selected_product
        let centerName = this.props.center_name
        return(
            <>
                <Row>
                    <Col style={{margin:'30px'}}>
                <h3 className="text-center">Package of Classes</h3>
                <p>Buy Package of Classes for <b>{centerName}</b> Classes.</p>
            {(centerName!=='PowerWatts UK')?
                <p><i>If you participate in classes hosted by your local center, please purchase your classes through the center manager</i></p>
            :''}
                    </Col>
                </Row>
                <Row className="productList">
                    {
                        this.props.products_list.map( (product, key)=> {
                            if (product.type===ProductType.service && product.price>0) {
                                if(product.is_default && selected===null) {
                                    this.setProduct(product.type, product.id)
                                }
                                return (
                                    <Col className="productCard">
                                        <Card className={(selected===product.id ?'checkedProduct': 'uncheckedProduct')}
                                              onClick={()=>this.setProduct(product.type, product.id)}>
                                            <Card.Body>
                                                <Card.Title className="productCount">{product.count}</Card.Title>
                                            </Card.Body>
                                            <ListGroup className="list-group-flush">
                                                <ListGroupItem className="productName text-nowrap"> {product.name}</ListGroupItem>
                                                <ListGroupItem className="productPrice">
                                                    {formatCurrency(product.price, product.currency)}
                                                    <div className="productInclTax">{formatCurrency(product.total, product.currency)} incl tax</div>

                                                </ListGroupItem>
                                                <ListGroupItem className="productExpiration">
                                                    {formatCurrency(product.price/product.count, product.currency)} per class<br/>
                                                    valid for {product.expiration}</ListGroupItem>
                                                <ListGroupItem className="text-center">
                                                    <Button variant={(selected===product.id ?'success': 'secondary')}
                                                            onClick={()=>this.setProduct(product.type, product.id)}>
                                                        {(selected===product.id ? <FontAwesomeIcon  icon={faCheck}  /> : '')}
                                                    </Button>
                                                </ListGroupItem>
                                            </ListGroup>
                                        </Card>
                                    </Col>
                                );
                            }

                        })
                    }
                </Row>
            </>

        )
    }


    renderContracts() {
        let selected = this.props.selected_product
        const date = new Date();
        const day = date.getDate();
        return(
            <>

                <Row className="productList">
                    <Col >
                    {
                        this.props.products_list.map( (product, key)=> {
                            if (product.type === ProductType.contract && product.price>0) {
                                if(product.is_default && selected===null) {
                                    this.setProduct(product.type, product.id)
                                }
                                return (
                                    <Col className="productCard">
                                        <Card>
                                            <Card.Body>
                                                <Card.Title className="productCount">{product.name}</Card.Title>
                                            </Card.Body>
                                            <ListGroup className="list-group-flush">

                                                <ListGroupItem className="productPrice">
                                                   {formatCurrency(product.price,product.currency)}/month
                                                    <div className="productInclTax"> {formatCurrency(product.total, product.currency)} incl tax</div>

                                                </ListGroupItem>
                                                <ListGroupItem className="productExpiration">
                                                    All future payments will be processed on the {day} of each month<br/>
                                                    Each payment is {formatCurrency(product.price,product.currency)} + {formatCurrency(product.tax,product.currency)} tax = {formatCurrency(product.total,product.currency)}

                                                </ListGroupItem>

                                            </ListGroup>
                                        </Card>
                                    </Col>
                                );
                            }

                        })
                    }
                    </Col>
                </Row>

            </>

        )
    }

    render() {
        if (this.props.products_state!== loadingState.ready){
            return <Loading/>;
        }

        if (this.props.productType === ProductType.service){
            return this.renderPackages()
        }

        if (this.props.productType === ProductType.contract){
            return this.renderContracts()
        }

    }


}

const mapStateToProps = state => ({
    user : state.user,
    last_error : state.subscription.last_error,
    products_state : state.subscription.products_state,
    products_list : state.subscription.products_list,
    center_name : state.subscription.center_name,
    selected_product : (state.subscription.selected_product!==null ? state.subscription.selected_product.product_id : null),
    ref_id : state.subscription.ref_id,

});
export default connect(mapStateToProps,{selectProduct})(ProductsSelector)