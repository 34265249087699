import React, {Component} from "react";
import RemoteMetricsGauge from "./RemoteMetricsGauge";
import {connect} from "react-redux";
import {loadWorkout, toggleFullMetrics} from "../actions";
import CoachGameGauge from "./CoachGameGauge";

class ClassCurrentStatistics extends Component {
    constructor(props) {
        super(props);
        this.state = { width: 0, height: 0 };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    render() {

        let users = this.props.users;
        let mapElements = [];
        for (let elem of users) {
            if (elem[1].visible && !elem[1].idle) {
                let emailParts = elem[0].split('@');
                let nickname = (elem[1].nickName===null) ? emailParts[0] : elem[1].nickName
                mapElements.push({nickName: nickname, email: elem[0]});
            }
        }
        let elementsPerRow = Math.min(mapElements.length,10);
        let width = elementsPerRow >0?  Math.round((this.state.width - 10*elementsPerRow) / elementsPerRow) : 180;
        if(width<185)
            width=185;
        if(width>275)
            width=275;
        let height = width;
        return (
            mapElements.map(( elem,key)=>
                <div className="remoteGaugeContainer">
                    <h4 className='text-warning text-center'>{elem.nickName}</h4>
                    <RemoteMetricsGauge key={"RMG" + elem.email} width={width} height={height} email={elem.email}/>
                    <CoachGameGauge  key={"CGG" + elem.email} width={width}  email={elem.email} />
                </div>
                )

        )

    }
}
const mapStateToProps = state => ({
    status: state.workout.status,
    users: state.generalMetrics.users,
    timer: state.timer.workout,
});
export default connect(mapStateToProps, {loadWorkout,toggleFullMetrics})(ClassCurrentStatistics)


