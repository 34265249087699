import {
    accuracyStatus,
    BLE_CADENCE_CHANGED,
    BLE_POWER_CHANGED,
    END_INTERVAL,
    END_SET, intervalType, METRICS_CHANGED,
    START_INTERVAL,
    START_SET,
    START_WORKOUT,
    RESET_SESSION, FAST_FORWARD
} from '../actions'
import {round, normalizedPower} from '../utils/statistics';

const initialState = {
    current: {
        power: 0,
        power_avg:0,
        power_status:null,
        cadence: 0,
        cadence_avg:0,
        cadence_status:null,
        location: 0,
        power_load: 0,
        cadence_load: 0
    },

    intervals:[],
    sets:[],

    interval: {
        setId : 0,
        intervalId : 0,
        power: 0,
        power_samples: 0,
        cadence: 0,
        cadence_samples: 0,
        avg_speed: 0,
        distance: 0,
        max_speed: 0,
    },
    set: {
        power: 0,
        power_samples: 0,
        cadence: 0,
        cadence_samples: 0
    },
    workout: {
        last_ts: null,
        distance: 0,
        avg_speed: 0,
        kj: 0
    },
    samples :[],
    normalized_power:0
};


const metricsReducer = (state = initialState, action) => {
    let curValue = 0;
    let newIntervalAvg = 0;
    let newSetAvg = 0;
    let samples = state.samples;

    switch (action.type) {
        case RESET_SESSION:
            return initialState;
        case START_WORKOUT:
        case START_INTERVAL:
        case FAST_FORWARD:
            return Object.assign({}, state, {
                current: {
                    power: 0,
                    power_avg:0,
                    power_status:null,
                    cadence:0,
                    cadence_avg:0,
                    cadence_status:null,
                    location: state.current.location,
                    power_load: state.current.power_load,
                    cadence_load: state.current.cadence_load
                },
                interval: {
                    startTs :  action.payload.timestamp,
                    setId : action.payload.setId,
                    intervalId : action.payload.intervalId,
                    endTs: null,
                    power: 0,
                    power_samples: [],
                    cadence: 0,
                    cadence_samples: []
                }
            });

        case START_SET:
            return Object.assign({}, state, {
                current: {
                    power: 0,
                    power_avg:0,
                    power_status:null,
                    cadence:0,
                    cadence_avg:0,
                    cadence_status:null,
                    location: 0,
                    power_load: state.current.power_load,
                    cadence_load: state.current.cadence_load
                },
                interval: {
                    startTs : action.payload.timestamp,
                    endTs: null,
                    setId : action.payload.setId,
                    intervalId : action.payload.intervalId,
                    power: 0,
                    power_samples: [],
                    cadence: 0,
                    cadence_samples: []
                },
                set: {
                    startTs : state.set.startTs,
                    endTs: null,
                    setId : action.payload.setId,
                    power: 0,
                    power_samples: [],
                    cadence: 0,
                    cadence_samples: []
                }
            });

        case END_INTERVAL:
            let intervals = state.intervals;
            let load = action.payload;
            let interval = state.interval;
            interval.endTs = action.payload.timestamp;
            samples = state.samples;
            let summary = intervalSummary(interval, samples.filter(s =>{return s.ts>=interval.startTs && s.ts<=interval.endTs}), load);
            //console.log(summary);
            intervals.push(summary);
            return Object.assign({}, state, {
                intervals: intervals,
                normalized_power: normalizedPower(samples)
            });

        case END_SET:
            let set = state.set;
            let sets = state.sets;
            sets.push(set);
            return Object.assign({}, state, {
                sets:sets
            });
        case METRICS_CHANGED:
            let curTs =  action.payload.metrics.workout_ts;
            let samplesCount =  state.samples.length;
            let deltaTime = state.workout.last_ts>0 ? curTs -  state.workout.last_ts : 0;
          
            return Object.assign({}, state, {
                current: {
                    power: state.current.power,
                    power_avg: action.payload.metrics.avgWatts,
                    power_status: state.current.power_status,
                    cadence: state.current.cadence,
                    cadence_avg: action.payload.metrics.avgCadence,
                    cadence_status: state.current.cadence_status,
                    location: state.current.location,  ///TODO: calculate!!!!
                    power_load: action.payload.metrics.wattsLoad,
                    cadence_load: action.payload.metrics.cadenceLoad,
                    speed: action.payload.metrics.speed
                    },
                workout: {
                    last_ts:  curTs,
                    distance: state.workout.distance + action.payload.metrics.speed*deltaTime/3600,
                    speed: action.payload.metrics.speed,
                    avg_speed: samplesCount>0 ? state.workout.avg_speed + (action.payload.metrics.speed - state.workout.avg_speed)/samplesCount : 0,
                    avg_watts:  samplesCount>0 ? state.workout.avg_watts + (action.payload.metrics.watts - state.workout.avg_watts)/samplesCount : 0,
                    kj:  samplesCount>0 ? state.workout.kj + action.payload.metrics.watts*deltaTime/1000 : 0
                }
         });

        case BLE_POWER_CHANGED:
             curValue = isNaN(action.payload.value)  ? 0 :action.payload.value;
            let intervalAvgPower = state.interval.power;
            let intervalPowerSamples = state.interval.power_samples;
            let setAvgPower = state.set.power;
            let setPowerSamples = state.set.power_samples;
            
            intervalPowerSamples++;
            newIntervalAvg = intervalAvgPower + (curValue - intervalAvgPower) / intervalPowerSamples;
            setPowerSamples++;
            newSetAvg = setAvgPower + (curValue - setAvgPower) / setPowerSamples;

            samples.push({
                ts: action.payload.ts,
                p: action.payload.value,
                c: state.current.cadence,
                s: state.current.speed,
            });



            return Object.assign({}, state, {
                current: {
                    power: curValue,
                    power_avg : newIntervalAvg,
                    power_status : state.current.power_status,
                    cadence: state.current.cadence,
                    cadence_avg: curValue,
                    cadence_status: state.current.cadence_status,
                    location: state.current.location,  ///TODO: calculate!!!!
                    power_load: state.current.power_load,
                    cadence_load: state.current.cadence_load,
                    speed: state.current.speed
                },
                interval: {
                    startTs : state.interval.startTs,
                    endTs: null,
                    setId : state.interval.setId,
                    intervalId : state.interval.intervalId,
                    power: newIntervalAvg,
                    power_samples: intervalPowerSamples,
                    cadence: state.interval.cadence,
                    cadence_samples: state.interval.cadence_samples
                },
                set: {
                    startTs : state.set.startTs,
                    endTs: null,
                    setId : state.interval.setId,
                    power: newSetAvg,
                    power_samples: setPowerSamples,
                    cadence: state.set.cadence,
                    cadence_samples: state.set.cadence_samples,
                },
                samples: samples
            });
        

        case BLE_CADENCE_CHANGED:
            curValue = isNaN(action.payload.value)  ? 0 :action.payload.value;
            let intervalAvgCadence = state.interval.cadence;
            let intervalCadenceSamples = state.interval.cadence_samples;
            let setAvgCadence = state.set.cadence;
            let setCadenceSamples = state.set.cadence_samples;

            intervalCadenceSamples++;
            newIntervalAvg = intervalAvgCadence + (curValue - intervalAvgCadence) / intervalCadenceSamples;
            setCadenceSamples++
            newSetAvg = setAvgCadence + (curValue - setAvgCadence) / setCadenceSamples;

            samples.push({
                ts: action.payload.ts,
                p: state.current.power,
                c: action.payload.value,
                s: state.current.speed,
            });


            return Object.assign({}, state, {
                current: {
                    power: state.current.power,
                    power_avg:state.current.power_avg,
                    power_status:state.current.power_status,
                    cadence: curValue,
                    cadence_avg: curValue,
                    cadence_status: state.current.cadence_status,
                    location: state.current.location,  ///TODO: calculate!!!!
                    power_load: state.current.power_load,
                    cadence_load: state.current.cadence_load,
                    speed: state.current.speed
                },
                interval: {
                    startTs : state.interval.startTs,
                    endTs: null,
                    setId : state.interval.setId,
                    intervalId : state.interval.intervalId,
                    power: state.interval.power,
                    power_samples: state.interval.power_samples,
                    cadence: newIntervalAvg,
                    cadence_samples: intervalCadenceSamples,
                },
                set: {
                    startTs : state.set.startTs,
                    endTs: null,
                    setId : action.payload.setId,
                    power: state.set.power,
                    power_samples: state.set.power_samples,
                    cadence: newSetAvg,
                    cadence_samples: setCadenceSamples,
                },
                samples: samples
            });

        default:
            return state;
    }

};

function intervalSummary(intervalData, samples, load) {
    let ptbz, ptiz, ptoz, ctbz, ctiz, ctoz, maxP, minP, maxC, minC, totalW;
    ptbz = ptiz = ptoz = ctbz = ctiz = ctoz = maxP = minP = maxC= minC = totalW =  0;
    let lastTs  = samples.length>0  ? samples[0].ts  :0;
    let np =  normalizedPower(samples);
    let avgSpeed = 0;
    let distance = 0;
    let maxSpeed = 0;
    samples.map((item) => {
        let seconds = (item.ts-lastTs)/1000;
        if (!isNaN(item.s)) {
            avgSpeed += item.s;
            distance += item.s * seconds / 3600;
            if (item.s > maxSpeed) {
                maxSpeed = item.s;
            }
        }
        totalW += item.p * seconds/1000;
        lastTs = item.ts;
        if (maxP === 0 || item.p > maxP){
            maxP = item.p;
        }
        if (minP === 0 || item.p < minP) {
            minP = item.p;
        }
        if (item.p < load.power_window[0]) {
            ptbz++;
        } else if (item.p > load.power_window[1]) {
            ptoz++;
        } else {
            ptiz++;
        }


        if (maxC === 0 || item.c > maxC) {
            maxC = item.c;
        }
        if (minC === 0 || item.c < minC) {
            minC = item.c;
        }
        if (item.c < load.cadence_window[0]) {
            ctbz++;
        } else if (item.p > load.cadence_window[1]) {
            ctoz++;
        } else {
            ctiz++;
        }
        return null;
    });
    avgSpeed =  samples.length>0 ? avgSpeed/samples.length : 0;
    let powerStatus = null;
    if (intervalData.power > load.power_window[1]) {
        powerStatus = accuracyStatus.high
    } else if (intervalData.power < load.power_window[0]) {
        powerStatus = accuracyStatus.low
    } else {
        powerStatus = accuracyStatus.in_zone
    }
    let cadenceStatus = null;
    if (intervalData.power > load.power_window[1]) {
        cadenceStatus = accuracyStatus.high
    } else if (intervalData.power < load.power_window[0]) {
        cadenceStatus = accuracyStatus.low
    } else {
        cadenceStatus = accuracyStatus.in_zone
    }


    let samplesCount = samples.length;
    let pInZonePerc = samplesCount===0 ? 0 :   ptiz / samplesCount;

    let pAvgAcc =   (1 - Math.abs(intervalData.power - load.power) / load.power);
    let cAvgAcc =   (1 - Math.abs(intervalData.cadence - load.cadence) / load.cadence);


    let grade =  0;
    let showGrade = false;
    switch(load.interval_type){
        case intervalType.warm_up:
        case intervalType.recover:
            grade = 0;
            break;
        case intervalType.active_recovery:
        case intervalType.power:
        case intervalType.power_slope:
            grade = pAvgAcc*100;
            break;
        case intervalType.tar:
            showGrade = true;
        case intervalType.power_cadence:
            grade = 100 * (pAvgAcc+cAvgAcc) /2 ;
            break;
        case intervalType.agr:
            showGrade = true;
            grade  = 100 * intervalData.power / load.critical_power;
            break;
        case intervalType.tiz:
            showGrade = true;
            grade = pInZonePerc*100 -  Math.abs(intervalData.power - load.power)/100;
            break;
        default:
            break;
    }

    return {
        start_ts : intervalData.startTs,
        end_ts : intervalData.endTs,
        duration: load.duration,
        setId : intervalData.setId,
        intervalId : intervalData.intervalId,
        shortText : load.shortText,
        intervalType : load.interval_type,
        average_power: round(intervalData.power,1),
        relative_power: round(intervalData.power/load.critical_power*100,1),
        targetPower:load.power,
        targetPowerWindow: Math.abs(load.power-load.power_window[0]) ,
        maxPower: maxP,
        minPower: minP,
        minCadence: minC,
        maxCadence: maxC,
        criticalPower: load.critical_power,
        normalizedPower: np,
        power_accuracy : {
            diff: round(intervalData.power - load.power,1),
            tbz: samplesCount===0 ? 0 : round(ptbz/samplesCount*100,1),
            tiz: samplesCount===0 ? 0 :  round(ptiz/samplesCount*100 ,1),
            toz: samplesCount===0 ? 0 :  round(ptoz/samplesCount*100, 1),
            tot:samplesCount,
            grade: showGrade ? round(grade,3) :null
        },
        totalWork: totalW,
        average_cadence:  round(intervalData.cadence,1),
        targetCadence:load.cadence,
        targetCadenceWindow: (load.cadence_window ? Math.abs(load.cadence- load.cadence_window[0]) : null),
        cadence_accuracy : {
            diff:  round(intervalData.cadence - load.cadence,1),
            tbz:  samplesCount===0 ? 0 :  round(ctbz/samplesCount*100,1),
            tiz:  samplesCount===0 ? 0 :  round(ctiz/samplesCount*100,1),
            toz:  samplesCount===0 ? 0 :  round(ctoz/samplesCount*100,1),
            tot: samplesCount
        },
        power_status: powerStatus,
        cadence_status: cadenceStatus,
        grade: round(grade,3),
        avg_speed: avgSpeed,
        distance: distance,
        max_speed: maxSpeed,
    };
}

export default metricsReducer;