

import {connect} from "react-redux";
import {GameGauge} from "./GameGauge";

class CoachGameGauge extends GameGauge {
    constructor(props)
    {
        super(props);
        this.userEmail = this.props.email;
    }

    isVisible()
    {
        return this.props.visualExperience.coach_screen_display_rider_metrics;
    }
}


const mapStateToProps = state => ({
    status: state.workout.status,
    interval: (state.workout.interval) ? state.workout.interval : null,
    user: state.user,
    users: state.generalMetrics.users,
    metrics: state.generalMetrics.usersMetrics,
    timer: state.timer.workout,
    visualExperience : state.visualExperience
});
export default connect(mapStateToProps, {})(CoachGameGauge)