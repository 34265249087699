import React, {Component} from "react";
import { Table} from "react-bootstrap";
import {connect} from "react-redux";
import {loadWorkout, toggleFullMetrics} from "../actions";
import {AutoFocus} from "./AutoFocus";
import {  roundStr, accuracyClass} from "../utils/statistics";
import {  GameMetrics} from "../Components/ClientMetrics";


class ReportCard extends Component {


    render() {
        let workout = this.props.workout;
        if (!workout.initialized) {
            return '';
        }

        let display_report_card = this.props.display_report_card;
        if (display_report_card===false){
            return '';
        }


        let sets = workout.plan.sets;
        let users = this.props.users;
        let nickNames =[];
        let emails =[];
        for (let elem of users){
            if(elem[1].visible  && !elem[1].idle) {
                let emailParts = elem[0].split('@');
                let nickname = (elem[1].nickName===null) ? emailParts[0] : elem[1].nickName
                nickNames.push(nickname);
                emails.push(elem[0]);
            }
        }

        let currentIndex = workout.progress.intervalId ;
        return (
            <div className="extendedClassStatsContainer" >
            <Table striped hover table variant="dark" className="classMetricsTable">
                <thead>
                <tr className='currentSetHeader'>
                    <th>Step</th>
                    {nickNames.map(nickName =>
                        <th className="text-left text-light" >{nickName.length>7? nickName.substr(0,7)+'...' :nickName }</th>
                    )}

                </tr>
                </thead>
                <tbody>
                {sets.map((set) => {
                    return <>
                        {set.intervals.map((interval) => {
                            return <>
                                {(interval.intervalId === currentIndex) ? <AutoFocus location={interval.intervalId}/> :'' }
                                <StepMetrics
                                    key={"interval_"+interval.intervalId}
                                    interval={interval}
                                    header={ interval.shortText}
                                    data={this.getMetrics(interval.intervalId)}
                                    emails={emails}
                                />
                             </>

                        })}

                    </>
                    })}
                })
                </tbody>

            </Table>
            </div>
        );

    }

    getMetrics(intervalId){
        if (typeof this.props.metrics[intervalId]==="undefined")
            return null;
        return this.props.metrics[intervalId];
    }
}



const StepMetrics = (props) => {
    let interval = props.interval;
    let header = interval.shortText;
    let data = props.data;
    let emails = props.emails;

    return (
        <tr>
                <td className="text-left text-light">{header}</td>
                {emails.map(email =>{
                    if(data===null){
                        return <td>&nbsp;</td>
                    }
                    let stats = data.get(email);
                    if(typeof stats=== "undefined" || stats===null){
                        return <td>&nbsp;</td>
                    }

                    let power =  roundStr(stats.avgPower,0);
                    let cadence =  roundStr(stats.avgCadence,1);
                    let pAccClass = accuracyClass(stats.avgPowerStatus);
                    let cAccClass = accuracyClass(stats.avgCadenceStatus);
                    return <td className="text-right classData" >
                        <div className="text-nowrap">
                            <span className={pAccClass}>{power}%</span> &nbsp;
                            <span className={cAccClass}>{cadence}</span>
                        </div>

                        <GameMetrics data={stats} interval={interval}/>
                    </td>
                }
            )}

        </tr>
    )
};



const mapStateToProps = state => ({
    workout: state.workout,
    metrics: state.generalMetrics.usersMetrics,
    users: state.generalMetrics.users,
    timer: state.timer.workout,
    display_report_card: state.visualExperience.display_report_card
});
export default connect(mapStateToProps, {loadWorkout,toggleFullMetrics})(ReportCard)





