import  authReducer from './auth';
import  bleReducer from './bleReducer';
import  metricsReducer from './metrics';
import  userReducer from './user';
import  workoutReducer from './workout';
import  timerReducer from './timer';
import  sessionReducer from './session';
import  visualExperienceReducer from './visualExperience';
import  generalMetricsReducer from './generalMetrics';
import  subscriptionReducer from './subscription';


import {combineReducers} from 'redux'


const allReducers = combineReducers({
    auth: authReducer,
    ble: bleReducer,
    user: userReducer,
    workout: workoutReducer,
    metrics: metricsReducer,
    timer: timerReducer,
    session: sessionReducer,
    visualExperience: visualExperienceReducer,
    generalMetrics: generalMetricsReducer,
    subscription: subscriptionReducer,
});


export  default allReducers;