import React  from "react";
import { useDispatch} from "react-redux";
import {useAuth0} from "../react-auth0-spa";
import Loading from "../Components/Loading";
import {
    resetSession,
    startSession,
    startCoachSession,

} from "../actions";
import {Col, Container, Row} from "react-bootstrap";
import StudioControls from "../Components/StudioControls";
import Timer from "../Components/Timer";
import StepIndicator from "../Components/StepIndicator";
import ClassCurrentStatistics from "../Components/ClassCurrentStatistics";
import ClassStatistics from "../Components/ReportCard";
import WorkoutImage from "../Components/WorkoutImage";
import WorkoutsSelector from "../Components/WorkoutsSelector";

import Scheduler from "../Components/Scheduler";
import {Event} from "../Components/Tracking";

export const CoachView  =  (props)=>{
    let dispatch = useDispatch();
    const { loading, user } = useAuth0();

    if (loading || !user) {
        return <Loading />;
    }
    let exteranalId=1;
    if(typeof  props.location.state!=="undefined") {
        exteranalId = props.location.state.externalId;
    }

    dispatch(resetSession());
    dispatch(startCoachSession());
    dispatch(startSession(exteranalId));



    Event("page_view", {page_title:"Coach Screen"})
    return (
        <Container   fluid>

            <Scheduler/>

            <Row>
                <Col><Timer className="workout-timer text-left"  timer_type="workout" direction="up"/> </Col>
                <StepIndicator type="current"/>
                <Col ><Timer timer_type="interval" direction="down"  className="interval-timer text-center"/>
                    <div> <WorkoutsSelector /></div>
                    <div className='text-center'> <StudioControls /></div>
                </Col>
                <StepIndicator type="next"/>
                <Col><Timer className="workout-timer text-right"  timer_type="workout" direction="down"/></Col>
            </Row>
            <Row>
                <ClassCurrentStatistics/>
            </Row>

            <Row>
                <ClassStatistics/>
            </Row>
            <Row  >
                <Col ><WorkoutImage/></Col>
            </Row>
        </Container>
    )
};