import {BlePowerCadenceMeter} from "../utils/BleMeter";
import {Event} from "../Components/Tracking";

export const BLE_STATUS_CHANGED = 'BLE_STATUS_CHANGED';
export const BLE_POWER_CHANGED = 'BLE_POWER_CHANGED';
export const BLE_CADENCE_CHANGED = 'BLE_CADENCE_CHANGED';
export const BLE_SPEED_CHANGED = 'BLE_SPEED_CHANGED';


export const connectionStatus = {
    connected : 'connected',
    searching: 'searching',
    connectingDevice: 'connecting-device',
    connectingServer: 'connecting-server',
    connectingService: 'connecting-service',
    failed: 'failed',
    not_connected: 'not_connected',
};

let BleDevice = null;

export function connectToBle() {
    return function (dispatch) {
        let options = {
            filters: [
                {services: ['cycling_power']},
                {services: ['cycling_speed_and_cadence']}
            ]
        };
        let locDevice = null;
        let locServer = null;
        let locService = null;
        deviceConnectionStatusChanged(connectionStatus.not_connected,'not connected');
        Event('click', {'target':'connect ble'});
        if(typeof  navigator.bluetooth ==='undefined'  ){
            Event("ble_connection",{status:'connection failed' ,error:'Bluetooth is not supported'});
            dispatchConnectionChange(dispatch,connectionStatus.failed,'No BLE Support');
            return false;
        }
        navigator.bluetooth.getAvailability().then(available => {
            if (!available) {
                Event("ble_connection",{status:'connection failed' ,error:'Bluetooth is not supported'});
                dispatchConnectionChange(dispatch, connectionStatus.failed, 'Bluetooth is not supported');
                return false;
            }
            return  navigator.bluetooth.requestDevice(options);
        }).then(device => {
            console.log('found device');
            console.log(device);
            locDevice = device;
            device.addEventListener('gattserverdisconnected', deviceDisconnected);
            Event("ble_connection",{status:'found device'});
            dispatchConnectionChange(dispatch,connectionStatus.connectingDevice,'found device');
            return device.gatt.connect();
        }).then(server => {
            console.log('found server');
            console.log(server);
            locServer = server;
            Event("ble_connection",{status:'found server'});
            dispatchConnectionChange(dispatch,connectionStatus.connectingServer,'found server');
            return server.getPrimaryService('cycling_power');
        }).then(service => {
            console.log('found service');
            console.log(service);
            locService = service;
            Event("ble_connection",{status:'found service'});
            dispatchConnectionChange(dispatch,connectionStatus.connectingService,'found service');
            return service.getCharacteristic(0x2A63)
        }).then(characteristic => {
            console.log('found characteristic');
            console.log(characteristic);
            Event("ble_connection",{status:'found characteristic'});
            dispatchConnectionChange(dispatch,connectionStatus.connected,'found characteristic');
            BleDevice = new BlePowerCadenceMeter(locDevice, locServer, locService, characteristic);
            BleDevice.addListener('power', (value)=>{powerChanged(dispatch,value)});
            BleDevice.addListener('cadence', (value)=>{cadenceChanged(dispatch,value)});
            BleDevice.addListener('wheelrpm', (value)=>{speedChanged(dispatch,value)});
            BleDevice.listen();
            Event("ble_connection",{status:'connected' ,trainer_type:locDevice.name});
            dispatchConnectionChange(dispatch,connectionStatus.connected,'connected', locDevice.name);
        }).catch(error => {
            Event("ble_connection",{status:'connection failed' ,error: '' + error});
            console.log('Argh! ' + error);
            dispatchConnectionChange(dispatch,connectionStatus.failed,'' + error);
            return false;
        });
    }
}

export function disconnectBle(){
    if (!BleDevice) {
        return deviceConnectionStatusChanged(connectionStatus.not_connected,'no connected device');
    }
    let device = BleDevice.device;
    BleDevice.device= null
    device.gatt.disconnect();
    console.log('device disconnected');
    console.log(device);
    return deviceConnectionStatusChanged(connectionStatus.not_connected,'disconnected');
}

export function deviceDisconnected() {
    console.log('Device is disconnected.');
    deviceConnectionStatusChanged(connectionStatus.not_connected,'device disconnected');
}

export function dispatchConnectionChange(dispatch,status, remark, deviceName ){
    dispatch(
        {
            type : BLE_STATUS_CHANGED,
            payload : {
                status :status,
                remark :remark,
                device_name : (status === connectionStatus.connected ? deviceName  : null)
            }
        }
    )
}
export function deviceConnectionStatusChanged(status, remark, deviceName) {
    return function (dispatch) {
        dispatch(
            {
                type : BLE_STATUS_CHANGED,
                payload : {
                    status :status,
                    remark :remark,
                    device_name : (status === connectionStatus.connected ? deviceName  : null)
                }
            }
        )
    };
}



export function powerChanged(dispatch,value) {
    dispatch(
        {
            type : BLE_POWER_CHANGED,
            payload : {
                ts: Date.now(),
                value :value
            }
        }
    )
}

export function cadenceChanged(dispatch, value) {
    dispatch(
        {
            type : BLE_CADENCE_CHANGED,
            payload : {
                ts: Date.now(),
                value :value
            }
        }
    )

}

export function speedChanged(dispatch,value) {
    dispatch(
        {
            type : BLE_SPEED_CHANGED,
            payload : {
                ts: Date.now(),
                value :value/8.10  //26`` tire
            }
        }
    )

}

